import axios, { AxiosResponse } from 'axios';
import { QueryFunctionContext } from 'react-query';

import { GroupChat, GroupChatMessage } from 'app/models/group-chat.interface';
import { Contact } from 'app/models/contact.interface';
import {
	PersonalChat,
	PersonalChatMessage
} from 'app/models/personl-chat.interface';

export const getGroupChats = async({ queryKey }: QueryFunctionContext): Promise<{ results: GroupChat[], count: number }> => {
	const [, limit, offset] = queryKey as [string, number, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/group_chats/`, {
		params: {
			limit,
			offset
		}
	});
	return response?.data;
}

export const getPersonalChats = async({ queryKey }: QueryFunctionContext): Promise<{ results: PersonalChat[], count: number }> => {
	const [, limit, offset] = queryKey as [string, number, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/personal_chats/`, {
		params: {
			limit,
			offset
		}
	});
	return response?.data;
}

export const getContacts = async({ queryKey }: QueryFunctionContext): Promise<Contact[]> => {
	const [, role, limit, offset] = queryKey as [string, string, number, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/contacts/`, {
		params: {
			role: role || null,
			limit,
			offset
		}
	});
	return response?.data;
}

export const getGroupChatInfo = async({ queryKey }: QueryFunctionContext): Promise<{ name: string, image: string }> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/group_chats/${id}/`);
	return response?.data;
}

export const getPersonalChatInfo = async({ queryKey }: QueryFunctionContext): Promise<{ user: { name: string, avatar: string } }> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/personal_chats/${id}/`);
	return response?.data;
}

export const getGroupChatMessages = async({ queryKey }: QueryFunctionContext): Promise<{ results: GroupChatMessage[], count: number }> => {
	const [, id, limit, offset] = queryKey as [string, number, number, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/group_chats/${id}/messages/`, {
		params: {
			limit,
			offset
		}
	});
	return response?.data;
}

export const getPersonalChatMessages = async({ queryKey }: QueryFunctionContext): Promise<{ results: PersonalChatMessage[], count: number }> => {
	const [, id, limit, offset] = queryKey as [string, number, number, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/personal_chats/${id}/messages/`, {
		params: {
			limit,
			offset
		}
	});
	return response?.data;
}

export const sendGroupChatMessage = (id: number, text: string): Promise<AxiosResponse> =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/group_chats/${id}/message/`, {
			text
		}
	);

export const sendGroupChatFileMessage = (id: number, file: number): Promise<AxiosResponse> =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/group_chats/${id}/message/`, {
			file
		}
	);

export const sendPersonalChatMessage = (id: number, text: string): Promise<AxiosResponse> =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/personal_chats/${id}/message/`, {
			text
		}
	);

export const sendPersonalChatFileMessage = (id: number, file: number): Promise<AxiosResponse> =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/personal_chats/${id}/message/`, {
			file
		}
	);

export const createChat = (user: number): Promise<AxiosResponse> =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/personal_chats/`, {
			user
		}
	);

export const uploadChatFile = (file: File): Promise<AxiosResponse> => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('type', file.type.split('/').includes('image') ? '0' : '2');

	return axios.post(`${process.env.REACT_APP_API_URL}/users/chat_files/`, formData, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
	);
}

export const createGroup = (name: string, image: number | null, members: number[]): Promise<AxiosResponse> =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/group_chats/`, {
			name,
			image,
			members
		}
	);

export const removeMessage = (id: number) =>
	axios.delete(`${process.env.REACT_APP_API_URL}/users/chat_messages/${id}/`);
