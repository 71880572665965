import React from 'react';

import { Semester } from 'app/models/semester.interface';
import { ButtonGroup } from 'app/components/ui';

import styles from './index.module.sass';

interface Props {
	initialValue: number;
	onChangeType: (val: number) => void;
	semesters: Semester[];
	className?: string;
}

export const EvaluationHeader = (props: Props) => {
	const {
		initialValue,
		onChangeType,
		semesters,
		className,
		...restProps
	} = props;

	const createButtons = () => semesters?.map(semester => ({
		value: `${semester.id}`,
		label: semester.name,
		onClick: () => onChangeType(semester.id)
	})) ?? [];

	const buttons: { onClick: () => void; label: string; value: string }[] = createButtons();

	return (
		<header className={styles.container}>
			<ButtonGroup
				{...restProps}
				className={className}
				buttons={buttons}
				initialState={initialValue.toString()}
				type='secondary'
			/>
		</header>
	);
};
