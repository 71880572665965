import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useQuery } from 'react-query';

import { Icon } from 'app/components/ui/Icon';
import { Schedule } from 'app/components/features';
import { getStudentSchedule } from 'app/services/studentAPI';
import { EventType } from 'app/models/enums/event-type.enum';

import styles from './index.module.sass';

export const StudentSchedule = () => {
	const [date, setDate] = useState<string>();
	const [eventType, setEventType] = useState<EventType>(EventType.Lesson);

	const user = useSelector((state: any) => state.userSlice);

	const { data } = useQuery(['events', date, eventType], getStudentSchedule, {
		retry: 0,
		refetchOnWindowFocus: false,
		cacheTime: 0,
		enabled: !!date && !!eventType.toString()
	});

	const onDateChange = (date: string) => {
		setDate(date);
	}

	const onEventTypeChange = (type: EventType) => {
		setEventType(type);
	}

	return (
		<section className={styles.wrapper}>
			<aside className={styles.navigation}>
				<p className={styles.title}>
					{user?.school_class?.name}
				</p>
				<nav>
					<ul>
						<li>
							<NavLink
								to='schedule'
								className={cn(styles.link, styles.link_active)}
							>
								<Icon
									icon='schedule'
									size={24}
									className={styles.link__icon}
								/>
								Расписание
							</NavLink>
						</li>
					</ul>
				</nav>
			</aside>
			<section className={styles.content}>
				<Schedule
					data={data}
					onDateChange={onDateChange}
					onEventTypeChange={onEventTypeChange}
					forStudent
				/>
			</section>
		</section>
	);
}
