import React from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';

import { Icon } from 'app/components/ui';

import styles from './index.module.sass';

interface Props {
	lesson: any;
	className?: string;
}

export const LessonCard = (props: Props) => {
	const { lesson, className, ...restProps } = props;

	return (
		<div
			{...restProps}
			className={cn(styles.card, className)}
			style={{ background: `center right / contain no-repeat url(${lesson?.image})` }}
		>
			{
				lesson ? (
					<>
						<h2>{lesson?.name}</h2>
						<div className={styles.card__content}>
							<p className={styles.info}>
								<Icon
									icon='clock'
									size={20}
									className={styles.info__icon}
								/>
								{DateTime.fromISO(lesson?.start_at).toFormat('HH:mm')} – {DateTime.fromISO(lesson?.end_at).toFormat('HH:mm')}
							</p>
							<p className={styles.info}>
								<Icon
									icon='cabine'
									size={20}
								/>
								{lesson?.place}
							</p>
						</div>
					</>
				) : (
					<p className={styles.empty}>
						Урок отсутствует
					</p>
				)
			}
		</div>
	);
}
