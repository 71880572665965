import React, { PropsWithChildren, useEffect } from 'react';
import { FieldArray, FieldArrayRenderProps } from 'formik';
import { Button, DatePicker, Icon, Input, Textarea } from 'app/components/ui';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { SyllabusItem, Topic } from 'app/models/syllabus.interface';

import styles from './index.module.sass';

export const PlanBlock = (props: Partial<PropsWithChildren<FieldArrayRenderProps>>) => {
	const {
		form,
		replace = () => {},
		insert = () => {},
		remove = () => {},
	} = props;

	const getBlocks = () => form?.values?.data?.length > 0 ? form?.values?.data : [];

	useEffect(() => {
		if (form?.values?.data?.length === 0) {
			insert(
				getBlocks().length + 1,
				{ title: '', topics: [{ name: '', planned_date: '', actual_date: '' }] }
			);
		}
	}, [form]);

	// @ts-ignore
	const getBlockError = (blockIndex: number) => form?.errors?.data && form?.errors?.data[blockIndex]

	// @ts-ignore
	const getBlockTouched = (blockIndex: number) => form?.touched?.data && form?.touched?.data[blockIndex];

	// @ts-ignore
	const getLessonError = (blockIndex: number, lessonIndex: number) => form?.errors?.data && form?.errors?.data[blockIndex]?.topics[lessonIndex];

	// @ts-ignore
	const getLessonTouched = (blockIndex: number, lessonIndex: number) => form?.touched?.data && form?.touched?.data[blockIndex]?.topics[lessonIndex];

	const lessonsFieldArrayName = (index: number) => `data[${index}].topics`;

	return (
		<section className={styles.wrapper}>
			{
				getBlocks()?.map((block: SyllabusItem, blockIndex: number) =>
					<div
						key={blockIndex}
						className={styles.block}
					>
						<div className={styles.block__title}>
							<Input
								label='Заголовок'
								value={block.title}
								onChange={title => form?.setFieldValue(`data.${blockIndex}.title`, title)}
								hasError={
									!!getBlockError(blockIndex)?.title &&
									!!getBlockTouched(blockIndex)?.title
								}
								className={styles.field}
								withClear
							/>
							<Button
								onClick={() => replace(blockIndex, {
									...block,
									topics: [
										...block.topics,
										{
											name: '',
											planned_date: '',
											actual_date: '',
										}
									]
								})}
								leftIcon='plus'
								size={ButtonSize.s}
								theme={ButtonTheme.clear}
								className={styles.btn_oneline}
							>
								Добавить урок
							</Button>
							<button
								type='button'
								onClick={() => remove(blockIndex)}
								className={styles.remove}
								disabled={getBlocks()?.length === 1}
							>
								<Icon
									icon='clear'
									size={20}
								/>
							</button>
						</div>
						<FieldArray
							name={lessonsFieldArrayName(blockIndex)}
							render={() => (
								<div>
									{
										block.topics?.map((lesson: Topic, lessonIndex: number) =>
											<div
												key={`${blockIndex}-${lessonIndex}`}
												className={styles.lesson}
											>
												<Textarea
													label={`Урок ${lessonIndex + 1}`}
													value={lesson.name}
													onChange={e => form?.setFieldValue(
														`${lessonsFieldArrayName(blockIndex)}[${lessonIndex}].name`,
														(e.target as HTMLTextAreaElement).value
													)}
													name={`${lessonsFieldArrayName(blockIndex)}[${lessonIndex}].name`}
													hasError={
														!!getLessonError(blockIndex, lessonIndex)?.name &&
														!!getLessonTouched(blockIndex, lessonIndex)?.name
													}
													className={styles.lesson__title}
												/>

												<div className={styles.lesson__dates}>
													<DatePicker
														value={lesson?.planned_date}
														onChange={(value) => form?.setFieldValue(
															`${lessonsFieldArrayName(blockIndex)}[${lessonIndex}].planned_date`,
															value
														)}
														label='Планируемая дата'
														placeholder='Выберите дату'
														hasError={
															!!getLessonError(blockIndex, lessonIndex)?.planned_date &&
															!!getLessonTouched(blockIndex, lessonIndex)?.planned_date
														}
														className={styles.input}
													/>
													<DatePicker
														value={lesson?.actual_date}
														onChange={(value) => form?.setFieldValue(
															`${lessonsFieldArrayName(blockIndex)}[${lessonIndex}].actual_date`,
															value
														)}
														label='Фактическая дата'
														placeholder='Выберите дату'
														hasError={
															!!getLessonError(blockIndex, lessonIndex)?.actual_date &&
															!!getLessonTouched(blockIndex, lessonIndex)?.actual_date
														}
														className={styles.datepicker}
													/>
												</div>
												<button
													type='button'
													onClick={() => replace(blockIndex, {
														...block,
														topics: block.topics?.filter((item, index) => index !== lessonIndex)
													})}
													className={styles.remove}
													disabled={block.topics?.length === 1}
												>
													<Icon
														icon='clear'
														size={16}
													/>
												</button>
											</div>
										)
									}
								</div>
							)}
						/>
					</div>
				)
			}
			<Button
				size={ButtonSize.s}
				theme={ButtonTheme.secondary}
				leftIcon='plus'
				onClick={() => insert(
					getBlocks().length + 1,
					{ title: '', topics: [{ name: '', planned_date: '', actual_date: '', }] }
				)}
				className={styles.block__control}
			>
				Добавить заголовок
			</Button>
		</section>
	);
}
