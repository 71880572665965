import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import FileSaver from 'file-saver';
import { DateTime } from 'luxon';

import { getTeacherMedicalCertificates } from 'app/services/teacherAPI';
import { Button, Icon } from 'app/components/ui';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { FullSizeFridgeContainer } from 'app/components/modals';
import { ReferenceAddingForm } from './components/ReferenceAddingForm';

import styles from './index.module.sass';

export const TeacherClassSkips = () => {
	const currentClassId = useLocation().pathname.split('/')[3];

	const [isReferenceAddingOpen, setIsReferenceAddingOpen] = useState(false);

	const { data, refetch } = useQuery(['medical_certificates', currentClassId], getTeacherMedicalCertificates, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!currentClassId
	});

	const onOpenReferenceAddingHandle = () => setIsReferenceAddingOpen(true);

	const onCloseReferenceAddingHandle = () => {
		setIsReferenceAddingOpen(false);
		refetch();
	};

	return (
		<section className={styles.wrapper}>
			<header>
				<h1>Пропуски</h1>
				<Button
					onClick={onOpenReferenceAddingHandle}
					leftIcon='plus'
					theme={ButtonTheme.secondary}
					size={ButtonSize.m}
				>
					Добавить справку
				</Button>
			</header>
			<section className={styles.content}>
				<div className={styles.content__block}>
					{
						data?.map(item => (
							<div key={Math.random()}
								 className={styles.reference}>
								<div className={styles.reference__header}>
									<Icon icon='document' size={24} />
									<span>Справка</span>
								</div>
								<div className={styles.reference__content}>
									<div className={styles.info}>
										<div>
											<span className={styles.info__label}>
												ученик
											</span>
											<span
												className={styles.info__content}>
												{`${item?.student.first_name} ${item?.student.last_name}`}
											</span>
										</div>
										<div>
											<span className={styles.info__label}>
												даты отсутствия
											</span>
											<span
												className={styles.info__content}>
												{DateTime.fromISO(item?.start_at).toFormat('dd.MM.yyyy')} - {DateTime.fromISO(item?.end_at).toFormat('dd.MM.yyyy')}
											</span>
										</div>
									</div>
									<Button
										onClick={() => {
											FileSaver.saveAs(item?.file.file as unknown as Blob,
												item?.file?.presentation_name ?? 'Справка');
										}}
										theme={ButtonTheme.ghost}
									>
										Скачать
									</Button>
								</div>
							</div>
						))
					}
				</div>
			</section>
			<FullSizeFridgeContainer
				isOpen={isReferenceAddingOpen}
				onClose={onCloseReferenceAddingHandle}
			>
				<ReferenceAddingForm
					classId={currentClassId}
					onCancel={onCloseReferenceAddingHandle}
				/>
			</FullSizeFridgeContainer>
		</section>
	);
}
