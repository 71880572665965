import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { toast } from 'react-toastify';

import { Button, Icon } from 'app/components/ui';
import { ConfirmModal, FullSizeFridgeContainer } from 'app/components/modals';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { StudentTaskType } from 'app/models/enums/student-task-type.enum';
import { getClassTasks, removeClassTask } from 'app/services/teacherAPI';

import avatarDefault from 'assets/images/avatarDefault.png';

import { ClassTaskAddingForm } from './components/ClassTaskAddingForm';

import styles from './index.module.sass';

export const TeacherClassTasks = () => {
	const classId = useLocation().pathname.split('/')[3];

	const [isTaskAddingOpen, setIsTaskAddingOpen] = useState(false);
	const [isTaskRemovingOpen, setIsTaskRemovingOpen] = useState(false);
	const [taskForRemove, setTaskForRemove] = useState<number>();
	const [currentTaskType, setCurrentTaskType] = useState<StudentTaskType>();
	const [showAllHomeWorkTasks, setShowAllHomeWorkTasks] = useState(false);
	const [showPersonalWorkTasks, setShowPersonalWorkTasks] = useState(false);
	const [showControlWorkTasks, setShowControlWorkTasks] = useState(false);
	const [showPersonalStudyingTasks, setShowPersonalStudyingTasks] = useState(false);
	const [showOtherTasks, setShowOtherTasks] = useState(false);

	const { data: tasks, refetch } = useQuery(['class-tasks', classId], getClassTasks, {
		retry: 0,
		refetchOnWindowFocus: false,
		cacheTime: 0,
		enabled: !!classId
	});

	const removeTaskMutation = useMutation((taskId: number) => removeClassTask(taskId));

	const onOpenTaskAddingHandle = (type: StudentTaskType) => {
		setCurrentTaskType(type);
		setIsTaskAddingOpen(true);
	}

	const onCloseTaskAddingHandle = (withReload = false) => {
		if (typeof withReload === 'boolean' && withReload) {
			refetch();
		}

		setIsTaskAddingOpen(false);
	}

	const removeTask = (id: number) => {
		setIsTaskRemovingOpen(true);
		setTaskForRemove(id);
	}

	const removeTaskAccept = () => {
		setIsTaskRemovingOpen(false);

		if (taskForRemove) {
			removeTaskMutation.mutate(
				taskForRemove,
				{
					onSuccess: () => {
						toast.success('Задача удалена');
						setTaskForRemove(undefined);
						refetch();
					},
					onError: () => {
						toast.error('Не удалось удалить задачу');
					}
				}
			);
		}
	}

	return (
		<section className={styles.content}>
			<h2>Задачи</h2>
			<div className={styles.block}>
				<div className={styles.block__header}>
					<section className={styles.caption}>
						<h4>Домашнее задание</h4>
						{
							tasks && tasks['0']?.length > 0 &&
							<p className={styles.count}>
								{tasks && tasks['0']?.length}
							</p>
						}
					</section>
					<Button
						onClick={() => onOpenTaskAddingHandle(StudentTaskType.HomeWork)}
						theme={ButtonTheme.secondary}
						size={ButtonSize.xs}
					>
						+
					</Button>
				</div>
				{
					!tasks || tasks['0']?.length === 0 &&
						<p className={styles.block__empty_message}>
							Пока нет ни одной задачи
						</p>
				}
				{
					tasks && tasks['0']?.length > 0 &&
					<section className={styles.tasks}>
						{
							tasks['0']?.slice(0,showAllHomeWorkTasks ? tasks['0']?.length : 3)?.map((task: any) => (
								<div
									key={task?.id}
									className={cn(styles.tasks__item, styles.tasks__item_home_work)}
								>
									<button
										onClick={() => removeTask(task?.id)}
										className={styles.close}
									>
										<Icon
											icon="clear"
											size={20}
										/>
									</button>
									<p className={styles.tasks__item__caption}>
										{
											task?.topic && <>{task?.topic}: <br /></>
										}
										{task?.description}
									</p>
									<section>
										<div className={styles.tasks__item__info}>
											{
												task?.subject?.full_name &&
												<p className={styles.tasks__item__info__item}>
													{task?.subject?.full_name}
												</p>
											}
											<p className={styles.tasks__item__info__item}>
												{DateTime.fromISO(task?.deadline).toFormat('dd.MM')}
											</p>
										</div>
										<div className={styles.tasks__item__controls}>
											<div className={styles.author}>
												<img
													className={styles.avatar}
													src={task?.author?.avatar ? `${process.env.REACT_APP_BACK_URL}${task?.author?.avatar}` : avatarDefault}
													alt='author'
												/>
												{task?.author?.first_name} {task?.author?.patronymic}
											</div>
										</div>
									</section>
								</div>
							))
						}
					</section>
				}
				{
					tasks && tasks['0']?.length > 3 &&
					<Button
						theme={ButtonTheme.secondary}
						stretch
						onClick={() => setShowAllHomeWorkTasks(!showAllHomeWorkTasks)}
						className={styles.more}
					>
						{showAllHomeWorkTasks ? 'Свернуть' : 'Показать еще'}
					</Button>
				}
			</div>
			<div className={styles.block}>
				<div className={styles.block__header}>
					<section className={styles.caption}>
						<h4>Самостоятельная работа</h4>
						{
							tasks && tasks['1']?.length > 0 &&
							<p className={styles.count}>
								{tasks && tasks['1']?.length}
							</p>
						}
					</section>
					<Button
						onClick={() => onOpenTaskAddingHandle(StudentTaskType.PersonalWork)}
						theme={ButtonTheme.secondary}
						size={ButtonSize.xs}
					>
						+
					</Button>
				</div>
				{
					!tasks || tasks['1']?.length === 0 &&
					<p className={styles.block__empty_message}>
						Пока нет ни одной задачи
					</p>
				}
				{
					tasks && tasks['1']?.length > 0 &&
					<section className={styles.tasks}>
						{
							tasks['1']?.slice(0,showPersonalWorkTasks ? tasks['1']?.length : 3)?.map((task: any) => (
								<div
									key={task?.id}
									className={cn(styles.tasks__item, styles.tasks__item_personal_work)}
								>
									<button
										onClick={() => removeTask(task?.id)}
										className={styles.close}
									>
										<Icon
											icon="clear"
											size={20}
										/>
									</button>
									<p className={styles.tasks__item__caption}>
										{
											task?.topic && <>{task?.topic}: <br /></>
										}
										{task?.description}
									</p>
									<section>
										<div className={styles.tasks__item__info}>
											{
												task?.subject?.full_name &&
												<p className={styles.tasks__item__info__item}>
													{task?.subject?.full_name}
												</p>
											}
											<p className={styles.tasks__item__info__item}>
												{DateTime.fromISO(task?.deadline).toFormat('dd.MM')}
											</p>
										</div>
										<div className={styles.tasks__item__controls}>
											<div className={styles.author}>
												<img
													className={styles.avatar}
													src={task?.author?.avatar ? `${process.env.REACT_APP_BACK_URL}${task?.author?.avatar}` : avatarDefault}
													alt='author'
												/>
												{task?.author?.first_name} {task?.author?.patronymic}
											</div>
										</div>
									</section>
								</div>
							))
						}
					</section>
				}
				{
					tasks && tasks['1']?.length > 3 &&
					<Button
						theme={ButtonTheme.secondary}
						stretch
						onClick={() => setShowPersonalWorkTasks(!showPersonalWorkTasks)}
						className={styles.more}
					>
						{showPersonalWorkTasks ? 'Свернуть' : 'Показать еще'}
					</Button>
				}
			</div>
			<div className={styles.block}>
				<div className={styles.block__header}>
					<section className={styles.caption}>
						<h4>Контрольная работа</h4>
						{
							tasks && tasks['2']?.length > 0 &&
							<p className={styles.count}>
								{tasks && tasks['2']?.length}
							</p>
						}
					</section>
					<Button
						onClick={() => onOpenTaskAddingHandle(StudentTaskType.ControlWork)}
						theme={ButtonTheme.secondary}
						size={ButtonSize.xs}
					>
						+
					</Button>
				</div>
				{
					!tasks || tasks['2']?.length === 0 &&
					<p className={styles.block__empty_message}>
						Пока нет ни одной задачи
					</p>
				}
				{
					tasks && tasks['2']?.length > 0 &&
					<section className={styles.tasks}>
						{
							tasks['2']?.slice(0,showControlWorkTasks ? tasks['2']?.length : 3)?.map((task: any) => (
								<div
									key={task?.id}
									className={cn(styles.tasks__item, styles.tasks__item_control_work)}
								>
									<button
										onClick={() => removeTask(task?.id)}
										className={styles.close}
									>
										<Icon
											icon="clear"
											size={20}
										/>
									</button>
									<p className={styles.tasks__item__caption}>
										{
											task?.topic && <>{task?.topic}: <br /></>
										}
										{task?.description}
									</p>
									<section>
										<div className={styles.tasks__item__info}>
											{
												task?.subject?.full_name &&
												<p className={styles.tasks__item__info__item}>
													{task?.subject?.full_name}
												</p>
											}
											<p className={styles.tasks__item__info__item}>
												{DateTime.fromISO(task?.deadline).toFormat('dd.MM')}
											</p>
										</div>
										<div className={styles.tasks__item__controls}>
											<div className={styles.author}>
												<img
													className={styles.avatar}
													src={task?.author?.avatar ? `${process.env.REACT_APP_BACK_URL}${task?.author?.avatar}` : avatarDefault}
													alt='author'
												/>
												{task?.author?.first_name} {task?.author?.patronymic}
											</div>
										</div>
									</section>
								</div>
							))
						}
					</section>
				}
				{
					tasks && tasks['2']?.length > 3 &&
					<Button
						theme={ButtonTheme.secondary}
						stretch
						onClick={() => setShowControlWorkTasks(!showControlWorkTasks)}
						className={styles.more}
					>
						{showControlWorkTasks ? 'Свернуть' : 'Показать еще'}
					</Button>
				}
			</div>
			<div className={styles.block}>
				<div className={styles.block__header}>
					<section className={styles.caption}>
						<h4>Самостоятельное изучение</h4>
						{
							tasks && tasks['3']?.length > 0 &&
							<p className={styles.count}>
								{tasks && tasks['3']?.length}
							</p>
						}
					</section>
					<Button
						onClick={() => onOpenTaskAddingHandle(StudentTaskType.PersonalStudying)}
						theme={ButtonTheme.secondary}
						size={ButtonSize.xs}
					>
						+
					</Button>
				</div>
				{
					!tasks || tasks['3']?.length === 0 &&
					<p className={styles.block__empty_message}>
						Пока нет ни одной задачи
					</p>
				}
				{
					tasks && tasks['3']?.length > 0 &&
					<section className={styles.tasks}>
						{
							tasks['3']?.slice(0,showPersonalStudyingTasks ? tasks['3']?.length : 3)?.map((task: any) => (
								<div
									key={task?.id}
									className={cn(styles.tasks__item, styles.tasks__item_personal_studying)}
								>
									<button
										onClick={() => removeTask(task?.id)}
										className={styles.close}
									>
										<Icon
											icon="clear"
											size={20}
										/>
									</button>
									<p className={styles.tasks__item__caption}>
										{
											task?.topic && <>{task?.topic}: <br /></>
										}
										{task?.description}
									</p>
									<section>
										<div className={styles.tasks__item__info}>
											{
												task?.subject?.full_name &&
												<p className={styles.tasks__item__info__item}>
													{task?.subject?.full_name}
												</p>
											}
											<p className={styles.tasks__item__info__item}>
												{DateTime.fromISO(task?.deadline).toFormat('dd.MM')}
											</p>
										</div>
										<div className={styles.tasks__item__controls}>
											<div className={styles.author}>
												<img
													className={styles.avatar}
													src={task?.author?.avatar ? `${process.env.REACT_APP_BACK_URL}${task?.author?.avatar}` : avatarDefault}
													alt='author'
												/>
												{task?.author?.first_name} {task?.author?.patronymic}
											</div>
										</div>
									</section>
								</div>
							))
						}
					</section>
				}
				{
					tasks && tasks['3']?.length > 3 &&
					<Button
						theme={ButtonTheme.secondary}
						stretch
						onClick={() => setShowPersonalStudyingTasks(!showPersonalStudyingTasks)}
						className={styles.more}
					>
						{showPersonalStudyingTasks ? 'Свернуть' : 'Показать еще'}
					</Button>
				}
			</div>
			<div className={styles.block}>
				<div className={styles.block__header}>
					<section className={styles.caption}>
						<h4>Другое</h4>
						{
							tasks && tasks['4']?.length > 0 &&
							<p className={styles.count}>
								{tasks && tasks['4']?.length}
							</p>
						}
					</section>
					<Button
						onClick={() => onOpenTaskAddingHandle(StudentTaskType.Other)}
						theme={ButtonTheme.secondary}
						size={ButtonSize.xs}
					>
						+
					</Button>
				</div>
				{
					!tasks || tasks['4']?.length === 0 &&
					<p className={styles.block__empty_message}>
						Пока нет ни одной задачи
					</p>
				}
				{
					tasks && tasks['4']?.length > 0 &&
					<section className={styles.tasks}>
						{
							tasks['4']?.slice(0,showOtherTasks ? tasks['4']?.length : 3)?.map((task: any) => (
								<div
									key={task?.id}
									className={cn(styles.tasks__item, styles.tasks__item_other)}
								>
									<button
										onClick={() => removeTask(task?.id)}
										className={styles.close}
									>
										<Icon
											icon="clear"
											size={20}
										/>
									</button>
									<p className={styles.tasks__item__caption}>
										{
											task?.topic && <>{task?.topic}: <br /></>
										}
										{task?.description}
									</p>
									<section>
										<div className={styles.tasks__item__info}>
											{
												task?.subject?.full_name &&
												<p className={styles.tasks__item__info__item}>
													{task?.subject?.full_name}
												</p>
											}
											<p className={styles.tasks__item__info__item}>
												{DateTime.fromISO(task?.deadline).toFormat('dd.MM')}
											</p>
										</div>
										<div className={styles.tasks__item__controls}>
											<div className={styles.author}>
												<img
													className={styles.avatar}
													src={task?.author?.avatar ? `${process.env.REACT_APP_BACK_URL}${task?.author?.avatar}` : avatarDefault}
													alt='author'
												/>
												{task?.author?.first_name} {task?.author?.patronymic}
											</div>
										</div>
									</section>
								</div>
							))
						}
					</section>
				}
				{
					tasks && tasks['4']?.length > 3 &&
					<Button
						theme={ButtonTheme.secondary}
						stretch
						onClick={() => setShowOtherTasks(!showOtherTasks)}
						className={styles.more}
					>
						{showOtherTasks ? 'Свернуть' : 'Показать еще'}
					</Button>
				}
			</div>
			<ConfirmModal
				isOpen={isTaskRemovingOpen}
				onClose={() => {
					setTaskForRemove(undefined);
					setIsTaskRemovingOpen(false)
				}}
				onAccept={removeTaskAccept}
			>
				<h3>Действительно хотите <br /> удалить задачу?</h3>
			</ConfirmModal>
			<FullSizeFridgeContainer
				isOpen={isTaskAddingOpen}
				onClose={onCloseTaskAddingHandle}
			>
				<ClassTaskAddingForm
					type={currentTaskType!}
					onCancel={onCloseTaskAddingHandle}
				/>
			</FullSizeFridgeContainer>
		</section>
	);
}
