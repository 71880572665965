import React, { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import { Button, Input } from 'app/components/ui';
import { changePassword } from 'app/services/authAPI';

import styles from 'app/pages/Login/index.module.sass';

const ChangePasswordFormSchema = Yup.object().shape({
	password: Yup.string()
		.min(8, 'Пароль должен быть не меньше 8 символов')
		.required('Заполните поле'),
	password_repetition: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
		.required('Заполните поле')
});

export const ChangePassword: FC = () => {
	const [params] = useSearchParams();
	const navigate = useNavigate();

	const mutation = useMutation(
		(value: {
			new_password1: string;
			new_password2: string;
			token: string;
			uid: string;
		}) => changePassword(value));

	return (
		<>
			<h1>Смена пароля</h1>
			<Formik
				initialValues={{
					password: '',
					password_repetition: ''
				}}
				validationSchema={ChangePasswordFormSchema}
				onSubmit={(
					values
				) => {
					mutation.mutate({
						new_password1: values.password,
						new_password2: values.password_repetition,
						token: params.get('token') ?? '',
						uid: params.get('uid') ?? ''
					}, {
						onSuccess: () => {
							navigate('/login');
							toast.success('Пароль изменен');
						},
						onError: () => {
							toast.error('Произошла ошибка');
						}
					})
				}}
			>
				{({ values, setFieldValue }) => (
					<Form className={styles.form}>
						<div className={styles.form__field}>
							<Input
								leftIcon='lock'
								placeholder='Новый пароль'
								type='password'
								value={values.password}
								onChange={val => setFieldValue('password', val)}
								iconColors='#225fa8'
								className={styles.form__field}
							/>
							<p className={styles.form__field__error}>
								<ErrorMessage name='password' />
							</p>
						</div>

						<div className={styles.form__field}>
							<Input
								leftIcon='lock'
								placeholder='Пароль еще раз'
								type='password'
								value={values.password_repetition}
								onChange={val => setFieldValue('password_repetition', val)}
								iconColors='#225fa8'
							/>

							<p className={styles.form__field__error}>
								<ErrorMessage name='password_repetition' />
							</p>
						</div>
						<Button
							type='submit'
							className={styles.form__control}
							disabled={mutation.isLoading}
							stretch
						>
							Обновить пароль
						</Button>
					</Form>
				)}
			</Formik>
		</>
	);
}
