import { QueryFunctionContext } from 'react-query';
import axios from 'axios';

import { ScheduleEventListItem } from 'app/models/schedule-event.interface';
import { EventType } from 'app/models/enums/event-type.enum';
import { Student } from 'app/models/student.interface';
import { Parent } from 'app/models/parent.interface';
import { User } from 'app/models/user.interface';
import { StudentDetailGrades } from 'app/models/student-rating.model';
import { Score } from 'app/models/score.interface';

export const getStudentSchedule = async({ queryKey }: QueryFunctionContext): Promise<Array<ScheduleEventListItem>> => {
	const [, date, eventType] = queryKey as [string, number, EventType];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/schedule/`, {
		params: {
			date,
			type: eventType
		}
	});
	return response?.data;
}

export const getStudentClassmates = async(): Promise<Array<Student>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/classmates/`);
	return response?.data;
}

export const getStudentClassmatesParents = async(): Promise<Array<Parent>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/classmates/parents/`);
	return response?.data;
}

export const getStudentInfo = async({ queryKey }: QueryFunctionContext): Promise<Student> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${id}/`);
	return response?.data;
}

export const getStudentParents = async({ queryKey }: QueryFunctionContext): Promise<Array<User>> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${id}/parents/`);
	return response?.data;
}

export const getStudentTasks = async(): Promise<Array<any>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/tasks/`);
	return response.data;
}

export const getStudentEvaluation = async({ queryKey }: QueryFunctionContext): Promise<Array<StudentDetailGrades>> => {
	const [, semester_id, student_id] = queryKey as [string, number, string];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${student_id}/grades/`, {
		params: {
			semester_id
		}
	});
	return response?.data;
}

export const finishStudentTasks = (data: number[]) =>
	axios.patch(`${process.env.REACT_APP_API_URL}/students/tasks/finish/`, {
			task_ids: data
		}
	);

export const getStudentNearestLessons = async({ queryKey }: QueryFunctionContext): Promise<Array<any>> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${id}/nearest_lessons/`);
	return response.data;
}

export const getStudentNearestEvents = async({ queryKey }: QueryFunctionContext): Promise<Array<any>> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${id}/nearest_events/`);
	return response.data;
}

export const getStudentAverageScores = async({ queryKey }: QueryFunctionContext): Promise<Array<Score>> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${id}/average_grades/`);
	return response.data;
}

export const getStudentTasksCount = async({ queryKey }: QueryFunctionContext): Promise<number> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${id}/tasks/count/`);
	return response?.data;
}

export const getStudentBirthdays = async(): Promise<Array<Student>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/birthday/`);
	return response?.data;
}

export const getStudentNearestTasks = async({ queryKey }: QueryFunctionContext): Promise<Array<any>> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${id}/nearest_tasks/`);
	return response?.data;
}

