import React, { ChangeEvent, useEffect, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useMutation, useQuery } from 'react-query';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroller';

import { Button, Checkbox, Icon, Input } from 'app/components/ui';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { numberEndings } from 'app/utils/numberEndings';
import {
	createGroup,
	getContacts,
	getGroupChats, uploadChatFile
} from 'app/services/messengerAPI';
import { useAppSelector } from 'app/hooks/reduxHooks';
import { setUnreadMessagesCount } from 'app/reducers/unreadMessagesCountSlice';
import { UserRole } from 'app/models/enums/user-role.enum';
import { File as UserFile } from 'app/models/file.interface';
import { GroupChat } from 'app/models/group-chat.interface';
import { Contact } from 'app/models/contact.interface';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

export const Groups = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const { id } = useParams();

	const user = useAppSelector((state: any) => state.userSlice);
	const notificationsCount = useSelector((state: any) => state.notificationsCountSlice);

	const [currentStep, setCurrentStep] = useState(0);
	const [selectedFilters, setSelectedFilters] = useState<number[]>([]);
	const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
	const [selectedCover, setSelectedCover] = useState<UserFile | null>(null);
	const [theme, setTheme] = useState('');

	const limit = 25;
	const [groupsOffset, setGroupsOffset] = useState(0);
	const [groups, setGroups] = useState<Array<GroupChat>>([]);
	const [contacts, setContacts] = useState<Array<Contact>>([]);

	const { data: groupsData, refetch: refetchGroups, isLoading } = useQuery(['group_chats', limit, groupsOffset], getGroupChats, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: false,
		cacheTime: 0
	});

	const { data: contactsData, refetch: refetchContacts } = useQuery(['contacts', selectedFilters.toString(), limit], getContacts, {
		retry: 0,
		refetchOnWindowFocus: false,
		cacheTime: 0,
		enabled: false
	});

	useEffect(() => {
		refetchGroups();
	}, [groupsOffset]);

	useEffect(() => {
		if (!groupsData) {
			return;
		}

		if(groupsData?.results?.length > 0) {
			const results = groupsData?.results;

			if (groupsOffset === 0) {
				setGroups(results);
			} else {
				setGroups([...groups, ...results]);
			}
		}
	}, [groupsData]);

	useEffect(() => {
		if (contactsData === undefined) {
			return;
		}

		setContacts(contactsData);
	}, [contactsData]);

	const uploadFileMutation = useMutation((file: File) => uploadChatFile(file));

	const createGroupMutation = useMutation(
		(value: { theme: string, image: number | null, members: number[] }) =>
			createGroup(value.theme, value.image, value.members), {
			onSuccess: () => {
				if (groupsOffset === 0) {
					refetchGroups();
				} else {
					setGroupsOffset(0);
				}
			}
		}
	);

	useEffect(() => {
		refetchContacts();
	}, [selectedFilters]);

	const addFileHandle = (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		if (!files) {
			return null;
		}

		return uploadFileMutation.mutate(files[0], {
			onSuccess: res => {
				setSelectedCover(res.data);
			},
		});
	};

	const setChatWatched = (id: number, has_unread_messages: boolean) => {
		if (has_unread_messages) {
			dispatch(setUnreadMessagesCount(notificationsCount > 1 ? notificationsCount - 1 : 0));
		}

		const newGroups = groups.map(group => {
			if (group.id === id) {
				return { ...group, has_unread_messages: false };
			}

			return group;
		});

		setGroups(newGroups);
	}

	return (
		<>
			{
				(
					selectedContacts.length > 0 && currentStep === 1 ||
					!!theme && currentStep === 2
				) &&
				<section className={styles.tooltip}>
					<div className={styles.tooltip__caption}>
						{
							selectedContacts.length > 0 && currentStep === 1 &&
							<>
								<Button
									onClick={() => setSelectedContacts([])}
									theme={ButtonTheme.secondary}
									className={styles.button_clear}
								>
									Снять выделение
								</Button>
								<p>
									Выбрано {selectedContacts.length}
									{numberEndings(selectedContacts.length, [' участник', ' участника', ' участников'])}
								</p>
							</>
						}
						{
							!!theme && currentStep === 2 &&
							<Button
								onClick={() => {
									setCurrentStep(0);
									setSelectedContacts([]);
									setSelectedFilters([]);
									setTheme('');
								}}
								theme={ButtonTheme.secondary}
								className={styles.button_clear}
							>
								Отменить
							</Button>
						}
					</div>
					{
						selectedContacts.length > 0 && currentStep === 1 &&
						<Button
							onClick={() => setCurrentStep(2)}
							theme={ButtonTheme.secondary}
							className={styles.button_finish}
						>
							Далее
						</Button>
					}
					{
						!!theme && currentStep === 2 &&
						<Button
							onClick={() => {
								createGroupMutation.mutate({ theme, image: selectedCover?.id!, members: selectedContacts }, {
									onSuccess: () => {
										setCurrentStep(0);
										setSelectedContacts([]);
										setSelectedFilters([]);
										setTheme('');
										setSelectedCover(null);

										setGroupsOffset(0);

										toast.success('Группа успешно создана');
									},
									onError: () => {
										toast.error('Не удалось создать группу');
									}
								});
							}}
							theme={ButtonTheme.secondary}
							className={styles.button_finish}
						>
							Создать группу
						</Button>
					}
				</section>
			}
			{
				user?.role === UserRole.Teacher &&
				<section className={styles.header}>
					{
						currentStep === 0 &&
						<Button
							onClick={() => setCurrentStep(1)}
							theme={ButtonTheme.secondary}
							size={ButtonSize.s}
							stretch
						>
							+ Добавить группу
						</Button>
					}
					{
						currentStep === 1 &&
						<>
							<Button
								onClick={() => {
									setCurrentStep(0);
									setSelectedFilters([]);
									setSelectedContacts([]);
								}}
								theme={ButtonTheme.clear}
								size={ButtonSize.s}
								leftIcon='back'
							>
								Добавление участников группы
							</Button>
							<div className={styles.filters}>
								<h4>Фильтр</h4>
								<Checkbox
									value={selectedFilters.includes(1)}
									onChange={event => setSelectedFilters(
										event.target.checked ? [...selectedFilters, 1] :
											selectedFilters.filter(item => item !== 1)
									)}
									type='circle'
									className={styles.filters__item}
								>
									Преподаватели
								</Checkbox>
								<Checkbox
									value={selectedFilters.includes(0)}
									onChange={event => setSelectedFilters(
										event.target.checked ? [...selectedFilters, 0] :
											selectedFilters.filter(item => item !== 0)
									)}
									type='circle'
									className={styles.filters__item}
								>
									Ученики
								</Checkbox>
								<Checkbox
									value={selectedFilters.includes(2)}
									onChange={event => setSelectedFilters(
										event.target.checked ? [...selectedFilters, 2] :
											selectedFilters.filter(item => item !== 2)
									)}
									type='circle'
									className={styles.filters__item}
								>
									Родители
								</Checkbox>
							</div>
						</>
					}
					{
						currentStep === 2 &&
						<Button
							onClick={() => {
								setCurrentStep(1);
							}}
							theme={ButtonTheme.clear}
							size={ButtonSize.s}
							leftIcon='back'
							className={styles.button}
						>
							Новая группа
						</Button>
					}
				</section>
			}
			{
				currentStep === 0 &&
				<section className={styles.container}>
					{
						!isLoading && groups?.length === 0 ?
							<p className={styles.chat_empty}>Группы отсутствуют</p> :
							<InfiniteScroll
								pageStart={0}
								initialLoad={false}
								loadMore={() => setGroupsOffset(groupsOffset + limit)}
								hasMore={groups?.length < groupsData?.count!}
								useWindow={false}
							>
								{
									groups?.map(item => (
										<NavLink
											key={item?.id}
											to={`groups/${item?.id}`}
											onClick={() => setChatWatched(item?.id, item?.has_unread_messages)}
											className={cn(
												styles.chat,
												{[styles.chat_active]: location.pathname.includes('groups') && +id! === item?.id}
											)}
										>
											<img
												src={item?.image || avatarDefault}
												className={styles.chat__avatar}
												alt="Аватар"
											/>
											<div className={styles.chat__info}>
												<p className={styles.name}>{item?.name}</p>
												<p className={styles.message}>{item?.last_message_text}</p>
											</div>
											<div className={styles.chat__flags}>
												{
													item?.last_message_date &&
													<p className={styles.time}>
														{DateTime.fromISO(item?.last_message_date).toFormat('dd.MM.yy HH:mm')}
													</p>
												}
												{
													item?.has_unread_messages && <div className={styles.circle} />
												}
											</div>
										</NavLink>
									))
								}
							</InfiniteScroll>
					}
				</section>
			}
			{
				currentStep === 1 &&
				<section className={cn(styles.container, styles.container_contacts)}>
					{
						contacts?.map(item => (
							<div
								key={item?.id}
								className={cn(styles.chat, styles.chat_without_hover)}
							>
								<img
									src={item?.avatar || avatarDefault}
									className={styles.chat__avatar}
									alt="Аватар"
								/>
								<div className={styles.chat__info}>
									<p className={styles.name}>{item?.last_name} {item?.first_name}</p>
									<p className={styles.name}>{item?.info}</p>
								</div>
								<Checkbox
									value={selectedContacts.includes(item?.id)}
									onChange={event => setSelectedContacts(
										event.target.checked ? [...selectedContacts, item?.id] :
											selectedContacts.filter(contact => contact !== item?.id)
									)}
									className={styles.filters__item}
								/>
							</div>
						))
					}
				</section>
			}
			{
				currentStep === 2 &&
				<div className={styles.group}>
					<label
						htmlFor='fileInput'
					>
						{
							!selectedCover ? (
								<div className={styles.group__avatar}>
									<Icon icon='img' />
									<p>Добавить <br /> картинку группы</p>
								</div>
							) : (
								<img
									src={selectedCover?.file}
									alt='group cover'
									className={styles.group__cover}
								/>
							)
						}
						<input
							id='fileInput'
							type='file'
							onChange={e => addFileHandle(e)}
						/>
					</label>
					<Input
						value={theme}
						onChange={value => setTheme(value)}
						placeholder='Тема группы'
						className={styles.group__theme}
					/>
				</div>
			}
		</>
	);
}
