import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Grade } from 'app/models/student-rating.model';
import { getGradesTypes } from 'app/services/schoolAPI';
import { getStudyingPlanTopics } from 'app/services/teacherAPI';
import { AlfaSelect, Button, Textarea } from 'app/components/ui';
import { TextareaSize } from 'app/components/ui/Textarea';
import { SelectSize } from 'app/components/ui/Select';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import EvaluationTag
	from 'app/components/features/Evaluation/components/EvaluationTag';
import {
	EvaluationPropertiesContext,
	GRADES
} from 'app/components/features/TeacherEvaluation/utils';

import styles from '../index.module.sass';

const GradeFormSchema = Yup.object().shape({
	topic: Yup.object().shape({
		label: Yup.string().required(),
	}),
	type: Yup.object().shape({
		label: Yup.string().required(),
	}),
	score: Yup.string().required()
});

interface FormGradeValues {
	score: number;
	comment: string;
	topic?: {
		value: string;
		label: string;
	};
	type?: {
		value: number;
		label: string;
	};
}

interface Props {
	data: Grade | null;
	onChange: (value: Grade) => void;
	onDelete: () => void;
}

export const GradeForm = ({onChange, onDelete, data}: Props) => {
	const { classId, subjectId } = useContext(EvaluationPropertiesContext);

	const { data: topics} = useQuery(['types', classId, subjectId], getStudyingPlanTopics, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!classId && !!subjectId
	});

	const { data: gradeTypes} = useQuery('types', getGradesTypes, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const topicsOptions = topics?.map(topic => ({
		value: topic,
		label: topic
	})) ?? [];

	const gradeTypesOptions = gradeTypes?.map(grade => ({
		value: grade.id,
		label: grade.name
	})) ?? [];

	const getGradeOption = (id: number) => gradeTypesOptions.find(grade => id === grade.value);

	const [initialValues, setInitialValues] = useState<FormGradeValues>({
		score: 0,
		comment: '',
	});

	useEffect(() => {
		if (!gradeTypes) {
			return;
		}

		setInitialValues(prev => ({
			...prev,
			comment: '',
			...data,
			topic: data?.topic ? {
				value: data.topic,
				label: data.topic
			} : undefined,
			type: data?.type ? getGradeOption(data.type) : undefined
		}));
	}, [data, gradeTypes]);

	return (
		<Formik
			enableReinitialize
			initialValues={initialValues}
			validationSchema={GradeFormSchema}
			onSubmit={values => {
				const { topic, type, score, comment } = values;
				return onChange({
					id: data?.id,
					type: type!.value,
					topic: topic!.value,
					score,
					comment,
				} as Grade);
			}}
		>
			{({ values, setFieldValue, errors, touched }) => (
				<Form className={styles.form}>
					<AlfaSelect
						label='Тема урока'
						value={(values as any).topic}
						options={topicsOptions}
						onChange={(v) => setFieldValue('topic', v)}
						hasError={!!errors.topic && !!touched.topic}
						size={SelectSize.s}
						className={styles.select}
					/>
					<AlfaSelect
						label='Тип оценки'
						value={(values as any).type}
						options={gradeTypesOptions}
						onChange={(v) => setFieldValue('type', v)}
						hasError={!!errors.type && !!touched.type}
						size={SelectSize.s}
						className={styles.select}
					/>
					<section className={styles.grades}>
						<span className={styles.label}>Оценка</span>
						<section className={styles.grades__list}>
							{
								GRADES.map(grade =>
									<button
										type='button'
										onClick={() => setFieldValue('score', grade)}
										key={grade}
									>
										<EvaluationTag
											isActive={grade === values.score}
											value={grade}
										/>
									</button>
								)
							}
						</section>
					</section>
					<Textarea
						label='Комментарий'
						onChange={e => setFieldValue('comment', (e.target as HTMLTextAreaElement).value)}
						value={values.comment}
						size={TextareaSize.s}
						className={styles.textarea}
					/>
					<hr className={styles.delimiter}/>
					<section className={styles.form__footer}>
						{
							data &&
							<Button
								onClick={onDelete}
								theme={ButtonTheme.ghost}
								size={ButtonSize.s}
							>
								Удалить
							</Button>
						}
						<Button
							type='submit'
							size={ButtonSize.s}
						>
							{data ? 'Редактировать' : 'Сохранить'}
						</Button>
					</section>
				</Form>
			)}
		</Formik>
	);
}
