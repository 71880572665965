import React from 'react';
import cn from 'classnames';

import { Slider } from '../../ui/Slider';

import styles from './index.module.sass';

interface Props {
	tasks: any[] | undefined;
	className?: string;
}

export const StudentTasks = (props: Props) => {
	const { tasks, className, ...restProps } = props;

	return (
		<div
			{...restProps}
			className={
				cn(
					styles.container,
					className,
					{[styles.container_empty]: tasks?.filter((item: any) => item?.status === 0)?.length === 0}
				)
			}
		>
			{
				tasks && tasks?.filter((item: any) => item?.status === 0)?.length > 0 ?
					<Slider
						withNavigation
						slidesPerView={4}
						className={styles.block__content}
					>
						{
							tasks
								?.filter((item: any) => item?.status === 0)
								?.map((item: any) => (
									<div
										key={item?.id}
										className={
											cn(
												styles.stick,
												item?.type === 0 ? styles.stick_pink : '',
												item?.type === 1 ? styles.stick_yellow : '',
												item?.type === 2 ? styles.stick_blue : '',
												item?.type === 3 ? styles.stick_green : '',
												item?.type === 4 ? styles.stick_pink : ''
											)
										}
									>
										{item?.description}
									</div>
								))
						}
					</Slider>
			 		:
					<p className={styles.empty}>
						Задачи отсутствуют
					</p>
			}

		</div>
	);
}
