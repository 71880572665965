import React, { useRef, useState } from 'react';
import cn from 'classnames';
import Calendar from 'react-calendar';
import { DateTime } from 'luxon';

import { Icon } from 'app/components/ui/Icon';
import { useOnClickOutside } from 'app/hooks';

import styles from './index.module.sass';

interface Props {
	value: string | undefined;
	onChange: (value: string) => void;
	label?: string;
	placeholder?: string;
	disabled?: boolean;
	hasError?: boolean;
	className?: string;
}

export const DatePicker = (props: Props) => {
	const {
		value,
		onChange = () => {},
		label = '',
		placeholder = '',
		disabled = false,
		hasError = false,
		className = '',
		...restProps
	} = props;

	const calendar = useRef<HTMLButtonElement>(null);

	const [calendarOpen, setCalendarOpen] = useState(false);
	const [calendarPosition, setCalendarPosition] = useState(60);

	useOnClickOutside(calendar, () => {
		setCalendarOpen(false);
	});

	const onChangeCalendarHandle = (date: any) => {
		onChange(DateTime.fromJSDate(date).toISODate());
		setCalendarOpen(false);
	}

	const onClearInput = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		onChange('');
	}

	const onOpenCalendarHandle = () => {
		const distance = calendar.current?.getBoundingClientRect().y;
		if (!distance || calendarOpen) {
			return;
		}

		setCalendarPosition(window.innerHeight - distance < 400 ? -310 : 60);

		setCalendarOpen(true);
	}

	return (
		<button
			{...restProps}
			ref={calendar}
			className={cn(styles.wrapper, hasError && styles.wrapper_error, className)}
			onClick={onOpenCalendarHandle}
			type='button'
		>
			{
				label &&
				<span className={styles.label}>{label}</span>
			}
			<div className={styles.content}>
				<Icon
					icon='datepicker'
					size={24}
					className={styles.prefix}
				/>
				{
					value &&
					<span>{DateTime.fromISO(value).toFormat('dd.MM.yyyy')}</span>
				}
				{
					!value && placeholder &&
					<span className={styles.content__placeholder}>{placeholder}</span>
				}
			</div>
			<button
				disabled={disabled}
				type='button'
				onClick={onClearInput}
				className={styles.clear}
			>
				{
					!!value &&
					<Icon
						icon='clear'
						size={12}
					/>
				}
			</button>
			{
				calendarOpen &&
				<div
					className={styles.calendar}
					style={{ top: `${calendarPosition}px` }}
				>
					<Calendar onChange={(value) => onChangeCalendarHandle(value)} value={value ? new Date(value) : null} />
				</div>
			}
		</button>
	);
}
