import axios from 'axios';
import { QueryFunctionContext } from 'react-query';

import { User } from 'app/models/user.interface';
import { File } from 'app/models/file.interface';
import { NotificationsList } from 'app/models/notifications.interface';
import {
	ArchiveMediaEvent,
	MediaContent
} from 'app/models/media-archive.interface';

export const getUserInfo = async(): Promise<User> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/info/`);
	return response?.data;
}

export const getUserUnreadMessagesCount = async(): Promise<number> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/unread_messages_count/`);
	return response?.data;
}

export const getUsers = async({ queryKey }: QueryFunctionContext): Promise<Array<{ id: number, name: string }>> => {
	const [, search] = queryKey as [string, string];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/`, {
		params: {
			search
		}
	});
	return response?.data;
}

export const getUserDocuments = async({ queryKey }: QueryFunctionContext): Promise<Array<File>> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/${id}/documents/`);
	return response?.data;
}

export const getUserNotifications = async({ queryKey }: QueryFunctionContext): Promise<NotificationsList> => {
	const [, limit, offset, type] = queryKey as [string, number, number, string];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/notifications/`, {
		params: type === '' ? {
			limit,
			offset,
		} : {
			type,
			limit,
			offset,
		}
	});
	return response?.data;
}

export const getMediaArchives = async({ queryKey }: QueryFunctionContext): Promise<Array<ArchiveMediaEvent>> => {
	const [, studying_year] = queryKey as [string, string];

	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/media_archive/`, {
		params: {
			studying_year
		}
	});
	return response?.data;
}

export const getMediaContent = async({ queryKey }: QueryFunctionContext): Promise<MediaContent> => {
	const [, id] = queryKey as [string, number];

	const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/media_archive/${id}/`);
	return response?.data;
}
