import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import {
	createChat,
	getContacts,
} from 'app/services/messengerAPI';
import { Contact } from 'app/models/contact.interface';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

interface Props {
	className?: string;
}

export const Contacts = (props: Props) => {
	const { className = '', ...restProps } = props;

	const navigate = useNavigate();

	const limit = 25;
	const [contacts, setContacts] = useState<Array<Contact>>([]);

	const { data, refetch } = useQuery(['contacts', null, limit], getContacts, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: false,
		cacheTime: 0
	});

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		if (data === undefined) {
			return;
		}

		setContacts(data);
	}, [data]);

	const createChatMutation = useMutation((value: number) => createChat(value));

	const createChatMutationHandle = (id: number) => {
		createChatMutation.mutate(id,{
			onSuccess: (response) => {
				navigate(`chats/${response?.data?.id}`);
			}
		});
	}

	return (
		<section
			{...restProps}
			className={cn(styles.container, className)}
		>
			{
				contacts?.map(item => (
					<button
						key={item?.id}
						onClick={() => createChatMutationHandle(item?.id)}
						className={styles.contact}
					>
						<img
							src={item?.avatar || avatarDefault}
							className={styles.contact__avatar}
							alt="Аватар"
						/>
						<div className={styles.contact__info}>
							<p className={styles.name}>{item?.last_name} {item?.first_name}</p>
							<p className={styles.name}>{item?.info}</p>
						</div>
					</button>
				))
			}
		</section>
	);
}
