import React, { useEffect } from 'react';
import { Form, Formik } from 'formik';
import cn from 'classnames';

import { AlfaSelect } from 'app/components/ui';
import { Class } from 'app/models/class.interface';

import styles from './index.module.sass';

interface Props {
	classes: Class[],
	subjects: Array<{ id: number, full_name: string }>;
	onChange: (values: {
		class_number: number,
		subject: number,
	}) => void;
	className?: string;
}

export const SyllabusFilters = (props: Props) => {
	const { classes, subjects, onChange, className = '', ...restProps } = props;

	useEffect(() => {
		if (!!classes && !!subjects) {
			onChange({ class_number: classes[0]?.id, subject: subjects[0]?.id });
		}
	}, [classes, subjects]);

	return (
		<Formik
			{...restProps}
			enableReinitialize
			initialValues={{
				class_number: classes ? { value: classes[0]?.id, label: classes[0]?.name } : null,
				subject: subjects ? { value: subjects[0]?.id, label: subjects[0]?.full_name } : null
			}}
			onSubmit={() => {}}
		>
			{({ values, setFieldValue }) => (
				<Form className={cn(styles.form, className)}>
					<AlfaSelect<number>
						label='Класс'
						value={values.class_number!}
						options={classes?.map(item => ({ value: item?.id, label: item?.name })) ?? []}
						onChange={option => {
							setFieldValue('class_number', option);
							onChange({ class_number: option.value, subject: values?.subject?.value! });
						}}
						className={styles.select_short}
					/>
					<AlfaSelect<number>
						label='Наименование предмета'
						value={values.subject!}
						options={subjects?.map(item => ({ value: item?.id, label: item?.full_name })) ?? []}
						onChange={option => {
							setFieldValue('subject', option);
							onChange({ class_number: values?.class_number?.value!, subject: option.value });
						}}
						className={styles.select_long}
					/>
				</Form>
			)}
		</Formik>
	);
}
