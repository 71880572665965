import React, { FC } from 'react'
import { Outlet } from 'react-router-dom'
import { ReactComponent as Logo } from 'assets/images/logo.svg'
import styles from './index.module.sass'

export const AuthLayout: FC = () => (
	<div className={styles.wrapper}>
		<div className={styles.leftSide}>
			<Logo className={styles.logoIcon} />
			<div className={styles.form}>
				<Outlet />
			</div>
		</div>
		<div className={styles.rightSide} />
	</div>
);
