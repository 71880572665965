import React from 'react';
import cn from 'classnames';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/hooks/reduxHooks';
import { StorageService } from 'app/services/storageService';
import { resetCurrentUser } from 'app/reducers/userSlice';
import { resetChild } from 'app/reducers/childSlice';
import { resetChildren } from 'app/reducers/childrenSlice';
import { Icon } from 'app/components/ui';
import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

export const Profile = () => {
	const user = useAppSelector((state: any) => state.userSlice);
	const storage = StorageService.getInstance();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const logout = () => {
		dispatch(resetCurrentUser());
		dispatch(resetChild());
		dispatch(resetChildren());
		storage.logout();
		navigate('/login', { replace: true });
	}

	const fullName = `${user.first_name} ${user.last_name} ${user.patronymic ?? ''}`;

	return (
		<section className={styles.wrapper}>
			<aside className={styles.aside}>
				<section className={styles.aside__header}>
					<img src={user.avatar ?? avatarDefault}
						 className={styles.avatar}
						 alt='avatar'
					/>

					<h3 className={styles.full_name}>
						{fullName}
					</h3>
				</section>

				<nav className={styles.navigation}>
					<NavLink
						to='edit'
						className={({ isActive }) => cn(styles.link, { [styles.link_active]: isActive })}
					>
						<Icon
							icon='profile-color'
							className={styles.link__icon}
						/>
						Профиль
					</NavLink>
					<button
						onClick={logout}
						className={styles.link}
					>
						<Icon
							icon='exit'
							className={styles.link__icon}
						/>
						Выход
					</button>
				</nav>
			</aside>
			<Outlet />
		</section>
	);
}
