import React from 'react';
import { useSelector } from 'react-redux';
import { Evaluation } from 'app/components/features';

import styles from './index.module.sass';

export const StudentClassEvaluation = () => {
	const user = useSelector((state: any) => state.userSlice);

	return (
		<section className={styles.content}>
			<Evaluation studentId={user.user_id} />
		</section>
	);
}
