import React, {
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState
} from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import {
	AbsenceMark,
	Grade,
	StudentGrades
} from 'app/models/student-rating.model';
import {
	deleteStudentAbsence,
	deleteStudentGrade,
	getTeacherEvaluation,
	getTeacherSubjectsByClass,
	setStudentAbsence,
	setStudentGrade,
	updateStudentAbsence,
	updateStudentGrade
} from 'app/services/teacherAPI';
import { getCurrentSemester, getSemesters } from 'app/services/schoolAPI';
import { isGrade } from 'app/utils/checkIsGrade';
import { useOnClickOutside } from 'app/hooks';

import avatarDefault from 'assets/images/avatarDefault.png';
import {
	EvaluateType,
	EvaluationFilters,
	EvaluationPropertiesProvider
} from 'app/components/features/TeacherEvaluation/utils';
import EvaluationTag from '../Evaluation/components/EvaluationTag';
import EvaluationCell from './components/EvaluationCell';
import TeacherEvaluationHeader from './components/Header';

import styles from './index.module.sass';

interface Props {
	classId: string;
	className?: string;
}

export const TeacherEvaluation = (props: Props) => {
	const {
		classId,
		className,
		...restProps
	} = props;

	const user = useSelector((state: any) => state.userSlice);

	const [openEvaluateId, setOpenEvaluateId] = useState<string | null>(null);

	const [filters, setFilters] = useState<EvaluationFilters>({
		subject: null,
		currentTime: DateTime.now(),
		semester: null
	});

	const endMonthDay = filters.currentTime.endOf('month').toFormat('d');

	const monthDays = useCallback(
		() => new Array(+endMonthDay).fill(''),
		[filters.currentTime]
	);

	const tooltip = useRef<HTMLButtonElement>(null);

	const { data: semesters } = useQuery('semesters', getSemesters, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { data: curSemester } = useQuery('current_semester', getCurrentSemester, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { data: teacherSubjects } = useQuery(['topics', classId, filters.semester?.id], getTeacherSubjectsByClass, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!classId && !!filters.semester
	});

	const { data: evaluations, refetch } = useQuery(
		['classes', classId, filters.subject?.value, filters.semester?.id],
		getTeacherEvaluation,
		{
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!filters.semester?.id && !!filters.subject?.value
	});

	const [currentEvaluations, setCurrentEvaluations] = useState<StudentGrades[]>([]);

	const setEvaluateMutation = useMutation(
		(value: { student: string, subject: number, grade: Grade }) =>
			setStudentGrade(value.student, value.subject, value.grade)
	);

	const updateEvaluateMutation = useMutation(
		(value: { student: string, subject: number, grade: Grade }) =>
			updateStudentGrade(value.student, value.subject, value.grade)
	);

	const deleteEvaluateMutation = useMutation((id: number) => deleteStudentGrade(id));

	const setAbsenceMutation = useMutation(
		(value: {
			student: string,
			subject: number,
			absenceMark: AbsenceMark
		}) =>
			setStudentAbsence(value.student, value.subject, value.absenceMark)
	);

	const updateAbsenceMutation = useMutation(
		(value: {
			student: string,
			subject: number,
			absenceMark: AbsenceMark
		}) =>
			updateStudentAbsence(value.student, value.subject, value.absenceMark)
	);

	const deleteAbsenceMutation = useMutation((id: number) => deleteStudentAbsence(id));

	useEffect(() => {
		if (!semesters?.length) {
			return;
		}

		const initialSemester = curSemester ?? semesters[0];

		setFilters(prev => ({
			...prev,
			currentTime: initialSemester ? DateTime.fromISO(initialSemester.start_at) : DateTime.now(),
			semester: initialSemester,
		}));
	}, [curSemester, semesters]);

	useEffect(() => {
		if(filters.subject) {
			return;
		}
		const [initialSubject] = teacherSubjects ?? [];
		if (!initialSubject) {
			return;
		}
		const { full_name, id } = initialSubject;
		setFilters(prev => ({
			...prev,
			subject: {
				label: full_name,
				value: id,
			}
		}));
	}, [teacherSubjects]);

	useEffect(() => {
		if(!evaluations) {
			return;
		}
		setCurrentEvaluations(evaluations);
	}, [evaluations]);

	useEffect(() => {
		if(!filters.semester) {
			return;
		}
		const isCurrentSemester = filters.semester?.id === curSemester?.id;

		if(isCurrentSemester) {
			 setFilters(prev => ({
				...prev,
				currentTime: DateTime.now()
			}));
			return;
		}

		setFilters(prev => ({
			...prev,
			currentTime: DateTime.fromISO(filters.semester?.end_at!)
		}));
	}, [filters.semester]);

	useEffect(() => setOpenEvaluateId(null), [filters]);

	useOnClickOutside(tooltip, () => {
		setOpenEvaluateId(null);
	});

	const setEvaluate = (valueInCell: EvaluateType, studentId: string) => {
		if (isGrade(valueInCell)) {
			if(valueInCell.id) {
				return updateEvaluateMutation.mutate({
					student: studentId,
					subject: filters.subject!.value,
					grade: valueInCell,
				}, {
					onSuccess: () => {
						toast.success('Оценка отредактирована');
						refetch();
					},
					onError: () => {
						toast.error('Произошла ошибка');
					}
				});
			}
			return setEvaluateMutation.mutate({
				student: studentId,
				subject: filters.subject!.value,
				grade: valueInCell,
			}, {
				onSuccess: () => {
					toast.success('Оценка добавлена');
					refetch();
				},
				onError: () => {
					toast.error('Произошла ошибка');
				}
			});
		}
		if(valueInCell.id) {
			return updateAbsenceMutation.mutate({
				student: studentId,
				subject: filters.subject!.value,
				absenceMark: valueInCell,
			}, {
				onSuccess: () => {
					toast.success('Пропуск отредактирован');
					refetch();
				},
				onError: () => {
					toast.error('Произошла ошибка');
				}
			});
		}

		return setAbsenceMutation.mutate({
			student: studentId,
			subject: filters.subject!.value,
			absenceMark: valueInCell,
		}, {
			onSuccess: () => {
				toast.success('Пропуск добавлен');
				refetch();
			},
			onError: () => {
				toast.error('Произошла ошибка');
			}
		});
	};

	const deleteEvaluate = (valueInCell: EvaluateType) => {
		if (isGrade(valueInCell)) {
			return deleteEvaluateMutation.mutate(valueInCell.id, {
				onSuccess: () => {
					toast.success('Оценка удалена');
					refetch();
				},
				onError: () => {
					toast.error('Произошла ошибка');
				}
			});
		}
		return deleteAbsenceMutation.mutate(valueInCell.id, {
			onSuccess: () => {
				toast.success('Пропуск удален');
				refetch();
			},
			onError: () => {
				toast.error('Произошла ошибка');
			}
		});
	}

	const collectEvaluations = useCallback((student: StudentGrades) => {
		const res = Array(+endMonthDay).fill(null);
		[...student.grades, ...student.absence_marks]
			.filter(
				({ date }) =>
					DateTime.fromISO(date).month === filters.currentTime.month
			)
			.forEach(grade => {
				const { day } = DateTime.fromISO(grade.date);

				if(!isGrade(grade)) {
					res[day-1] = grade;
					return;
				}

				if(!Array.isArray(res[day-1])) {
					res[day-1] = [grade];
					return;
				}

				res[day-1].push(grade);
			});
		return res;
	}, [currentEvaluations, filters]);

	return (
		<EvaluationPropertiesProvider
			value={{
				classId,
				subjectId: filters.subject?.value,
				tooltipRef: tooltip
			}}
		>
			<TeacherEvaluationHeader
				filter={filters}
				handleFilter={v => setFilters({ ...v })}
				subjects={teacherSubjects ?? []}
				semesters={semesters ?? []}
			/>
			<section {...restProps} className={cn(styles.content, className)}>
				<section className={styles.table}>
					<section className={cn(styles.table__head, styles.columns)}>
						<p className={styles.column__student}>Ученики</p>
						<section className={styles.column__time}>
							<p className={styles.month}>{filters.currentTime.monthLong}</p>
							<div className={styles.days}>
								{
									monthDays().map((_, day) =>
											<p className={styles.day}
											   key={day}>{day + 1}</p>
										)
								}
							</div>
						</section>
						<section className={styles.column__result}>
							<p>Ср.балл</p>
							<p>Оценка</p>
						</section>
					</section>
					<section className={cn(styles.table__body, styles.columns)}>
						{
							currentEvaluations.map(evaluation => {
								const { student } = evaluation;
								return (
									<Fragment key={student.id}>
										<section className={styles.column__student}>
											<img
												className={styles.avatar}
												src={avatarDefault}
												alt='student'
											/>
											<p>{`${student.first_name} ${student.last_name}`}</p>
										</section>
										<section className={styles.row__evaluations}>
											{
												collectEvaluations(evaluation)
													.map((evaluation, index) => {
														const id = `${student.id}${index}`;
														return (
															<EvaluationCell
																values={evaluation}
																openEvaluateId={openEvaluateId || ''}
																handleDetail={v => user?.profile === 0 && setOpenEvaluateId(v)}
																onChangeEvaluate={v => {
																	setOpenEvaluateId(null);
																	return setEvaluate({
																		...v,
																		date: filters.currentTime.set({ day: index + 1 }).toFormat('yyyy-MM-dd')
																	}, student.id)
																}}
																onDeleteEvaluate={v => {
																	setOpenEvaluateId(null);
																	return deleteEvaluate(v);
																}}
																date={filters.currentTime.set({
																	day: index + 1
																})}
																key={id}
																id={id}
															/>
														)
													})
											}
										</section>
										<section
											className={styles.column__result}>
											<EvaluationTag value={evaluation.average_score} />
											<EvaluationTag value={evaluation.final_score} />
										</section>
									</Fragment>
								)
							})
						}
					</section>
				</section>
			</section>
		</EvaluationPropertiesProvider>
	);
}
