import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { toast } from 'react-toastify';

import { Button, Checkbox } from 'app/components/ui';
import { ButtonTheme } from 'app/components/ui/Button';
import {
	finishStudentTasks,
	getStudentTasks,
	getStudentTasksCount
} from 'app/services/studentAPI';
import { numberEndings } from 'app/utils/numberEndings';
import { setTasksCount } from 'app/reducers/tasksCountSlice';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

export const MyStudentTasks = () => {
	const dispatch = useDispatch();

	const user = useSelector((state: any) => state.userSlice);

	const [showAllHomeWorkTasks, setShowAllHomeWorkTasks] = useState(false);
	const [showPersonalWorkTasks, setShowPersonalWorkTasks] = useState(false);
	const [showControlWorkTasks, setShowControlWorkTasks] = useState(false);
	const [showPersonalStudyingTasks, setShowPersonalStudyingTasks] = useState(false);
	const [showOtherTasks, setShowOtherTasks] = useState(false);
	const [selectedTasks, setSelectedTasks] = useState<Array<number>>([]);

	const { data: tasks, refetch } = useQuery(['student-tasks'], getStudentTasks, {
		retry: 0,
		refetchOnWindowFocus: false,
		cacheTime: 0
	});

	const { data: tasks_count, refetch: refetchTasksCount } = useQuery(['tasks_count', user?.user_id], getStudentTasksCount, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});

	useEffect(() => {
		if (tasks_count?.toString()) {
			dispatch(setTasksCount(tasks_count));
		}
	}, [tasks_count]);

	const finishTasksMutation = useMutation((selectedTasks: number[]) => finishStudentTasks(selectedTasks));

	const onTasksSelectionChange = (checked: boolean, id: number) => {
		if (checked) {
			setSelectedTasks([...selectedTasks, id]);
		} else {
			setSelectedTasks(selectedTasks.filter(item => item !== id));
		}
	}

	const skipSelectedTasks = () => {
		setSelectedTasks([]);
	}

	const finishSelectedTasks = () => {
		if (selectedTasks?.length > 0) {
			finishTasksMutation.mutate(
				selectedTasks,
				{
					onSuccess: () => {
						toast.success('Выбранные задачи завершены');
						setSelectedTasks([]);
						refetch();
						refetchTasksCount();
					},
					onError: () => {
						toast.error('Не удалось завершить выбранные задачи');
					}
				}
			);
		}
	}

	return (
		<section className={styles.content}>
			{
				selectedTasks.length > 0 &&
				<section
					className={styles.tooltip}
				>
					<div className={styles.tooltip__caption}>
						<Button
							onClick={skipSelectedTasks}
							theme={ButtonTheme.secondary}
							className={styles.button_clear}
						>
							Снять выделение
						</Button>
						<p>
							Выбрано {selectedTasks.length}
							{numberEndings(selectedTasks.length, [' задача', ' задачи', ' задач'])}
						</p>
					</div>
					<Button
						onClick={finishSelectedTasks}
						theme={ButtonTheme.secondary}
						className={styles.button_finish}
					>
						Завершить задачи
					</Button>
				</section>
			}
			<h2>Задачи</h2>
			<div className={styles.block}>
				<div className={styles.block__header}>
					<section className={styles.caption}>
						<h4>Домашнее задание</h4>
						{
							tasks && tasks['0']?.length > 0 &&
							<p className={styles.count}>
								{tasks && tasks['0']?.length}
							</p>
						}
					</section>
				</div>
				{
					!tasks || tasks['0']?.length === 0 &&
					<p className={styles.block__empty_message}>
						Пока нет ни одной задачи
					</p>
				}
				{
					tasks && tasks['0']?.length > 0 &&
					<section className={styles.tasks}>
						{
							tasks['0']?.slice(0,showAllHomeWorkTasks ? tasks['0']?.length : 3)?.map((task: any) => (
								<div
									key={task?.id}
									className={cn(styles.tasks__item, styles.tasks__item_home_work)}
								>
									<p className={styles.tasks__item__caption}>
										{
											task?.topic && <>{task?.topic}: <br /></>
										}
										{task?.description}
									</p>
									<section>
										<div className={styles.tasks__item__info}>
											{
												task?.subject?.full_name &&
												<p className={styles.tasks__item__info__item}>
													{task?.subject?.full_name}
												</p>
											}
											<p className={styles.tasks__item__info__item}>
												{DateTime.fromISO(task?.deadline).toFormat('dd.MM')}
											</p>
										</div>
										<div className={styles.tasks__item__controls}>
											<div className={styles.author}>
												<img
													className={styles.avatar}
													src={task?.author?.avatar ? `${process.env.REACT_APP_BACK_URL}${task?.author?.avatar}` : avatarDefault}
													alt='author'
												/>
												{task?.author?.first_name} {task?.author?.patronymic}
											</div>
											<Checkbox
												value={selectedTasks.includes(task?.id)}
												onChange={event => onTasksSelectionChange(event.target.checked, task?.id)}
											/>
										</div>
									</section>
								</div>
							))
						}
					</section>
				}
				{
					tasks && tasks['0']?.length > 3 &&
					<Button
						theme={ButtonTheme.secondary}
						stretch
						onClick={() => setShowAllHomeWorkTasks(!showAllHomeWorkTasks)}
						className={styles.more}
					>
						{showAllHomeWorkTasks ? 'Свернуть' : 'Показать еще'}
					</Button>
				}
			</div>
			<div className={styles.block}>
				<div className={styles.block__header}>
					<section className={styles.caption}>
						<h4>Самостоятельная работа</h4>
						{
							tasks && tasks['1']?.length > 0 &&
							<p className={styles.count}>
								{tasks && tasks['1']?.length}
							</p>
						}
					</section>
				</div>
				{
					!tasks || tasks['1']?.length === 0 &&
					<p className={styles.block__empty_message}>
						Пока нет ни одной задачи
					</p>
				}
				{
					tasks && tasks['1']?.length > 0 &&
					<section className={styles.tasks}>
						{
							tasks['1']?.slice(0,showPersonalWorkTasks ? tasks['1']?.length : 3)?.map((task: any) => (
								<div
									key={task?.id}
									className={cn(styles.tasks__item, styles.tasks__item_personal_work)}
								>
									<p className={styles.tasks__item__caption}>
										{
											task?.topic && <>{task?.topic}: <br /></>
										}
										{task?.description}
									</p>
									<section>
										<div className={styles.tasks__item__info}>
											{
												task?.subject?.full_name &&
												<p className={styles.tasks__item__info__item}>
													{task?.subject?.full_name}
												</p>
											}
											<p className={styles.tasks__item__info__item}>
												{DateTime.fromISO(task?.deadline).toFormat('dd.MM')}
											</p>
										</div>
										<div className={styles.tasks__item__controls}>
											<div className={styles.author}>
												<img
													className={styles.avatar}
													src={task?.author?.avatar ? `${process.env.REACT_APP_BACK_URL}${task?.author?.avatar}` : avatarDefault}
													alt='author'
												/>
												{task?.author?.first_name} {task?.author?.patronymic}
											</div>
											<Checkbox
												value={selectedTasks.includes(task?.id)}
												onChange={event => onTasksSelectionChange(event.target.checked, task?.id)}
											/>
										</div>
									</section>
								</div>
							))
						}
					</section>
				}
				{
					tasks && tasks['1']?.length > 3 &&
					<Button
						theme={ButtonTheme.secondary}
						stretch
						onClick={() => setShowPersonalWorkTasks(!showPersonalWorkTasks)}
						className={styles.more}
					>
						{showPersonalWorkTasks ? 'Свернуть' : 'Показать еще'}
					</Button>
				}
			</div>
			<div className={styles.block}>
				<div className={styles.block__header}>
					<section className={styles.caption}>
						<h4>Контрольная работа</h4>
						{
							tasks && tasks['2']?.length > 0 &&
							<p className={styles.count}>
								{tasks && tasks['2']?.length}
							</p>
						}
					</section>
				</div>
				{
					!tasks || tasks['2']?.length === 0 &&
					<p className={styles.block__empty_message}>
						Пока нет ни одной задачи
					</p>
				}
				{
					tasks && tasks['2']?.length > 0 &&
					<section className={styles.tasks}>
						{
							tasks['2']?.slice(0,showControlWorkTasks ? tasks['2']?.length : 3)?.map((task: any) => (
								<div
									key={task?.id}
									className={cn(styles.tasks__item, styles.tasks__item_control_work)}
								>
									<p className={styles.tasks__item__caption}>
										{
											task?.topic && <>{task?.topic}: <br /></>
										}
										{task?.description}
									</p>
									<section>
										<div className={styles.tasks__item__info}>
											{
												task?.subject?.full_name &&
												<p className={styles.tasks__item__info__item}>
													{task?.subject?.full_name}
												</p>
											}
											<p className={styles.tasks__item__info__item}>
												{DateTime.fromISO(task?.deadline).toFormat('dd.MM')}
											</p>
										</div>
										<div className={styles.tasks__item__controls}>
											<div className={styles.author}>
												<img
													className={styles.avatar}
													src={task?.author?.avatar ? `${process.env.REACT_APP_BACK_URL}${task?.author?.avatar}` : avatarDefault}
													alt='author'
												/>
												{task?.author?.first_name} {task?.author?.patronymic}
											</div>
											<Checkbox
												value={selectedTasks.includes(task?.id)}
												onChange={event => onTasksSelectionChange(event.target.checked, task?.id)}
											/>
										</div>
									</section>
								</div>
							))
						}
					</section>
				}
				{
					tasks && tasks['2']?.length > 3 &&
					<Button
						theme={ButtonTheme.secondary}
						stretch
						onClick={() => setShowControlWorkTasks(!showControlWorkTasks)}
						className={styles.more}
					>
						{showControlWorkTasks ? 'Свернуть' : 'Показать еще'}
					</Button>
				}
			</div>
			<div className={styles.block}>
				<div className={styles.block__header}>
					<section className={styles.caption}>
						<h4>Самостоятельное изучение</h4>
						{
							tasks && tasks['3']?.length > 0 &&
							<p className={styles.count}>
								{tasks && tasks['3']?.length}
							</p>
						}
					</section>
				</div>
				{
					!tasks || tasks['3']?.length === 0 &&
					<p className={styles.block__empty_message}>
						Пока нет ни одной задачи
					</p>
				}
				{
					tasks && tasks['3']?.length > 0 &&
					<section className={styles.tasks}>
						{
							tasks['3']?.slice(0,showPersonalStudyingTasks ? tasks['3']?.length : 3)?.map((task: any) => (
								<div
									key={task?.id}
									className={cn(styles.tasks__item, styles.tasks__item_personal_studying)}
								>
									<p className={styles.tasks__item__caption}>
										{
											task?.topic && <>{task?.topic}: <br /></>
										}
										{task?.description}
									</p>
									<section>
										<div className={styles.tasks__item__info}>
											{
												task?.subject?.full_name &&
												<p className={styles.tasks__item__info__item}>
													{task?.subject?.full_name}
												</p>
											}
											<p className={styles.tasks__item__info__item}>
												{DateTime.fromISO(task?.deadline).toFormat('dd.MM')}
											</p>
										</div>
										<div className={styles.tasks__item__controls}>
											<div className={styles.author}>
												<img
													className={styles.avatar}
													src={task?.author?.avatar ? `${process.env.REACT_APP_BACK_URL}${task?.author?.avatar}` : avatarDefault}
													alt='author'
												/>
												{task?.author?.first_name} {task?.author?.patronymic}
											</div>
											<Checkbox
												value={selectedTasks.includes(task?.id)}
												onChange={event => onTasksSelectionChange(event.target.checked, task?.id)}
											/>
										</div>
									</section>
								</div>
							))
						}
					</section>
				}
				{
					tasks && tasks['3']?.length > 3 &&
					<Button
						theme={ButtonTheme.secondary}
						stretch
						onClick={() => setShowPersonalStudyingTasks(!showPersonalStudyingTasks)}
						className={styles.more}
					>
						{showPersonalStudyingTasks ? 'Свернуть' : 'Показать еще'}
					</Button>
				}
			</div>
			<div className={styles.block}>
				<div className={styles.block__header}>
					<section className={styles.caption}>
						<h4>Другое</h4>
						{
							tasks && tasks['4']?.length > 0 &&
							<p className={styles.count}>
								{tasks && tasks['4']?.length}
							</p>
						}
					</section>
				</div>
				{
					!tasks || tasks['4']?.length === 0 &&
					<p className={styles.block__empty_message}>
						Пока нет ни одной задачи
					</p>
				}
				{
					tasks && tasks['4']?.length > 0 &&
					<section className={styles.tasks}>
						{
							tasks['4']?.slice(0,showOtherTasks ? tasks['4']?.length : 3)?.map((task: any) => (
								<div
									key={task?.id}
									className={cn(styles.tasks__item, styles.tasks__item_other)}
								>
									<p className={styles.tasks__item__caption}>
										{
											task?.topic && <>{task?.topic}: <br /></>
										}
										{task?.description}
									</p>
									<section>
										<div className={styles.tasks__item__info}>
											{
												task?.subject?.full_name &&
												<p className={styles.tasks__item__info__item}>
													{task?.subject?.full_name}
												</p>
											}
											<p className={styles.tasks__item__info__item}>
												{DateTime.fromISO(task?.deadline).toFormat('dd.MM')}
											</p>
										</div>
										<div className={styles.tasks__item__controls}>
											<div className={styles.author}>
												<img
													className={styles.avatar}
													src={task?.author?.avatar ? `${process.env.REACT_APP_BACK_URL}${task?.author?.avatar}` : avatarDefault}
													alt='author'
												/>
												{task?.author?.first_name} {task?.author?.patronymic}
											</div>
											<Checkbox
												value={selectedTasks.includes(task?.id)}
												onChange={event => onTasksSelectionChange(event.target.checked, task?.id)}
											/>
										</div>
									</section>
								</div>
							))
						}
					</section>
				}
				{
					tasks && tasks['4']?.length > 3 &&
					<Button
						theme={ButtonTheme.secondary}
						stretch
						onClick={() => setShowOtherTasks(!showOtherTasks)}
						className={styles.more}
					>
						{showOtherTasks ? 'Свернуть' : 'Показать еще'}
					</Button>
				}
			</div>
		</section>
	);
}
