import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Settings } from 'luxon';
import { ToastContainer } from 'react-toastify';

import store from 'app/store';
import 'app/services/interceptors';
import App from './App';
import './index.sass';

Settings.defaultLocale = 'ru';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<BrowserRouter>
				<App />

				<ToastContainer hideProgressBar />
			</BrowserRouter>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
