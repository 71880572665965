import React, { ChangeEvent, useState } from 'react';
import { Form, Formik } from 'formik';
import cn from 'classnames';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import {
	Button,
	DatePicker,
	Textarea,
	AlfaAutocomplete,
	Icon
} from 'app/components/ui';
import { ButtonTheme } from 'app/components/ui/Button';
import { getUsers } from 'app/services/userAPI';
import {
	addTeacherTask,
	uploadTaskFile
} from 'app/services/teacherAPI';

import styles from './index.module.sass';

const TaskAddingFormSchema = Yup.object().shape({
	assigned_to: Yup.number()
		.required('Заполните поле'),
	description: Yup.string()
		.required('Заполните поле'),
	deadline: Yup.string()
		.required('Заполните поле'),
});

interface Props {
	onCancel: (withReload?: boolean) => void;
	className?: string;
}

export const TaskAddingForm = (props: Props) => {
	const { onCancel, className = '', ...restProps } = props;

	const [search, setSearch] = useState('');

	const { data: teachers } = useQuery(['teachers', search], getUsers, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!search
	});

	const addMutation = useMutation((data: {
		assigned_to: number,
		description: string,
		deadline: string,
		files: number[]
	}) => addTeacherTask({ ...data, assigned_to: [data.assigned_to] }));

	const uploadFileMutation = useMutation((file: File) => uploadTaskFile(file));

	const handleAddFile = (
		e: ChangeEvent<HTMLInputElement>,
		formFiles: Array<{ id: number, file: File }>,
		setFieldValue: Function
	) => {
		const { files } = e.target;
		if (!files) {
			return null;
		}
		return uploadFileMutation.mutate(files[0], {
			onSuccess: res => {
				setFieldValue('files', [
					...formFiles,
					{
						id: res.data?.id,
						file: files[0]?.name
					}
				]);

				e.target.value = '';
			},
		});
	};

	const removeFile = (
		removeIndex: number,
		formFiles: Array<{ id: number, file: File }>,
		setFieldValue: Function
	) => {
		setFieldValue(
			'files',
			formFiles?.filter((item, index) => index !== removeIndex)
		);
	}

	return (
		<>
			<h3>Новая задача</h3>
			<Formik
				enableReinitialize
				validationSchema={TaskAddingFormSchema}
				initialValues={{
					description: '',
					assigned_to: null as any,
					deadline: '',
					files: []
				}}
				onSubmit={values => {
					addMutation.mutate({
						...values,
						files: values.files?.map((file: { id: number, file: string }) => file?.id)
						},
						{
							onSuccess: () => {
								toast.success('Задача успешно создана');
								onCancel(true);
							},
							onError: () =>{
								toast.error('Не удалось создать задачу');
							}
						}
					);
				}}
			>
				{({ values, setFieldValue }) => (
					<Form
						{...restProps}
						className={cn(styles.form, className)}
					>
						<section>
							<Textarea
								value={values?.description}
								onChange={e => setFieldValue('description', (e.target as HTMLTextAreaElement).value)}
								label='Задача'
								className={styles.control}
							/>
							<AlfaAutocomplete<number>
								options={teachers?.map(item => ({ value: item?.id, label: item?.name })) ?? []}
								onChange={option => setFieldValue('assigned_to', option.value)}
								onSearch={value => setSearch(value)}
								label='Исполнитель'
								className={cn(styles.control, styles.control_stretch)}
							/>
							<DatePicker
								value={values?.deadline}
								onChange={value => setFieldValue('deadline', value)}
								label='Дата'
								placeholder='Выберите дату'
								className={cn(styles.control, styles.control_datepicker)}
							/>
							<div>
								{
									values?.files?.map((item: { id: number, file: string }, index) => (
										<div key={Math.random()} className={styles.file}>
											<p>
												{item?.file}
											</p>
											<button
												type='button'
												onClick={() => removeFile(index, values.files, setFieldValue)}
												className={styles.button_delete}
											>
												<Icon icon='trash' />
											</button>
										</div>
									))
								}
								<label
									htmlFor='fileInput'
									className={styles.upload}
								>
									<Icon icon='clip' size={12}/>
									Добавить файл
									<input
										id='fileInput'
										type='file'
										onChange={e =>
											handleAddFile(e, values.files, setFieldValue)}
									/>
								</label>
							</div>
						</section>
						<footer className={styles.form__footer}>
							<section className={styles.form__footer__controls}>
								<Button
									onClick={onCancel}
									theme={ButtonTheme.ghost}
								>
									Отменить
								</Button>
								<Button type='submit'>
									Создать задачу
								</Button>
							</section>
						</footer>
					</Form>
				)}
			</Formik>
		</>
	);
}
