import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ClassesList } from 'app/pages/teacher/Classes/components/ClassesList';
import { getArchiveYearClasses } from 'app/services/teacherAPI';

import styles from './index.module.sass';

export const TeacherClassesArchive = () => {
	const location = useLocation();

	const [yearId, setYearId] = useState<number>();

	const { data } = useQuery(['classes', yearId], getArchiveYearClasses, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!yearId
	});

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (params.get('yearId')) {
			setYearId(+params.get('yearId')!);
		}
	}, [location]);

	return (
		<section className={styles.content}>
			<ClassesList classes={data} />
		</section>
	);
}
