import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { Schedule, Birthdays } from 'app/components/features';
import {
	getStudentBirthdays,
	getTeacherSchedule
} from 'app/services/teacherAPI';
import { EventType } from 'app/models/enums/event-type.enum';
import { TeacherEvents } from 'app/pages/teacher/Teacher/components/Events';

import styles from './index.module.sass';
import { TeacherDailyTasks } from './components/TeacherDailyTasks';

export const Teacher = () => {
	const [date, setDate] = useState<string>();
	const [eventType, setEventType] = useState<EventType>(EventType.Lesson);

	const user = useSelector((state: any) => state.userSlice);

	const { data: events } = useQuery(['events', date, eventType, user?.school_class?.id], getTeacherSchedule, {
		retry: 0,
		refetchOnWindowFocus: false,
		cacheTime: 0,
		enabled: !!date && !!eventType.toString()
	});

	const { data: birthdays } = useQuery('birthdays', getStudentBirthdays, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const onDateChange = (date: string) => {
		setDate(date);
	}

	const onEventTypeChange = (type: EventType) => {
		setEventType(type);
	}

	return (
		<section className={styles.wrapper}>
			<aside>
				<TeacherDailyTasks className={styles.tasks} />
				<Birthdays birthdays={birthdays!} className={styles.birthdays} />
				<TeacherEvents />
			</aside>
			<section className={styles.content}>
				<Schedule
					data={events}
					onDateChange={onDateChange}
					onEventTypeChange={onEventTypeChange}
				/>
			</section>
		</section>
	);
}
