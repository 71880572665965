import React, { useState } from 'react';
import cn from 'classnames';
import { Button } from 'app/components/ui';
import { ButtonTheme } from 'app/components/ui/Button';
import { Score } from 'app/models/score.interface';

import styles from './index.module.sass';


interface Props {
	scores: Score[];
	className?: string;
}

export const StudentScores = (props: Props) => {
	const { scores, className, ...restProps } = props;

	const [showAll, setShowAll] =useState(false);

	return (
		<div
			{...restProps}
			className={cn(styles.container, className, {[styles.container_empty]: scores?.length === 0})}
		>
			{
				scores?.length > 0 ? (
					<>
						{
							scores
								?.filter(item => item.average_score > 0)
								?.slice(0, showAll ? scores?.filter(item => item.average_score > 0)?.length : 5)
								?.map((item) => (
									<div
										key={Math.random()}
										className={styles.progress_container}
									>
										<span
											className={styles.progress}
											style={{
												width: `calc(${item.average_score / 5 * 100}% - 60px)`,
												background: item?.color
											}}
										/>
										<div className={styles.content}>
											<span className={styles.subject}>{item?.name}</span>
											<span>{item?.average_score}</span>
										</div>
								</div>
							))
						}
						{
							scores?.filter(item => item.average_score > 0)?.length > 5 &&
							<div className={styles.controls}>
								<Button
									onClick={() => setShowAll(!showAll)}
									theme={ButtonTheme.clear}
								>
									{showAll ? 'Свернуть' : 'Показать еще'}
								</Button>
							</div>
						}
					</>
				) : (
					<p className={styles.empty}>
						Баллы отсутствуют
					</p>
				)
			}

		</div>
	);
}
