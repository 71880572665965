import React, { useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';

import { Button, Tag } from 'app/components/ui';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { FullSizeFridgeContainer } from 'app/components/modals';
import { getMediaArchives } from 'app/services/userAPI';

import avatarDefault from 'assets/images/avatarDefault.png';

import { AlbomAddingForm } from './components/AlbomAddingForm';

import styles from './index.module.sass';

export const TeacherMediaArchive = () => {
	const [params] = useSearchParams();

	const [isAlbomAddingOpen, setIsAlbomAddingOpen] = useState(false);

	const { data, isLoading, refetch } = useQuery(['albums', params.get('yearId')], getMediaArchives, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const onOpenAlbomAddingHandle = () => setIsAlbomAddingOpen(true);

	const onCloseAlbomAddingHandle = (withReload = false) => {
		if (typeof withReload === 'boolean' && withReload) {
			refetch();
		}

		setIsAlbomAddingOpen(false);
	}

	return (
		<section className={styles.wrapper}>
			<section className={styles.header}>
				<Button
					onClick={onOpenAlbomAddingHandle}
					theme={ButtonTheme.ghost}
					size={ButtonSize.s}
					leftIcon='photo'
				>
					Добавить альбом
				</Button>
			</section>
			<section className={styles.container}>
				{
					data?.map(item =>
						<NavLink
							key={item?.id}
							to={params.get('yearId') ? `${item?.id}?yearId=${params.get('yearId')}` : item?.id.toString()}
							className={styles.card}
						>
							<img
								src={item?.preview?.file ?? avatarDefault}
								alt='poster'
								className={styles.cover}
							/>
							<h2 className={styles.title}>{item?.school_class?.name}</h2>
							<Tag>{DateTime.fromISO(item?.date).toFormat('d MMMM yyyy')}</Tag>
							<span className={styles.text}>{item?.description}</span>
						</NavLink>
					)
				}
				{
					!isLoading && data?.length === 0 &&
					<div className={styles.container_empty}>
						<p>Архив отсутствует</p>
					</div>
				}
			</section>
			<FullSizeFridgeContainer
				isOpen={isAlbomAddingOpen}
				onClose={onCloseAlbomAddingHandle}
			>
				<AlbomAddingForm
					onCancel={onCloseAlbomAddingHandle}
				/>
			</FullSizeFridgeContainer>
		</section>
	);
}
