import React, { useEffect, useState } from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import cn from 'classnames';

import { Checkbox, Icon } from 'app/components/ui';

import styles from './index.module.sass';
import { TeacherTasksList } from './components/TasksList';

export const TeacherTasks = () => {
	const [params, setSearchParams] = useSearchParams();

	const [authored, setAuthored] = useState<boolean>(params.get('authored') === '1');

	const onAuthoredToggle = () => {
		setAuthored(!authored);
	}

	useEffect(() => {
		const type = params.get('type') ? { 'type': params.get('type') } : {};
		const authoredQuery = authored ? { 'authored': '1' } : {};

		// @ts-ignore
		setSearchParams({ ...type, ...authoredQuery });

	}, [authored]);

	return (
		<section className={styles.wrapper}>
			<aside className={styles.navigation}>
				<nav>
					<ul>
						<li>
							<NavLink
								to={authored ? `?authored=1` : ''}
								className={cn(styles.link, {[styles.link_active]: !params.get('type')})}
							>
								<Icon
									icon='evaluation-color'
									size={24}
									className={styles.link__icon}
								/>
								Все
							</NavLink>
						</li>
						<li>
							<NavLink
								to={authored ? `?type=active&authored=1` : '?type=active'}
								className={cn(styles.link, {[styles.link_active]: params.get('type') === 'active'})}
							>
								<Icon
									icon='active-tasks'
									size={24}
									className={styles.link__icon}
								/>
								Активные
							</NavLink>
						</li>
						<li>
							<NavLink
								to={authored ? `?type=finished&authored=1` : '?type=finished'}
								className={cn(styles.link, {[styles.link_active]: params.get('type') === 'finished'})}
							>
								<Icon
									icon='tasks-color'
									size={24}
									className={styles.link__icon}
								/>
								Завершенные
							</NavLink>
						</li>
						<div className={styles.options}>
							<h3>Показать</h3>
							<Checkbox
								value={authored}
								type='circle'
								onChange={onAuthoredToggle}
								className={styles.options__item}
							>
								Созданные мной
							</Checkbox>
						</div>
					</ul>
				</nav>
			</aside>
			<TeacherTasksList />
		</section>
	);
}
