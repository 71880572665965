import axios, { AxiosResponse } from 'axios';
import { QueryFunctionContext } from 'react-query';

import { Student } from 'app/models/student.interface';
import { Class } from 'app/models/class.interface';
import { Parent } from 'app/models/parent.interface';
import { EventType } from 'app/models/enums/event-type.enum';
import { ScheduleEventListItem } from 'app/models/schedule-event.interface';
import { Event } from 'app/models/event.interface';
import {
	AbsenceMark,
	Grade,
	StudentGrades
} from 'app/models/student-rating.model';
import { MedicalCertificate } from 'app/models/medical-certificate.intetface';
import { Lesson } from 'app/models/lesson.interface';
import { Syllabus } from 'app/models/syllabus.interface';
import { TeacherTask, TeacherTasksList } from 'app/models/teacher-task.interface';
import { EmployeesList } from 'app/models/teacher.interface';
import { Teacher } from 'app/models/profile.interface';

export const getStudentBirthdays = async(): Promise<Array<Student>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/birthday/`);
	return response?.data;
}

export const getCurrentYearClasses = async(): Promise<Array<Class>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/classes/`);
	return response?.data;
}

export const getArchiveYearClasses = async({ queryKey }: QueryFunctionContext): Promise<Array<Class>> => {
	const [, yearId] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/classes/`, {
		params: {
			studying_year: yearId
		}
	});
	return response?.data;
}

export const getClassStudents = async({ queryKey }: QueryFunctionContext): Promise<Array<Student>> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/classes/${id}/students/`);
	return response?.data;
}

export const getClassParents = async({ queryKey }: QueryFunctionContext): Promise<Array<Parent>> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/classes/${id}/parents/`);
	return response?.data;
}

export const getTeacherSchedule = async({ queryKey }: QueryFunctionContext): Promise<Array<ScheduleEventListItem>> => {
	const [, date, eventType, currentClassId] = queryKey as [string, number, EventType, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/schedule/`, {
		params: {
			date,
			type: eventType,
			class_id: currentClassId
		}
	});
	return response?.data;
}

export const getClassSchedule = async({ queryKey }: QueryFunctionContext): Promise<Array<ScheduleEventListItem>> => {
	const [, id, date, eventType, currentClassId] = queryKey as [number, string, number, EventType, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/classes/${id}/schedule/`, {
		params: {
			date,
			type: eventType,
			class_id: currentClassId
		}
	});
	return response?.data;
}

export const getTeacherEvents = async({ queryKey }: QueryFunctionContext): Promise<Array<Event>> => {
	const [, date] = queryKey as [string, string];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/events/`, {
		params: {
			date
		}
	});
	return response?.data;
}

export const getTeacherEvaluation = async({ queryKey }: QueryFunctionContext): Promise<Array<StudentGrades>> => {
	const [, classId, subject_id, semester_id] = queryKey as [string, string, string, string];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/classes/${classId}/grades/`, {
		params: {
			subject_id,
			semester_id
		}
	});
	return response?.data;
}

export const getTeacherMedicalCertificates = async ({ queryKey }: QueryFunctionContext): Promise<Array<MedicalCertificate>> => {
	const [, classId] = queryKey as [string, string];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/classes/${classId}/medical_certificates/`);

	return response?.data;
}

export const setStudentGrade = (student: string, subject: number, grade: Grade): Promise<AxiosResponse> =>
	axios.post(`${process.env.REACT_APP_API_URL}/teachers/grades/`, {
			student,
			subject,
			...grade,
		}
	);

export const updateStudentGrade = (student: string, subject: number, grade: Grade): Promise<AxiosResponse> =>
	axios.patch(`${process.env.REACT_APP_API_URL}/teachers/grades/${grade.id}/`, {
			student,
			subject,
			...grade,
		}
	);

export const deleteStudentGrade = (id: number): Promise<AxiosResponse> =>
	axios.delete(`${process.env.REACT_APP_API_URL}/teachers/grades/${id}/`, { params: { id } });

export const setStudentAbsence = (student: string, subject: number, absenceMark: AbsenceMark): Promise<AxiosResponse> =>
	axios.post(`${process.env.REACT_APP_API_URL}/teachers/absence_marks/`, {
			student,
			subject,
			...absenceMark,
		}
	);

export const updateStudentAbsence = (student: string, subject: number, absenceMark: AbsenceMark): Promise<AxiosResponse> =>
	axios.patch(`${process.env.REACT_APP_API_URL}/teachers/absence_marks/${absenceMark.id}/`, {
			student,
			subject,
			...absenceMark,
		}
	);

export const deleteStudentAbsence = (id: number): Promise<AxiosResponse> =>
	axios.delete(`${process.env.REACT_APP_API_URL}/teachers/absence_marks/${id}/`, { params: { id } });

export const getClassInfo = async({ queryKey }: QueryFunctionContext): Promise<{ id: number, name: string }> => {
	const [, classId] = queryKey as [string, string];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/classes/${classId}/`);
	return response?.data;
}

export const postMedicalCertificate = (data: {
	student: number,
	start_at: string,
	end_at: string,
	file: string
}) => axios.post(`${process.env.REACT_APP_API_URL}/teachers/classes/medical_certificates/`, {
			...data
		}
	);

export const uploadMedicalCertificateFile = (file: File): Promise<AxiosResponse> => {
	const formData = new FormData();
	formData.append('file', file);

	return axios.post(`${process.env.REACT_APP_API_URL}/teachers/medical_certificate_files/`, formData, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
	);
}

export const getUpcomingLessons = async(): Promise<Array<Lesson>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/lessons/nearest/`);
	return response?.data;
}

export const getTeacherSubjects = async(): Promise<Array<{ id: number, full_name: string }>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/subjects/`);
	return response?.data;
}

export const getTeacherSubjectsByClass = async({ queryKey }: QueryFunctionContext): Promise<Array<{ id: number, full_name: string }>> => {
	const [, classId, semester_id] = queryKey as [string, string, string];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/classes/${ classId }/subjects/`, {
		params: {
			semester_id
		}
	});
	return response?.data;
}

export const getSyllabus = async({ queryKey }: QueryFunctionContext): Promise<Syllabus> => {
	const [, class_id, subject] = queryKey as [string, number, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/studying_plans/`, {
		params: {
			class: class_id,
			subject
		}
	});
	return response?.data;
}

export const getArchiveSyllabus = async({ queryKey }: QueryFunctionContext): Promise<Syllabus> => {
	const [, studying_year, class_id, subject] = queryKey as [string, number, number, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/studying_plans/`, {
		params: {
			studying_year,
			class: class_id,
			subject
		}
	});
	return response?.data;
}

export const addSyllabus = (data: Syllabus) =>
	axios.post(`${process.env.REACT_APP_API_URL}/teachers/studying_plans/`, {
		...data
	}
);

export const editSyllabus = (id: number, data: Syllabus) =>
	axios.put(`${process.env.REACT_APP_API_URL}/teachers/studying_plans/${id}/`, {
		...data
	}
);

export const getStudyingPlanTopics = async({ queryKey }: QueryFunctionContext): Promise<Array<string>> => {
	const [, class_id, subject] = queryKey as [string, number, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/studying_plans/topics/`, {
		params: {
			class: class_id,
			subject
		}
	});
	return response?.data;
}

export const addStudentTask = (data: {
	assigned_to: number,
	subject: number,
	topic: string,
	type: number,
	description: string,
	deadline: string
}) =>
	axios.post(`${process.env.REACT_APP_API_URL}/teachers/student_tasks/`, {
		...data
	}
);

export const addTeacherTask = (data: {
	assigned_to: number[],
	description: string,
	deadline: string,
	files: number[]
}) =>
	axios.post(`${process.env.REACT_APP_API_URL}/teachers/tasks/`, {
		...data
	}
);

export const getTeacherTasks = async({ queryKey }: QueryFunctionContext): Promise<TeacherTasksList> => {
	const [, limit, offset, type, authored] = queryKey as [string, number, number, string, string];

	let currentType = null;
	if (type === 'active') {
		currentType = 0;
	}

	if (type === 'finished') {
		currentType = 2;
	}

	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/tasks/`, {
		params: {
			limit,
			offset,
			status: currentType,
			authored: !!authored
		}
	});
	return response?.data;
}

export const finishTeacherTasks = (data: number[]) =>
	axios.patch(`${process.env.REACT_APP_API_URL}/teachers/tasks/finish/`, {
			task_ids: data
		}
	);

export const getStudentTasks = async({ queryKey }: QueryFunctionContext): Promise<Array<any>> => {
	const [, id] = queryKey as [string, number, number, string, string];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/${id}/tasks/`);
	return response?.data;
}

export const getDailyTeacherTasks = async({ queryKey }: QueryFunctionContext): Promise<Array<TeacherTask>> => {
	const [, deadline] = queryKey as [string, string];

	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/tasks/`, {
		params: {
			status: 0,
			deadline
		}
	});
	return response?.data;
}

export const getTeachers = async({ queryKey }: QueryFunctionContext): Promise<EmployeesList> => {
	const [, limit, offset, profile] = queryKey as [string, number, number, number];

	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/`, {
		params: {
			limit,
			offset,
			profile
		}
	});
	return response?.data;
}

export const getTeacherDetail = async({ queryKey }: QueryFunctionContext): Promise<Teacher> => {
	const [, id] = queryKey as [string, string];

	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/${id}/`);
	return response?.data;
}

export const uploadTeacherDocument = (data: { file: File, type: 0 | 1 }): Promise<AxiosResponse> => {
	const formData = new FormData();
	formData.append('file', data.file);
	formData.append('presentation_name', data.file.name);
	formData.append('type', data.type.toString());

	return axios.post(`${process.env.REACT_APP_API_URL}/teachers/documents/`, formData, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
	);
}

export const uploadTaskFile = (file: File): Promise<AxiosResponse> => {
	const formData = new FormData();
	formData.append('file', file);

	return axios.post(`${process.env.REACT_APP_API_URL}/tasks/files/`, formData, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
	);
}

export const removeTeacherTask = (data: number) =>
	axios.delete(`${process.env.REACT_APP_API_URL}/teachers/tasks/${data}/`);

export const removeStudentTask = (data: number) =>
	axios.delete(`${process.env.REACT_APP_API_URL}/teachers/student_tasks/${data}/`);

export const addMediaArchive = (data: {
	school_class: number,
	description: string,
	date: string,
	preview?: number,
	studying_year?: number
}) =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/media_archive/`, {
			...data
		}
	);

export const uploadMediaArchiveFile = (file: File): Promise<AxiosResponse> => {
	const formData = new FormData();
	formData.append('file', file);
	formData.append('presentation_name', file.name);
	formData.append('type', file.type.includes('video') ? '1' : '0')

	return axios.post(`${process.env.REACT_APP_API_URL}/users/media_archive_files/`, formData, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
	);
}

export const removeMediaArchiveFile = (id: number) =>
	axios.delete(`${process.env.REACT_APP_API_URL}/users/media_archive_files/${id}/`);

export const removeMediaArchive = (id: number) =>
	axios.delete(`${process.env.REACT_APP_API_URL}/users/media_archive/${id}/`);

export const addMediaArchiveFile = (archiveId: number, ids: number[]) =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/media_archive/${archiveId}/files/`, {
			files: ids
		}
	);

export const editMediaArchive = (id: number, data: {
	school_class: number,
	description: string,
	date: string,
	preview?: number,
	studying_year?: number
}) =>
	axios.patch(`${process.env.REACT_APP_API_URL}/users/media_archive/${id}/`, {
			...data
		}
	);

export const getClassTasks = async({ queryKey }: QueryFunctionContext): Promise<Array<any>> => {
	const [, id] = queryKey as [string, number, number, string, string];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/classes/${id}/tasks/`);
	return response?.data;
}

export const addClassTask = (data: {
	school_class: number,
	subject: number,
	topic: string,
	type: number,
	description: string,
	deadline: string
}) =>
	axios.post(`${process.env.REACT_APP_API_URL}/teachers/class_tasks/`, {
			...data
		}
	);

export const removeClassTask = (id: number) =>
	axios.delete(`${process.env.REACT_APP_API_URL}/teachers/class_tasks/${id}/`);
