import React, { ChangeEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';

import { MediaType } from 'app/models/enums/media-type.enum';
import { Button, Icon, Tag } from 'app/components/ui';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { File as FileInterface } from 'app/models/file.interface';
import { getMediaContent } from 'app/services/userAPI';
import {
	addMediaArchiveFile,
	removeMediaArchive, removeMediaArchiveFile,
	uploadMediaArchiveFile
} from 'app/services/teacherAPI';
import { ConfirmModal, FullSizeFridgeContainer } from 'app/components/modals';
import avatarDefault from 'assets/images/avatarDefault.png';
import { MediaPreviewModal } from './components/MediaPreviewModal';

import styles from './index.module.sass';
import { AlbomAddingForm } from '../MediaArchive/components/AlbomAddingForm';

export const TeacherMediaArchiveContent = () => {
	const navigation = useNavigate();
	const params = useParams();

	const [modalContent, setModalContent] = useState<FileInterface | null>(null);
	const [isAlbomRemovingOpen, setIsAlbomRemovingOpen] = useState(false);
	const [isFileRemovingOpen, setIsFileRemovingOpen] = useState(false);
	const [fileForRemove, setFileForRemove] = useState<number>();
	const [isAlbomEditingOpen, setIsAlbomEditingOpen] = useState(false);

	const { data, refetch } = useQuery(['media-content', params?.id], getMediaContent, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const removeAlbomMutation = useMutation((taskId: number) => removeMediaArchive(taskId));
	const uploadFileMutation = useMutation((file: File) => uploadMediaArchiveFile(file));
	// eslint-disable-next-line no-unsafe-optional-chaining
	const addFileMutation = useMutation((ids: number[]) => addMediaArchiveFile(+params?.id!, ids));
	const deleteMutation = useMutation((id: number) => removeMediaArchiveFile(id));

	const onClosePreviewHandle = () => setModalContent(null);

	const removeAlbom = () => {
		setIsAlbomRemovingOpen(true);
	}

	const handleAddFile = (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		if (!files) {
			return null;
		}
		return uploadFileMutation.mutate(files[0], {
			onSuccess: res => {
				e.target.value = '';

				// @ts-ignore
				// eslint-disable-next-line no-unsafe-optional-chaining
				addFileMutation.mutate([...data?.files?.map(item => item?.id), res.data?.id], {
					onSuccess: () => {
						refetch();
					}
				});
			},
		});
	};

	const removeAlbomAccept = () => {
		setIsAlbomRemovingOpen(false);

		removeAlbomMutation.mutate(
			// eslint-disable-next-line no-unsafe-optional-chaining
			+params?.id!,
			{
				onSuccess: () => {
					toast.success('Альбом удален');
					navigation(-1);
				},
				onError: () => {
					toast.error('Не удалось удалить альбом');
				}
			}
		);
	}

	const removeFile = (id: number) => {
		setFileForRemove(id);
		setIsFileRemovingOpen(true);
	}

	const removeFileAccept = () => {
		setIsFileRemovingOpen(false);

		if (fileForRemove) {
			deleteMutation.mutate(fileForRemove,{
				onSuccess: () => {
					toast.success('Файл удален');
					setFileForRemove(undefined);
					refetch();
				},
				onError: () => {
					toast.error('Не удалось удалить файл');
					setFileForRemove(undefined);
				}
			});
		}
	}

	const onOpenAlbomEditingHandle = () => setIsAlbomEditingOpen(true);

	const onCloseAlbomEditingHandle = (withReload = false) => {
		if (typeof withReload === 'boolean' && withReload) {
			refetch();
		}

		setIsAlbomEditingOpen(false);
	}

	return (
		<section className={styles.wrapper}>
			<section className={cn(styles.content)}>
				<section className={styles.header_wrapper}>
					<div className={styles.header}>
						<h2>{data?.school_class?.name}</h2>
						<Tag>
							{DateTime.fromISO(data?.date!).toFormat('dd MMMM yyyy')}
						</Tag>
					</div>
					<div className={styles.header}>
						<button
							onClick={onOpenAlbomEditingHandle}
							className={styles.header__button}
						>
							<Icon
								icon='edit'
								size={24}
							/>
						</button>
						<Button
							onClick={removeAlbom}
							theme={ButtonTheme.secondary}
							size={ButtonSize.s}
						>
							Удалить альбом
						</Button>
					</div>
				</section>
				<p className={styles.theme}>{data?.description}</p>
				<section className={styles.cards}>
					{
						data?.files?.map(media =>
							<div key={media?.id} className={styles.file}>
								<button
									onClick={() => setModalContent(media)}
								>
									{
										media.type === MediaType.Photo &&
										<img
											src={media?.file}
											alt='poster'
										/>
									}
									{
										media.type === MediaType.Video &&
										<div
											className={styles.cover}
											style={{ backgroundImage: `url(${avatarDefault})` }}
										>
											<div className={styles.play}>
												<Icon
													icon='play'
													size={24}
												/>
											</div>
										</div>
									}
								</button>
								<button onClick={() => removeFile(media?.id)}>
									<Icon icon='close' size={24} />
								</button>
							</div>
						)
					}
					<label
						htmlFor='fileInput'
						className={styles.upload}
					>
						<Icon icon='upload-photo' size={24}/>
						Выберите файл
						<input
							id='fileInput'
							type='file'
							onChange={e =>
								handleAddFile(e)}
						/>
					</label>
				</section>
			</section>
			<button
				onClick={() => navigation(-1)}
				className={styles.close}
			>
				<Icon icon='close' size={24} />
			</button>
			{
				modalContent &&
				<MediaPreviewModal file={modalContent} onClose={onClosePreviewHandle} />
			}
			<ConfirmModal
				isOpen={isFileRemovingOpen}
				onClose={() => {
					setIsFileRemovingOpen(false);
					setFileForRemove(undefined);
				}}
				onAccept={removeFileAccept}
			>
				<h3>Вы уверены, что хотите <br/> удалить файл?</h3>
			</ConfirmModal>
			<ConfirmModal
				isOpen={isAlbomRemovingOpen}
				onClose={() => setIsAlbomRemovingOpen(false)}
				onAccept={removeAlbomAccept}
			>
				<h3>Вы уверены, что хотите <br/> удалить альбом?</h3>
			</ConfirmModal>
			<FullSizeFridgeContainer
				isOpen={isAlbomEditingOpen}
				onClose={onCloseAlbomEditingHandle}
			>
				<AlbomAddingForm
					data={{
						school_class: data?.school_class?.id!,
						description: data?.description!,
						date: data?.date!,
						preview: data?.preview
					}}
					onCancel={onCloseAlbomEditingHandle}
				/>
			</FullSizeFridgeContainer>
		</section>
	);
}
