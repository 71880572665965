import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { Schedule } from 'app/components/features';
import { getChildSchedule } from 'app/services/parentAPI';
import { EventType } from 'app/models/enums/event-type.enum';

import styles from './index.module.sass';

export const ParentClassSchedule = () => {
	const [date, setDate] = useState<string>();
	const [eventType, setEventType] = useState<EventType>(EventType.Lesson);

	const child = useSelector((state: any) => state.childSlice);

	const { data } = useQuery(['events', date, eventType, child?.id], getChildSchedule, {
		retry: 0,
		refetchOnWindowFocus: false,
		cacheTime: 0,
		enabled: !!date && !!eventType.toString() && !!child?.id?.toString()
	});

	const onDateChange = (date: string) => {
		setDate(date);
	}

	const onEventTypeChange = (type: EventType) => {
		setEventType(type);
	}

	return (
		<section className={styles.content}>
			<Schedule
				data={data}
				onDateChange={onDateChange}
				onEventTypeChange={onEventTypeChange}
			/>
		</section>
	);
}
