import React, { FC, ReactNode } from 'react';
import cn from 'classnames';
import styles from './index.module.sass';
import { Icon } from '../Icon';

export enum ButtonTheme {
	primary,
	secondary,
	clear,
	ghost
}

export enum ButtonSize {
	l,
	m,
	s,
	xs
}

interface Props {
	type: 'button' | 'submit';
	onClick: () => void;
	theme: ButtonTheme;
	size: ButtonSize;
	stretch: boolean;
	leftIcon: string;
	rightIcon: string;
	disabled: boolean;
	className: string;
	children: ReactNode;
}

export const Button: FC<Partial<Props>> = (props) => {
	const {
		type = 'button',
		onClick = () => {},
		theme = ButtonTheme.primary,
		size = ButtonSize.m,
		stretch,
		leftIcon,
		rightIcon,
		className,
		children,
		...restProps
	} = props;

	const getIconSize = () => {
		switch (size) {
			case ButtonSize.l:
				return 24;
			case ButtonSize.m:
				return 16;
			case ButtonSize.s:
				return 12;
			case ButtonSize.xs:
				return 12;
			default:
				return 0;
		}
	}

	return (
		<button
			{...restProps}
			type={type}
			className={cn(
				styles.btn,
				className,
				{
					[styles.btn_primary]: theme === ButtonTheme.primary,
					[styles.btn_secondary]: theme === ButtonTheme.secondary,
					[styles.btn_clear]: theme === ButtonTheme.clear,
					[styles.btn_ghost]: theme === ButtonTheme.ghost,
					[styles.btn_l]: size === ButtonSize.l,
					[styles.btn_m]: size === ButtonSize.m,
					[styles.btn_s]: size === ButtonSize.s,
					[styles.btn_xs]: size === ButtonSize.xs,
					[styles.btn_stretch]: stretch
				}
			)}
			onClick={onClick}
		>
			{
				leftIcon &&
				<Icon
					icon={leftIcon}
					size={getIconSize()}
					className={styles.prefix}
				/>
			}
			{children}
			{
				rightIcon &&
				<Icon
					icon={rightIcon}
					size={getIconSize()}
					className={styles.suffix}
				/>
			}
		</button>
	);
}
