import React, { ReactNode } from 'react';
import cn from 'classnames';
import styles from './index.module.sass';

interface Props {
	children: ReactNode;
	className?: string;
}

export const Tag = ({children, className, ...restProps}: Props) => (
	<div
		{...restProps}
		className={cn(styles.tag, className)}
	>
		{ children }
	</div>
);
