import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { getChildClassmates } from 'app/services/parentAPI';
import { StudentCard } from 'app/components/features';

import styles from './index.module.sass';

export const ParentClassStudents = () => {
	const childId = useLocation().pathname.split('/')[2];

	const { data } = useQuery(['child_classmates', childId], getChildClassmates, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!childId
	});

	return (
		<section className={styles.content}>
			{
				data?.map((student) => (
					<StudentCard key={Math.random()} disabled student={student} />
				))
			}
		</section>
	);
}
