import { ComponentType, FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { StorageService } from 'app/services/storageService';
import { setCurrentUser } from 'app/reducers/userSlice';
import { UserRole } from 'app/models/enums/user-role.enum';

interface Props {
	component?: ComponentType;
}

export const PrivateStudentRoute: FC<Props> = ({ component: RouteComponent }) => {
	const storage = StorageService.getInstance();
	const user = useSelector((state: any) => state.userSlice);
	const dispatch = useDispatch();

	if (!storage.token || user?.role !== UserRole.Student) {
		storage.logout();
		dispatch(setCurrentUser(null));

		return <Navigate to='/login' />
	}

	return RouteComponent ? <RouteComponent /> : <Outlet />;
}
