import axios from 'axios';

import { Semester } from 'app/models/semester.interface';

export const getStudyingYears = async(): Promise<Array<{ id: number, name: string }>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/school/studying_years/`, {
		params: {
			is_archive: true
		}
	});
	return response?.data;
}

export const getSemesters = async(): Promise<Array<Semester>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/school/studying_years/semesters/`);
	return response?.data;
}

export const getCurrentSemester = async(): Promise<Semester> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/school/studying_years/semesters/current/`);
	return response?.data;
}

export const getGradesTypes = async(): Promise<Array<{id: number, name: string}>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/school/grades/types/`);
	return response?.data;
}
