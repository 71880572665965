import React, { ReactNode } from 'react';
import Modal, { Styles } from 'react-modal';
import { Icon } from 'app/components/ui';
import styles from './index.module.sass';

interface Props {
	isOpen: boolean;
	onClose?: () => void;
	children: ReactNode;
	config?: Partial<Modal.Props>;
	hasCloseButton?: boolean;
	className?: string;
}

const customStyles: Partial<Styles> = {
	content: {
		margin: 'auto',
		background: 'white',
		borderRadius: '18px',
		width: '746px',
		height: 'fit-content',
	},
	overlay: {
		background: 'rgba(34, 95, 168, 0.8)',
		transition: 'none'
	}
};

export const ModalContainer = (props: Props) => {
	const { isOpen, onClose, children, config = {}, className } = props;

	const newConfig: Partial<Modal.Props> = {
		...config,
	};

	return (
		<Modal
			onRequestClose={onClose}
			isOpen={isOpen}
			{...newConfig}
			style={{...customStyles}}
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
			className={className}
		>
			<button
				onClick={onClose}
				className={styles.close}
			>
				<Icon
					icon="clear"
					size={24}
				/>
			</button>
			{children}
		</Modal>
	);
}
