import React from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';

import { Event } from 'app/models/event.interface';

import styles from './index.module.sass';

interface Props {
	events: Array<Event> | undefined;
	className?: string;
}
export const Events = (props: Props) => {
	const { events, className, ...restProps } = props;

	return (
		<section {...restProps} className={cn(styles.wrapper, className)}>
			<h4>Мероприятия</h4>
			{
				events && events?.length > 0 ?
					events?.map(event => (
						<div key={Math.random()} className={styles.event}>
							<p className={styles.event__caption}>{event?.name}</p>
							<p className={styles.event__members}>
								{event?.classes.toString()}
							</p>
							<p className={styles.event__time}>
								{DateTime.fromISO(event?.time).toFormat('HH:mm')}, {DateTime.fromISO(event?.date).toFormat('d MMMM yyyy')}
							</p>
							<p className={styles.event__place}>{event?.place}</p>
						</div>
					)) :
					<p className={styles.empty_message}>
						Мероприятия отсутствуют
					</p>
			}
		</section>
	);
}
