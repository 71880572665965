import React from 'react';
import { useLocation } from 'react-router-dom';
import { Evaluation } from 'app/components/features';

import styles from './index.module.sass';

export const TeacherStudentEvaluation = () => {
	const currentStudentId = useLocation().pathname.split('/')[5];

	return (
		<section className={styles.content}>
			<Evaluation studentId={currentStudentId} />
		</section>
	);
}
