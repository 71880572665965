import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { StudentCard } from 'app/components/features';
import { getClassStudents } from 'app/services/teacherAPI';

import styles from './index.module.sass';

export const TeacherClassStudents = () => {
	const currentClassId = useLocation().pathname.split('/')[3];
	const { data } = useQuery(['students', currentClassId], getClassStudents, {
		retry: 0,
		enabled: !!currentClassId
	});

	return (
		<section className={styles.content}>
			{
				data?.map((student) => (
					<StudentCard key={Math.random()} student={student} />
				))
			}
		</section>
	);
}
