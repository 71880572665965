export const ServicesMock = [
	{
		id: 1,
		title: '1 услуга',
		description: 'В этом проекты мы узнаем, в каких неселённых пунктах живет наши друзья, что их окружает.',
		price: 4000
	},
	{
		id: 2,
		title: 'еще какая-то услуга',
		description: 'В этом проекты мы узнаем, в каких неселённых пунктах живет наши друзья, что их окружает.',
		price: 3000
	},
	{
		id: 3,
		title: 'третья услуга',
		description: 'В этом проекты мы узнаем, в каких неселённых пунктах живет наши друзья, что их окружает.',
		price: 1500
	},
	{
		id: 4,
		title: '4 услуга',
		description: 'В этом проекты мы узнаем, в каких неселённых пунктах живет наши друзья, что их окружает.',
		price: 200
	},
];
