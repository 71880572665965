import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom'
import { StorageService } from 'app/services/storageService';

// @ts-ignore
const ErrorInterceptor = ({ children }) => {
	const storage = StorageService.getInstance();
	const navigate = useNavigate();

	const errInterceptor = (error: AxiosError<Error>) => {
		if (error.response?.status === 401) {
			storage.logout();
			navigate('/login');
		}

		return Promise.reject(error);
	}


	axios.interceptors.response.use((response) => response, errInterceptor);

	return children;
}

export { ErrorInterceptor }
