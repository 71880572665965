const WIDTH_REGEX = /viewBox=['"]0 0 (\d+) \d+['"]/;
const HEIGHT_REGEX = /viewBox=['"]0 0 \d+ (\d+)['"]/;

const SymbolRegex = (symbol: string): RegExp =>
	new RegExp(`<symbol[^>]*id="${symbol}"[\\s\\S]*?</symbol>`, 'g');
const ViewboxRegex = /viewBox=['"](.*?)['"]/;

export function getSize(source: string): [number | null, number | null] {
	const matchedWidth = source.match(WIDTH_REGEX);
	const matchedHeight = source.match(HEIGHT_REGEX);

	if (!matchedWidth || !matchedHeight) {
		return [null, null];
	}

	return [+matchedWidth[1], +matchedHeight[1]];
}

export function setSize(
	source: string,
	width?: number,
	height?: number,
): string {
	return source
		.replace(WIDTH_REGEX, `width="${width}"`)
		.replace(HEIGHT_REGEX, `height="${height}"`);
}

export function symbolToSvg(res: string, mappedSrc: string): string | null {
	const use = mappedSrc.split('#')[1];
	const matched = res.match(SymbolRegex(use));

	if (!matched?.length) {
		return null;
	}

	const matchedViewbox = matched[0].match(ViewboxRegex);
	if (!(matchedViewbox && matchedViewbox[1])) {
		return null;
	}

	return `<svg
			version='1.1'
			xmlns='http://www.w3.org/2000/svg'
			focusable='false'
			viewBox='${matchedViewbox[1]}'
			width='fit-content'
		>
			<use xlink:href='${mappedSrc}'></use>
		</svg>`;
}

export type StringHandler<T> = (item: T) => string;

export const DEFAULT_ICONS_PATH: StringHandler<string> = name =>
	name.includes('.svg#') ? name : `/sprite.svg#${name}`;

export const DefaultIcons: Record<string, string> = {
	tkDropdownArrow:
		'<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19.5303 8.46967c.2929.29289.2929.76777 0 1.06066l-7 6.99997c-.1406.1407-.3314.2197-.5303.2197-.1989 0-.3897-.079-.5303-.2197L4.46967 9.53033c-.29289-.29289-.29289-.76777 0-1.06066s.76777-.29289 1.06066 0L12 14.9393l6.4697-6.46963c.2929-.29289.7677-.29289 1.0606 0z" fill="#000B26" fill-opacity=".8"/></svg>',
	tkCalendarArrow:
		'<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path  d="M13.5302 4.96979C13.8231 5.26269 13.8231 5.73756 13.5302 6.03045L8.53021 11.0305C8.23731 11.3233 7.76244 11.3233 7.46955 11.0305L2.46955 6.03045C2.17665 5.73756 2.17665 5.26268 2.46955 4.96979C2.76244 4.6769 3.23732 4.6769 3.53021 4.96979L7.99988 9.43946L12.4695 4.96979C12.7624 4.6769 13.2373 4.6769 13.5302 4.96979Z" /></svg>',
	tkCheck:
		'<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M16.03 5.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06 0l-3-3a.75.75 0 111.06-1.06L8 12.44l6.97-6.97a.75.75 0 011.06 0z"/></svg>',
};

const cache = new Map<string, Promise<string>>();

export const cachedRequest = (url: string): Promise<string> => {
	const urlBase = url.split('#')[0];
	const cached = cache.get(urlBase);

	if (cached) {
		return cached;
	}

	const promise = new Promise<string>((resolve, reject) => {
		const xhr = new XMLHttpRequest();

		xhr.onreadystatechange = () => {
			if (xhr.readyState === 4) {
				const response = xhr.responseType
					? xhr.response
					: xhr.responseText;

				if (xhr.status === 200) {
					resolve(response);
				} else {
					reject(response);
				}
			}
		};
		xhr.open('GET', urlBase);
		xhr.send();

		// eslint-disable-next-line no-promise-executor-return
		return () => {
			xhr.abort();
		};
	});

	cache.set(urlBase, promise);

	return promise;
};
