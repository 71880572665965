import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { ErrorMessage, Form, Formik } from 'formik';
import { recoverPassword } from 'app/services/authAPI';
import { Button, Input } from 'app/components/ui';

import styles from 'app/pages/Login/index.module.sass';

const PasswordRecoveryFormSchema = Yup.object().shape({
	email: Yup.string()
		.email('Проверьте правильность почты')
		.required('Заполните поле')
});

export const PasswordRecovery: FC = () => {
	const navigate = useNavigate();
	const mutation = useMutation((email: string) => recoverPassword(email));

	return (
		<>
			<h1>Восстановление пароля</h1>
			<Formik
				initialValues={{
					email: '',
				}}
				validationSchema={PasswordRecoveryFormSchema}
				onSubmit={(
					values
				) => {
					mutation.mutate(values.email, {
						onSuccess: () => {
							navigate('/login');
							toast.success('Письмо со ссылкой на восстановление пароля отправлено на указанный email');
						},
						onError: () => {
							toast.error('Произошла ошибка');
						}
					})
				}}
			>
				{({ values, setFieldValue }) => (
					<Form className={styles.form}>
						<div className={styles.form__field}>
							<Input
								leftIcon='user'
								placeholder='Email'
								value={values.email}
								onChange={val => setFieldValue('email', val)}
								iconColors='#225fa8'
							/>

							<p className={styles.form__field__error}>
								<ErrorMessage name='email' />
							</p>
						</div>

						<Button
							type='submit'
							className={styles.form__control}
							disabled={mutation.isLoading}
							stretch
						>
							Восстановить пароль
						</Button>
					</Form>
				)}
			</Formik>
			<Link
				to='/login'
				className={styles.form__control_link}
			>
				Вход на портал
			</Link>
		</>
	);
}
