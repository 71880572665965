import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { Icon } from 'app/components/ui/Icon';

import styles from './index.module.sass';

export const StudentClass = () => {
	const user = useSelector((state: any) => state.userSlice);

	return (
		<section className={styles.wrapper}>
			<aside className={styles.navigation}>
				<p className={styles.title}>
					{user?.school_class?.name}
				</p>
				<nav>
					<ul>
						<li>
							<NavLink
								to='students'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='students-color'
									size={24}
									className={styles.link__icon}
								/>
								Ученики
							</NavLink>
						</li>
						<li>
							<NavLink
								to='parents'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='parents-color'
									size={24}
									className={styles.link__icon}
								/>
								Родители
							</NavLink>
						</li>
						<li>
							<NavLink
								to='media-archive'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='video-color'
									size={24}
									className={styles.link__icon}
								/>
								Фото- и видеоархив
							</NavLink>
						</li>
					</ul>
				</nav>
			</aside>
			<Outlet />
		</section>
	);
}
