import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import cn from 'classnames';
import { getTeachers } from 'app/services/teacherAPI';
import { EmployeeShort } from 'app/models/teacher.interface';
import { Button } from 'app/components/ui';
import { ButtonTheme } from 'app/components/ui/Button';
import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

export const TeacherEmployeeList = () => {
	const navigate = useNavigate();
	const [params] = useSearchParams();

	const limit = 10;
	const [offset, setOffset] = useState(0);
	const [employees, setEmployees] = useState<Array<EmployeeShort>>([]);

	const { data, refetch } = useQuery(['teachers', limit, offset, params.get('type')], getTeachers, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: false,
		cacheTime: 0
	});

	useEffect(() => {
		if (offset === 0) {
			refetch();
		} else {
			setOffset(0);
		}
	}, [params]);

	useEffect(() => {
		refetch();
	}, [offset]);

	useEffect(() => {
		if (!data) {
			return;
		}
		if(data?.results?.length > 0) {
			const results = data?.results;

			if (offset === 0) {
				setEmployees(results);
			} else {
				setEmployees([...employees, ...results]);
			}
		} else {
			setEmployees([]);
		}}, [data]);

	const getNextPage = () => {
		setOffset(offset + limit);
	};

	return (
		<div className={styles.container}>
			<section className={styles.card__list}>
				{
					employees?.map(item =>
						<button
							type='button'
							onClick={() => navigate(`${item.id}`)}
							className={styles.card__container}
							key={item.id}
						>
							<img
								src={item.avatar || avatarDefault}
								className={styles.avatar}
								alt='employee avatar'
							/>
							<div>
								<p className={styles.name}>{`${item.last_name} ${item.first_name} ${item.patronymic}`}</p>
								<p className={styles.department}>{item.job_title}</p>
							</div>
						</button>
					)
				}
			</section>
			{
				data?.count! > employees?.length &&
				<Button
					theme={ButtonTheme.secondary}
					onClick={getNextPage}
					stretch
					className={cn(styles.button_add)}
				>
					Показать еще
				</Button>
			}
		</div>
	);
}
