import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { Button, Icon } from 'app/components/ui';
import { ButtonTheme } from 'app/components/ui/Button';
import { FullSizeFridgeContainer } from 'app/components/modals';
import {
	getCurrentYearClasses,
	getSyllabus,
	getTeacherSubjects
} from 'app/services/teacherAPI';
import { SyllabusFilters } from '../Filters';
import { PlanEditForm } from '../PlanEditForm';
import { TeacherSyllabusContentTable } from '../ContentTable';

import styles from './index.module.sass';

export const TeacherSyllabusTasksCurrentYear = () => {
	const [filterValues, setFilterValues] = useState<{ class_number: number, subject: number }>();

	const { data: classes } = useQuery('teacher-classes', getCurrentYearClasses, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { data: subjects } = useQuery('teacher-subjects', getTeacherSubjects, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { data: syllabus, refetch } = useQuery(['current-year-syllabus', filterValues?.class_number, filterValues?.subject], getSyllabus, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!filterValues?.class_number && !!filterValues?.subject
	});

	useEffect(() => {
		if (!!classes && !!subjects) {
			setFilterValues({ class_number: classes[0]?.id, subject: subjects[0]?.id });
		}
	}, [classes, subjects]);

	const [isSyllabusEditing, setIsSyllabusEditing] = useState(false);

	const onOpenEditingHandle = () => setIsSyllabusEditing(true);

	const onCloseEditingHandle = (withRefetch = false) => {
		if (withRefetch) {
			refetch();
		}

		setIsSyllabusEditing(false);
	}

	const onFiltersChange = (values: {
		class_number: number,
		subject: number,
	}) => {
		setFilterValues(values);
	}

	return (
		<section className={styles.wrapper}>
			<header className={styles.header}>
				<h1>Текущий учебный год</h1>
				<div className={styles.header__controls}>
					{
						!syllabus?.id ?
							<Button
								onClick={onOpenEditingHandle}
							>
								Добавить план
							</Button> :
							<>
								<Button
									onClick={onOpenEditingHandle}
									theme={ButtonTheme.ghost}
								>
									Редактировать
								</Button>
								<Button
									theme={ButtonTheme.ghost}
								>
									<Icon icon='printer' size={18} />
								</Button>
							</>
					}
				</div>
			</header>
			<SyllabusFilters
				classes={classes ?? []}
				subjects={subjects ?? []}
				onChange={onFiltersChange}
				className={styles.filters}
			/>
			<section className={styles.content}>
				{
					!syllabus?.id ?
						<p className={styles.empty_message}>
							Учебный план, нажав на кнопку “Добавить план”
						</p> :
						<TeacherSyllabusContentTable data={syllabus?.data ?? []} />
				}
			</section>
			<FullSizeFridgeContainer
				isOpen={isSyllabusEditing}
				onClose={onCloseEditingHandle}
			>
				<PlanEditForm
					data={{
						id: syllabus?.id ?? undefined,
						school_class: syllabus?.school_class ?? filterValues?.class_number!,
						subject: syllabus?.subject ?? filterValues?.subject!,
						data: syllabus?.data ?? [],
					}}
					onClose={onCloseEditingHandle}
				/>
			</FullSizeFridgeContainer>
		</section>
	);
}
