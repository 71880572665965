import React from 'react';
import cn from 'classnames';
import styles from './index.module.sass';

interface Props {
	value: number | number[];
	isActive?: boolean;
	isWide?: boolean;
	className?: string;
}

const colorByEvaluate = (val: number) => {
	if (val > 4) {
		return '#16a880'
	}
	if (val > 3) {
		return '#8fc61a'
	}
	if (val > 2) {
		return '#ffa922'
	}
	return '#ff7122';
};

const EvaluationTag = (props: Props) => {
	const { value, isActive, isWide = false, className, ...restProps } = props;

	return (
		<p
			{...restProps}
			style={{
				backgroundColor: colorByEvaluate(Array.isArray(value) ? value[0] : value),

			}}
			className={cn(styles.container, isWide && styles.container__wide, isActive && styles.container_active, className)}
		>
			{Array.isArray(value) ?
				value.slice(0, 2).filter(Boolean).join('/') : value}
		</p>
	);
}

export default React.memo(EvaluationTag);
