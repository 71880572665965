import React from 'react';
import cn from 'classnames';
import Select, { StylesConfig } from 'react-select';

import styles from './index.module.sass';

const colourStyles: StylesConfig<any> = {
	control: (styles) => ({
		...styles,
		border: '1px solid #E6E6E6',
		lineHeight: '27px',
		fontWeight: 'normal',
		borderRadius: '12px',
		margin: 0,
		padding: 0,
		':active': {
			border: '1px solid #E6E6E6'
		},
		':focus': {
			border: '1px solid #E6E6E6'
		},
		':hover': {
			cursor: 'pointer',
			border: '1px solid #E6E6E6'
		}
	}),
	singleValue: (styles) => ({
		...styles,
		margin: 0,
		padding: 0,
	}),
	placeholder: (styles) => ({
		...styles,
		fontSize: '16px',
		fontWeight: 'normal',
		color: '#000',
	}),
	option: (styles, { isDisabled, isFocused, isSelected}) => ({
		...styles,
		fontWeight: 'normal',
		minHeight: '34px',
		padding: 8,
		margin: 0,
		opacity: isDisabled ? 0.6 : 1,
		backgroundColor: isFocused && !isSelected ? 'rgba(75, 74, 73, 0.14)' : '#fff',
		color: isFocused ? '#000' : '#000',
		borderLeft: isSelected ? '2px solid #225FA8' : '2px solid #fff',
		':hover': {
			backgroundColor: 'rgba(75, 74, 73, 0.14)',
			borderLeft: isSelected ? '2px solid #225FA8' : '2px solid rgba(75, 74, 73, 0.14)',
			cursor: 'pointer',
		},
		':visited': {
			backgroundColor: 'rgba(75, 74, 73, 0.14)',
		},
		':selected': {
			...styles[':active'],
			backgroundColor: '#fff',
			borderLeft: '2px solid #225FA8',
		},
		zIndex: 2,
		':first-child': {
			borderTopLeftRadius: '4px',
			borderTopRightRadius: '4px',
		},
		':next-child': {
			borderBottomLeftRadius: '4px',
			borderBottomRightRadius: '4px',
		}
	}),
	valueContainer: styles => ({
		...styles,
		padding: '2px 16px',
	}),
	input: styles => ({
		...styles,
		padding: 0,
		margin: 0,
	}),
	indicatorSeparator: styles => ({ ...styles, display: 'none'}),
	menu: styles => ({
		...styles,
		padding: 0,
		zIndex: 2,
	}),
	dropdownIndicator: styles => ({ ...styles, color: '#225FA8' }),
	menuList: styles => ({
		...styles,
		padding: 0,
		margin: 0,
	}),
};

export enum SelectSize {
	s,
	m
}

interface Props<T> {
	options: Array<{ value: T, label: string }>;
	onChange: (option: { value: T, label: string }) => void;
	value?: { value: T, label: string };
	label?: string;
	size?: SelectSize;
	disabled?: boolean;
	hasError?: boolean;
	className?: string;
}

export const AlfaSelect = <T,>(props: Props<T>) => {
	const {
		value = undefined,
		options,
		onChange,
		label = '',
		size = SelectSize.m,
		disabled = false,
		hasError = false,
		className = '',
		...restProps
	} = props;

	return (
		<section className={className}>
			{
				label &&
				<label
					id='aria-label'
					htmlFor='select'
					className={cn(styles.label,
						{
							[styles.label_m]: size === SelectSize.m,
							[styles.label_s]: size === SelectSize.s,
						}
						)}
				>
					{label}
				</label>
			}
			<Select
				{...restProps}
				aria-labelledby='aria-label'
				inputId='select'
				noOptionsMessage={() => 'Данные отсутствуют'}
				value={value}
				placeholder=''
				options={options}
				onChange={onChange}
				styles={colourStyles}
				isDisabled={disabled}
				classNames={{
					control: () => cn(
						{
							[styles.control_m]: size === SelectSize.m,
							[styles.control_s]: size === SelectSize.s,
							[styles.control_error]: hasError
						}
					),
					option: () => cn(
						{
							[styles.option_m]: size === SelectSize.m,
							[styles.option_s]: size === SelectSize.s,
						}
					),
				}}
			/>
		</section>
	);
}
