import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';

import { Layout, AuthLayout } from 'app/components/ui';
import {
	PublicRoute,
	PrivateParentRoute,
	PrivateStudentRoute,
	PrivateTeacherRoute
} from 'app/utils/routes';
import {
	LoginPage,
	PasswordRecovery,
	ChangePassword,
	Profile,
	EditProfile,
	ProfileHistory,
	MessengerPage,
	Chat,
	Notifications,
	EmptyMessages
} from 'app/pages';
import {
	Student,
	StudentClass,
	StudentClassEvaluation,
	StudentClassParents,
	StudentClassSchedule,
	StudentClassStudents,
	MyStudentDiagnostics,
	MyStudentEvaluation,
	MyStudentSchedule,
	MyStudentTasks,
	StudentSchedule,
	MyStudentInfo
} from 'app/pages/student';
import {
	ParentChildren,
	ParentChildrenDiagnostics,
	ParentChildrenEvaluation,
	ParentChildrenSchedule,
	ParentChildrenTasks,
	ParentClass,
	ParentClassEvaluation,
	ParentClassSchedule,
	ParentClassStudents,
	ParentSchedule,
	ParentServices,
	ParentCart,
	ParentClassParents,
	ParentChildrenInfo
} from 'app/pages/parent';
import {
	Teacher,
	TeacherClass,
	TeacherClasses,
	TeacherClassesArchive,
	TeacherClassesCurrentYear,
	TeacherClassEvaluation,
	TeacherClassParents,
	TeacherClassSchedule,
	TeacherClassStudents,
	TeacherSchedule,
	TeacherSyllabusTasks,
	TeacherSyllabusTasksCurrentYear,
	TeacherSyllabusTasksArchive,
	TeacherTasks,
	TeacherEmployees,
	TeacherEmployeeList,
	TeacherEmployeeProfile,
	TeacherClassSkips,
	TeacherStudentDetails,
	TeacherStudentDiagnostics,
	TeacherStudentEvaluation,
	TeacherStudentTasks,
	TeacherStudentInfo,
	TeacherMediaArchive,
	TeacherMediaArchiveContent,
	TeacherClassTasks,
} from 'app/pages/teacher';
import { MediaArchive, MediaArchiveContent } from 'app/pages/media';

import { ErrorInterceptor } from 'app/services/errorInterceptor';

import 'app/styles/main.sass';

const queryClient = new QueryClient();

const App = () => {
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === '/login') {
			queryClient.removeQueries();
		}
	}, [location]);

	return (
		<ErrorInterceptor>
			<QueryClientProvider client={queryClient}>
				<Routes>
					<Route path='/login' element={<PublicRoute component={AuthLayout} />}>
						<Route index element={<LoginPage />} />
						<Route path='password-recovery' element={<PasswordRecovery />} />
						<Route path='password-reset' element={<ChangePassword />} />
					</Route>

					<Route path='student' element={<Layout />}>
						<Route path='main' element={<PrivateStudentRoute component={Student} />}>
							<Route path='info' element={<MyStudentInfo />} />
							<Route path='schedule' element={<MyStudentSchedule />} />
							<Route path='evaluation' element={<MyStudentEvaluation />} />
							<Route path='tasks' element={<MyStudentTasks />} />
							<Route path='diagnostics' element={<MyStudentDiagnostics />} />
							<Route
								path='/student/main'
								element={<Navigate to='info' replace />}
							/>
						</Route>
						<Route path='class' element={<PrivateStudentRoute component={StudentClass} />}>
							<Route path='evaluation' element={<StudentClassEvaluation />} />
							<Route path='schedule' element={<StudentClassSchedule />} />
							<Route path='students' element={<StudentClassStudents />} />
							<Route path='parents' element={<StudentClassParents />} />
							<Route path='media-archive' element={<MediaArchive />} />
							<Route path='media-archive/:id' element={<MediaArchiveContent />} />
							<Route
								path='/student/class'
								element={<Navigate to='students' replace />}
							/>
						</Route>
						<Route path='schedule' element={<PrivateStudentRoute component={StudentSchedule} />} />
						<Route path='messenger' element={<PrivateStudentRoute component={MessengerPage} />}>
							<Route index element={<EmptyMessages />} />
							<Route path='chats/:id' element={<Chat />} />
							<Route path='groups/:id' element={<Chat />} />
							{/* eslint-disable-next-line react/jsx-no-useless-fragment */}
							<Route path='contacts' element={<></>} />
						</Route>
						<Route path='notifications' element={<PrivateStudentRoute component={Notifications} />} />
						<Route path='profile' element={<PrivateStudentRoute component={Profile} />}>
							<Route path='edit' element={<EditProfile />} />
							<Route
								path='/student/profile'
								element={<Navigate to='edit' replace />}
							/>
						</Route>
						<Route
							path='/student'
							element={<Navigate to='main' replace />}
						/>
					</Route>

					<Route path='teacher' element={<Layout />}>
						<Route path='home' element={<PrivateTeacherRoute component={Teacher} />} />
						<Route path='classes' element={<PrivateTeacherRoute component={TeacherClasses} />}>
							<Route path='current-year' element={<TeacherClassesCurrentYear />} />
							<Route path='archive' element={<TeacherClassesArchive />} />
							<Route path='media-archive' element={<TeacherMediaArchive />} />
							<Route path='media-archive/:id' element={<TeacherMediaArchiveContent />} />
							<Route
								path='/teacher/classes'
								element={<Navigate to='current-year' replace />}
							/>
						</Route>
						<Route path='classes/:id' element={<PrivateTeacherRoute component={TeacherClass} />}>
							<Route path='evaluation' element={<TeacherClassEvaluation />} />
							<Route path='schedule' element={<TeacherClassSchedule />} />
							<Route path='students' element={<TeacherClassStudents />} />
							<Route path='parents' element={<TeacherClassParents />} />
							<Route path='skips' element={<TeacherClassSkips />} />
							<Route path='tasks' element={<TeacherClassTasks />} />
							<Route
								path='/teacher/classes/:id'
								element={<Navigate to='evaluation' replace />}
							/>
						</Route>
						<Route path='classes/:id/students/:id' element={<PrivateTeacherRoute component={TeacherStudentDetails} />}>
							<Route path='info' element={<TeacherStudentInfo />} />
							<Route path='diagnostics' element={<TeacherStudentDiagnostics />} />
							<Route path='evaluation' element={<TeacherStudentEvaluation />} />
							<Route path='tasks' element={<TeacherStudentTasks />} />
							<Route
								path='/teacher/classes/:id/students/:id'
								element={<Navigate to='info' replace />}
							/>
						</Route>
						<Route path='schedule' element={<PrivateTeacherRoute component={TeacherSchedule} />} />
						<Route path='tasks' element={<PrivateTeacherRoute component={TeacherTasks} />} />
						<Route path='plan' element={<PrivateTeacherRoute component={TeacherSyllabusTasks} />}>
							<Route path='current-year' element={<TeacherSyllabusTasksCurrentYear />} />
							<Route path='archive' element={<TeacherSyllabusTasksArchive />} />
							<Route
								path='/teacher/plan'
								element={<Navigate to='current-year' replace />}
							/>
						</Route>
						<Route path='messenger' element={<PrivateTeacherRoute component={MessengerPage} />}>
							<Route index element={<EmptyMessages />} />
							<Route path='chats/:id' element={<Chat />} />
							<Route path='groups/:id' element={<Chat />} />
							{/* eslint-disable-next-line react/jsx-no-useless-fragment */}
							<Route path='contacts' element={<></>} />
						</Route>
						<Route path='employees' element={<PrivateTeacherRoute component={TeacherEmployees} />} >
							<Route index element={<TeacherEmployeeList />} />
							<Route path=':id' element={<TeacherEmployeeProfile />} />
						</Route>
						<Route path='notifications' element={<PrivateTeacherRoute component={Notifications} />} />
						<Route path='profile' element={<PrivateTeacherRoute component={Profile} />}>
							<Route path='edit' element={<EditProfile />} />
							<Route
								path='/teacher/profile'
								element={<Navigate to='edit' replace />}
							/>
						</Route>
						<Route path='/teacher' element={<Navigate to='home' replace />} />
					</Route>

					<Route path='parent' element={<Layout />}>
						<Route path=':id' element={<PrivateParentRoute />}>
							<Route path='personal' element={<ParentChildren />}>
								<Route path='info' element={<ParentChildrenInfo />} />
								<Route path='schedule' element={<ParentChildrenSchedule />} />
								<Route path='evaluation' element={<ParentChildrenEvaluation />} />
								<Route path='tasks' element={<ParentChildrenTasks />} />
								<Route path='diagnostics' element={<ParentChildrenDiagnostics />} />
								<Route
									path='/parent/:id/personal'
									element={<Navigate to='info' replace />}
								/>
							</Route>
							<Route path='class' element={<ParentClass />}>
								<Route path='evaluation' element={<ParentClassEvaluation />} />
								<Route path='schedule' element={<ParentClassSchedule />} />
								<Route path='students' element={<ParentClassStudents />} />
								<Route path='parents' element={<ParentClassParents />} />
								<Route path='media-archive' element={<MediaArchive />} />
								<Route path='media-archive/:id' element={<MediaArchiveContent />} />
								<Route
									path='/parent/:id/class'
									element={<Navigate to='evaluation' replace />}
								/>
							</Route>
							<Route path='schedule' element={<ParentSchedule />} />
							<Route path='services' element={<ParentServices />} />
							<Route path='cart' element={<ParentCart />} />
							<Route
								path='/parent/:id'
								element={<Navigate to='personal' replace />}
							/>
						</Route>
						<Route path='messenger' element={<PrivateParentRoute component={MessengerPage} />}>
							<Route index element={<EmptyMessages />} />
							<Route path='chats/:id' element={<Chat />} />
							<Route path='groups/:id' element={<Chat />} />
							{/* eslint-disable-next-line react/jsx-no-useless-fragment */}
							<Route path='contacts' element={<></>} />
						</Route>
						<Route path='notifications' element={<PrivateParentRoute component={Notifications} />} />
						<Route path='profile' element={<PrivateParentRoute component={Profile} />}>
							<Route path='edit' element={<EditProfile />} />
							<Route path='history' element={<ProfileHistory />} />
							<Route
								path='/parent/profile'
								element={<Navigate to='edit' replace />}
							/>
						</Route>
					</Route>
					<Route path='*' element={<Navigate to='/login' replace />} />
				</Routes>
			</QueryClientProvider>
		</ErrorInterceptor>
	);
}

export default App;
