import React from 'react';
import cn from 'classnames';
import { ServicesMock } from 'app/mocks/services.mock';
import { TransactionStatus } from 'app/models/enums/transactions.enum';
import styles from './index.module.sass';

const data = [
	{
		date: '12 января 2022',
		status: TransactionStatus.paid,
		sum: 2500,
		services: ServicesMock
	},
	{
		date: '10 января 2022',
		status: TransactionStatus.cancel,
		sum: 300,
		services: ServicesMock
	},
];

export const ProfileHistory = () => (
	<section className={styles.content}>
		<h1 className={styles.title}>История покупок</h1>
		{
			data.map(block =>
				<section className={styles.block} key={Math.random()}>
					<header className={styles.block__header}>
						<p className={styles.date}>{block.date}</p>
						<section>
							{
								block.status === TransactionStatus.cancel &&
								<span
									className={cn(styles.status, styles.status_cancel)}>отмена</span>
							}
							{
								block.status === TransactionStatus.paid &&
								<span
									className={cn(styles.status, styles.status_paid)}>оплачено</span>
							}
							<span
								className={styles.price}>{`${block.sum}₽`}</span>
						</section>
					</header>
					{
						block.services.map(service =>
							<section
								className={styles.service}
								key={service.id}
							>
								<section>
									<span
										className={styles.title}>{service.title}</span>
									<p className={styles.description}>{service.description}</p>
								</section>

								<span
									className={styles.title}>{`${block.sum}₽`}</span>
							</section>
						)
					}
				</section>
			)
		}
	</section>
);
