import React, { useContext, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import cn from 'classnames';

import { AbsenceMark, Grade } from 'app/models/student-rating.model';
import { Icon } from 'app/components/ui';
import {
	EvaluateType,
	EvaluationPropertiesContext
} from 'app/components/features/TeacherEvaluation/utils';
import EvaluationTag from 'app/components/features/Evaluation/components/EvaluationTag';
import { AbsenceMarkForm } from './AbsenceMarkForm';
import { GradeForm } from './GradeForm';

import styles from './index.module.sass';

enum Modes {
	grade,
	absence_mark
}

interface Props {
	isOpen: boolean;
	anchorId: string;
	data?: Grade[] | AbsenceMark;
	className?: string;
	onChange: (value: EvaluateType) => void;
	onDelete: (id: EvaluateType) => void;
	onClose: () => void;
}

const EvaluationDetailEdit = (props: Props) => {
	const {
		isOpen,
		data,
		anchorId,
		className,
		onClose = () => {},
		onChange = () => {},
		onDelete = () => {},
		...restProps
	} = props;

	const [mode, setMode] = useState(() => Modes.grade);
	const [currentData, setCurrentData] = useState<Grade | AbsenceMark | null>(null);

	const { tooltipRef } = useContext(EvaluationPropertiesContext);

	useEffect(() => {
		if (!data) {
			setCurrentData(null);
			return;
		}

		const isGrade = Array.isArray(data);

		if (!isGrade) {
			setMode(Modes.absence_mark);
			setCurrentData(data);
		}

		if (isGrade) {
			setMode(Modes.grade);
			setCurrentData((data as Grade[])[0]);
		}
	}, [data]);

	const handleAddEvaluate = () => {
		setCurrentData(null);
	};

	const handleChangeEvaluate = (value: Grade) => {
		setCurrentData(value);
	};

	return (
		<Tooltip
			{...restProps}
			isOpen={isOpen}
			className={cn(styles.container, className)}
			anchorSelect={`[id='${anchorId}']`}
			style={{
				background: 'white',
				boxShadow: '0px 14px 64px rgba(0, 0, 0, 0.25)',
				borderRadius: '8px',
				opacity: '1 !important',
				padding: '0',
				zIndex: '9999'
			}}
			events={[]}
			place='bottom'
			clickable
			noArrow
		>
			{
				isOpen &&
				<section ref={tooltipRef} className={styles.content}>
					<button onClick={onClose} className={styles.close}>
						<Icon
							icon='clear'
							size={16}
						/>
					</button>
					{
						mode === Modes.grade &&
						<div className={styles.switcher__grades}>
							{
								(data as Grade[])?.map(evaluate =>
									<button
										onClick={(e) => {
											e.stopPropagation();
											handleChangeEvaluate(evaluate);
										}}
										key={evaluate.id}
									>
										<EvaluationTag value={evaluate.score} />
									</button>
								)
							}
							{
								(data as Grade[])?.length === 1 &&
								<button
									type='button'
									onClick={handleAddEvaluate}
									className={styles.add}
								>
									<Icon
										icon='plus'
										size={16}
									/>
								</button>
							}
						</div>
					}
					{
						!data &&
						<div className={styles.switcher__types}>
							<button
								type='button'
								onClick={() => setMode(Modes.grade)}
								className={cn(
									styles.btn,
									mode === Modes.grade && styles.btn_active
								)}
							>
								Знания
							</button>
							<button
								type='button'
								onClick={() => setMode(Modes.absence_mark)}
								className={cn(
									styles.btn,
									mode !== Modes.grade && styles.btn_active
								)}
							>
								Пропуски
							</button>
						</div>
					}
					{
						mode === Modes.grade ?
							<GradeForm
								onChange={onChange}
								onDelete={() => onDelete(currentData!)}
								data={currentData as Grade}
							/>
							:
							<AbsenceMarkForm
								onChange={onChange}
								onDelete={() => onDelete(currentData!)}
								data={currentData as AbsenceMark}
							/>
					}
				</section>
			}
		</Tooltip>
	);
}

export default React.memo(EvaluationDetailEdit);
