import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import userSlice from 'app/reducers/userSlice';
import childSlice from 'app/reducers/childSlice';
import childrenSlice from 'app/reducers/childrenSlice';
import tasksCountSlice from 'app/reducers/tasksCountSlice';
import notificationsCountSlice from 'app/reducers/unreadMessagesCountSlice';

const store = configureStore({
	reducer: { userSlice, childSlice, childrenSlice, tasksCountSlice, notificationsCountSlice },
	middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(thunk),
	devTools: process.env.NODE_ENV !== 'production',
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
