import axios from 'axios';

export const login = (value: { username: string, password: string }) =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/login/`, value);

export const recoverPassword = (email: string) =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/reset-password/`, {
		email,
	});

export const changePassword = (data: {
	new_password1: string;
	new_password2: string;
	token: string;
	uid: string;
}) =>
	axios.post(`${process.env.REACT_APP_API_URL}/users/reset-password-confirm/`, data);

