import React, { useEffect, useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { useQuery } from 'react-query';

import { Button, Icon } from 'app/components/ui';
import { Birthdays } from 'app/components/features';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { FullSizeFridgeContainer } from 'app/components/modals';
import { numberEndings } from 'app/utils/numberEndings';
import {
	getStudentParents,
	getStudentTasksCount,
	getStudentBirthdays, getStudentClassmates
} from 'app/services/studentAPI';
import { setTasksCount } from 'app/reducers/tasksCountSlice';

import avatarDefault from 'assets/images/avatarDefault.png';
import gift from 'assets/images/gift.png';

import { InfoDetails } from './components/InfoDetails';

import styles from './index.module.sass';

export const Student = () => {
	const dispatch = useDispatch();

	const user = useSelector((state: any) => state.userSlice);
	const tasksCount = useSelector((state: any) => state.tasksCountSlice);

	const [isOpen, setOpen] = useState(false);

	const { data: parents } = useQuery(['parents', user?.user_id], getStudentParents, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!user?.user_id
	});

	const { data: classmates } = useQuery(['classmates'], getStudentClassmates, {
		retry: 0,
		refetchOnWindowFocus: false
	});

	const { data: tasks_count } = useQuery(['tasks_count', user?.user_id], getStudentTasksCount, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!user?.user_id
	});

	const { data: birthdays } = useQuery('birthdays', getStudentBirthdays, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (tasks_count) {
			dispatch(setTasksCount(tasks_count));
		}
	}, [tasks_count]);

	const onOpen = () => {
		setOpen(true);
	}

	const onClose = () => {
		setOpen(false);
	}

	return (
		<section className={styles.wrapper}>
			<aside className={styles.navigation}>
				<img
					src={user?.avatar ?? avatarDefault}
					alt='student'
					className={styles.student__avatar}
				/>
				<p className={styles.student__name}>
					{user?.last_name} <br /> {user?.first_name} {user?.patronymic}
				</p>
				<p className={styles.student__birthday}>
					{user?.age} {numberEndings(user?.age!, [' год ', ' года ', ' лет '])}
					{user?.birth_day ? `• ${DateTime.fromISO(user?.birth_day!).toFormat('dd MMMM yyyy')}` : ''}
				</p>
				<Button
					theme={ButtonTheme.clear}
					size={ButtonSize.s}
					onClick={onOpen}
				>
					<Icon icon='info' size='16' />
					Подробнее
				</Button>
				<nav>
					<ul>
						<li>
							<NavLink
								to='info'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='profile-color'
									size={20}
									className={styles.link__icon}
								/>
								Общая информация
							</NavLink>
						</li>
						<li>
							<NavLink
								to='schedule'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='schedule-color'
									size={24}
									className={styles.link__icon}
								/>
								Расписание
							</NavLink>
						</li>
						<li>
							<NavLink
								to='evaluation'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='evaluation-color'
									size={24}
									className={styles.link__icon}
								/>
								Общие оценки
							</NavLink>
						</li>
						<li>
							<NavLink
								to='tasks'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='tasks-color'
									size={24}
									className={styles.link__icon}
								/>
								<div className={styles.caption}>
									Задачи
									{
										tasksCount > 0 &&
										<p className={styles.count}>
											{tasksCount}
										</p>
									}
								</div>
							</NavLink>
						</li>
					</ul>
				</nav>
				{
					parents && parents?.length > 0 &&
					<section className={styles.parents}>
						<h3>Родители</h3>
						{
							parents?.map(parent => (
								<div key={Math.random()} className={styles.parents__item}>
									<img
										className={styles.avatar}
										src={parent?.avatar ? `${process.env.REACT_APP_BACK_URL}${parent?.avatar}` : avatarDefault}
										alt='parent'
									/>
									{parent?.first_name} {parent?.patronymic}
								</div>
							))
						}
					</section>
				}
				{
					classmates && classmates?.length > 0 &&
					<section className={styles.classmates}>
						<h3>{user?.school_class?.name}</h3>
						<div className={styles.classmates__wrapper}>
							{
								classmates?.slice(0, 3)?.map(classmate => (
									<img
										key={Math.random()}
										className={styles.avatar}
										src={classmate?.avatar ?? avatarDefault}
										alt='classmate'
									/>
								))
							}
							{
								classmates && classmates?.length > 3 &&
								<NavLink
									to='../class/students'
									className={styles.classmates__count}
								>
									+{classmates.length - 3}
								</NavLink>
							}
						</div>
					</section>
				}
				<section className={styles.birthdays}>
					<img
						src={gift}
						alt=''
						className={styles.sticker}
					/>
					<Birthdays birthdays={birthdays!} />
				</section>
			</aside>
			<FullSizeFridgeContainer
				isOpen={isOpen}
				onClose={onClose}
			>
				<InfoDetails />
			</FullSizeFridgeContainer>
			<Outlet />
		</section>
	);
}
