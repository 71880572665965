import React, { useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import { DateTime } from 'luxon';
import cn from 'classnames';

import { Button, Icon } from 'app/components/ui';
import { getStudentInfo, getStudentParents } from 'app/services/studentAPI';
import { numberEndings } from 'app/utils/numberEndings';
import { FullSizeFridgeContainer } from 'app/components/modals';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { createChat } from 'app/services/messengerAPI';

import avatarDefault from 'assets/images/avatarDefault.png';

import { TeacherStudentInfoDetails } from './components/InfoDetails';

import styles from './index.module.sass';

export const TeacherStudentDetails = () => {
	const navigate = useNavigate();

	const currentStudentId = useLocation().pathname.split('/')[5];

	const [isOpen, setOpen] = useState(false);

	const { data: user } = useQuery(['student info', currentStudentId], getStudentInfo, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!currentStudentId.toString()
	});

	const { data: parents } = useQuery(['parents', currentStudentId], getStudentParents, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!currentStudentId.toString()
	});

	const createChatMutation = useMutation((value: number) => createChat(value));

	const onOpen = () => {
		setOpen(true);
	}

	const onClose = () => {
		setOpen(false);
	}

	const onWriteToStudent = () => {
		createChatMutation.mutate(user?.id!,{
			onSuccess: (response) => {
				navigate(`/teacher/messenger/chats/${response?.data?.id}`);
			}
		});
	}

	return (
		<section className={styles.wrapper}>
			<aside className={styles.navigation}>
				<img
					src={user?.avatar ?? avatarDefault}
					alt='student'
					className={styles.student__avatar}
				/>
				{
					user?.birth_day === DateTime.now().toFormat('yyyy-MM-dd') &&
					<Icon
						icon='birthday'
						className={styles.student__congrats}
					/>
				}
				<p className={styles.student__name}>
					{user?.last_name} <br /> {user?.first_name} {user?.patronymic}
				</p>
				<p className={styles.student__birthday}>
					{user?.age} {numberEndings(user?.age!, [' год ', ' года ', ' лет '])}
					{user?.birth_day ? `• ${DateTime.fromISO(user?.birth_day!).toFormat('dd MMMM yyyy')}` : ''}
				</p>
				<Button
					theme={ButtonTheme.clear}
					size={ButtonSize.s}
					onClick={onOpen}
					className={styles.student_info}
				>
					<Icon icon='info' size='16' />
					Подробнее
				</Button>
				<Button
					onClick={onWriteToStudent}
					leftIcon='talk'
					stretch
				>
					Написать
				</Button>
				<nav>
					<ul>
						<li>
							<NavLink
								to='info'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='profile-color'
									size={20}
									className={styles.link__icon}
								/>
								Общая информация
							</NavLink>
						</li>
						<li>
							<NavLink
								to='evaluation'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='evaluation-color'
									size={24}
									className={styles.link__icon}
								/>
								Общие оценки
							</NavLink>
						</li>
						<li>
							<NavLink
								to='tasks'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='tasks-color'
									size={24}
									className={styles.link__icon}
								/>
								Задачи
							</NavLink>
						</li>
					</ul>
				</nav>
				<section className={styles.parents}>
					<h4>Родители</h4>
					{
						parents?.map(parent => (
							<div key={Math.random()} className={styles.parents__item}>
								<img
									className={styles.avatar}
									src={parent?.avatar ? `${process.env.REACT_APP_BACK_URL}${parent?.avatar}` : avatarDefault}
									alt='parent'
								/>
								{parent?.first_name} {parent?.patronymic}
							</div>
						))
					}
				</section>
			</aside>
			<FullSizeFridgeContainer
				isOpen={isOpen}
				onClose={onClose}
			>
				<TeacherStudentInfoDetails student={user!} />
			</FullSizeFridgeContainer>
			<Outlet />
		</section>
	);
}
