import React from 'react';
import cn from 'classnames';

import { Parent } from 'app/models/parent.interface';

import styles from './index.module.sass';

interface Props {
	parent: Parent;
	className?: string;
}

export const ParentCard = (props: Props) => {
	const { parent, className, ...restProps } = props;

	return (
		<div
			key={Math.random()}
			{...restProps}
			className={cn(styles.card, className)}
		>
			<p className={styles.name}>{parent?.last_name} {parent?.first_name} {parent?.patronymic}</p>

			<span className={styles.text}>{parent?.phone_number}</span>

			<p className={styles.student}>
				<span className={styles.text}>Ученик</span>
				<h4 className={styles.student__name}>{parent?.student}</h4>
			</p>
		</div>
	);
}
