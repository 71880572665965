import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';

import { MediaType } from 'app/models/enums/media-type.enum';
import { Icon, Tag } from 'app/components/ui';
import { File } from 'app/models/file.interface';
import { getMediaContent } from 'app/services/userAPI';
import avatarDefault from 'assets/images/avatarDefault.png';
import { MediaPreviewModal } from './components/MediaPreviewModal';

import styles from './index.module.sass';

export const MediaArchiveContent = () => {
	const navigation = useNavigate();
	const params = useParams();

	const { data } = useQuery(['media-content', params?.id], getMediaContent, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const [modalContent, setModalContent] = useState<File | null>(null);

	const onClosePreviewHandle = () => setModalContent(null);

	return (
		<section className={styles.wrapper}>
			<section className={cn(styles.content)}>
				<section className={styles.header}>
					<h2>{data?.school_class?.name}</h2>
					<Tag>
						{DateTime.fromISO(data?.date!).toFormat('dd MMMM yyyy')}
					</Tag>
				</section>
				<p className={styles.theme}>{data?.description}</p>
				<section className={styles.cards}>
					{
						data?.files?.map(media =>
							<button
								key={media?.id}
								onClick={() => setModalContent(media)}
							>
								{
									media.type === MediaType.Photo &&
									<img
										src={media?.file}
										alt='poster'
									/>
								}
								{
									media.type === MediaType.Video &&
									<div
										className={styles.cover}
										style={{ backgroundImage: `url(${avatarDefault})` }}
									>
										<div className={styles.play}>
											<Icon
												icon='play'
												size={24}
											/>
										</div>
									</div>
								}
							</button>
						)
					}
				</section>
			</section>
			<button
				onClick={() => navigation(-1)}
				className={styles.close}
			>
				<Icon icon='close' size={24} />
			</button>
			{
				modalContent &&
				<MediaPreviewModal file={modalContent} onClose={onClosePreviewHandle} />
			}
		</section>
	);
}
