import React, { useState } from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import cn from 'classnames';

import { Icon } from 'app/components/ui/Icon';
import { numberEndings } from 'app/utils/numberEndings';
import { Button, ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { FullSizeFridgeContainer } from 'app/components/modals';
import { getChildClassmates } from 'app/services/parentAPI';

import avatarDefault from 'assets/images/avatarDefault.png';

import { ParentChildrenInfoDetails } from './components/InfoDetails';

import styles from './index.module.sass';

export const ParentChildren = () => {
	const child = useSelector((state: any) => state.childSlice);

	const { data: classmates } = useQuery(['child_classmates', child?.id], getChildClassmates, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!child?.id
	});

	const [isOpen, setOpen] = useState(false);

	const onOpen = () => {
		setOpen(true);
	}

	const onClose = () => {
		setOpen(false);
	}

	return (
		<section className={styles.wrapper}>
			<aside className={styles.navigation}>
				<img
					src={child?.avatar ?? avatarDefault}
					alt='student'
					className={styles.student__avatar}
				/>
				<p className={styles.student__name}>
					{child?.last_name} <br /> {child?.first_name} {child?.patronymic}
				</p>
				<p className={styles.student__birthday}>
					{child?.age} {numberEndings(child?.age!, [' год ', ' года ', ' лет '])}
					{child?.birth_day ? `• ${DateTime.fromISO(child?.birth_day!).toFormat('dd MMMM yyyy')}` : ''}
				</p>
				<Button
					theme={ButtonTheme.clear}
					size={ButtonSize.s}
					onClick={onOpen}
				>
					<Icon icon='info' size='16' />
					Подробнее
				</Button>
				<nav>
					<ul>
						<li>
							<NavLink
								to='info'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='profile-color'
									size={20}
									className={styles.link__icon}
								/>
								Общая информация
							</NavLink>
						</li>
						<li>
							<NavLink
								to='schedule'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='schedule-color'
									size={24}
									className={styles.link__icon}
								/>
								Расписание
							</NavLink>
						</li>
						<li>
							<NavLink
								to='evaluation'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='evaluation-color'
									size={24}
									className={styles.link__icon}
								/>
								Общие оценки
							</NavLink>
						</li>
						<li>
							<NavLink
								to='tasks'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='tasks-color'
									size={24}
									className={styles.link__icon}
								/>
								Задачи
							</NavLink>
						</li>
					</ul>
				</nav>
				{
					child?.school_class?.name &&
					<section className={styles.classmates}>
						<h3>{child?.school_class?.name}</h3>
						<div className={styles.classmates__wrapper}>
							{
								classmates?.slice(0, 3)?.map(parent => (
									<img
										key={Math.random()}
										className={styles.avatar}
										src={parent?.avatar ?? avatarDefault}
										alt='classmate'
									/>
								))
							}
							{
								classmates && classmates?.length > 3 &&
								<NavLink
									to='../class/students'
									className={styles.classmates__count}
								>
									+{classmates.length - 3}
								</NavLink>
							}
						</div>
					</section>
				}
			</aside>
			<FullSizeFridgeContainer
				isOpen={isOpen}
				onClose={onClose}
			>
				<ParentChildrenInfoDetails student={child} />
			</FullSizeFridgeContainer>
			<Outlet />
		</section>
	);
}
