import React, { useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { UserRole } from 'app/models/enums/user-role.enum';

import styles from './index.module.sass';

interface Props {
	userRole: UserRole;
	profile?: 0 | 1 | 2;
	className?: string;
}

export const Navigation = (props: Props) => {
	const { userRole, profile, className, ...restProps } = props;

	const child = useSelector((state: any) => state.childSlice);

	const getRoutes = useCallback((role) => {
		switch (role) {
			case UserRole.Student:
				return [
					{
						link: 'main',
						label: 'Моя страница'
					},
					{
						link: 'class',
						label: 'Мой класс'
					},
					// {
					// 	link: 'schedule',
					// 	label: 'Расписание'
					// },
				];
			case UserRole.Teacher:
				if (profile === 0) {
					return [
						{
							link: 'home',
							label: 'Моя страница'
						},
						{
							link: 'classes',
							label: 'Мои классы'
						},
						// {
						// 	link: 'schedule',
						// 	label: 'Расписание'
						// },
						{
							link: 'tasks',
							label: 'Задачи'
						},
						{
							link: 'plan',
							label: 'Учебный план'
						},
						{
							link: 'employees',
							label: 'Сотрудники'
						},
					];
				}

				return [
					{
						link: 'home',
						label: 'Моя страница'
					},
					{
						link: 'classes',
						label: 'Мои классы'
					},
					// {
					// 	link: 'schedule',
					// 	label: 'Расписание'
					// },
					{
						link: 'tasks',
						label: 'Задачи'
					},
					{
						link: 'employees',
						label: 'Сотрудники'
					},
				];
			case UserRole.Parent:
				return [
				{
					link: `${child?.id}/personal`,
					label: 'Мои дети'
				},
				{
					link: `${child?.id}/class`,
					label: 'Класс'
				},
				{
					link: `${child?.id}/schedule`,
					label: 'Расписание'
				},
				// {
				// 	link: `${child?.id}/services`,
				// 	label: 'Услуги'
				// },
			];
			default:
				return [];
		}
	}, [userRole, child]);

	return (
		<nav
			{...restProps}
			className={cn(styles.navigation, className)}
		>
			<ul>
				{
					getRoutes(userRole).map((route) => (
						<li key={route?.link}>
							<NavLink
								to={route?.link}
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								{route?.label}
							</NavLink>
						</li>
					))
				}
			</ul>
		</nav>
	);
}
