import React from 'react';
import { useMutation, useQuery } from 'react-query';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';

import { Button } from 'app/components/ui';
import { ButtonSize } from 'app/components/ui/Button';
import {
	finishTeacherTasks,
	getDailyTeacherTasks
} from 'app/services/teacherAPI';

import styles from './index.module.sass';

interface Props {
	className?: string;
}
export const TeacherDailyTasks = (props: Props) => {
	const { className, ...restProps } = props;

	const { data: tasks, refetch } = useQuery(['teacher-daily-tasks', DateTime.now().toISODate()], getDailyTeacherTasks, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const finishTaskMutation = useMutation((taskId: number) => finishTeacherTasks([taskId]));

	const onFinishTask = (id: number) => {
		finishTaskMutation.mutate(
			id,
			{
				onSuccess: () => {
					toast.success('Задача завершена');
					refetch();
				},
				onError: () => {
					toast.error('Не удалось завершить задачу');
				}
			}
		);
	}

	return (
		<section
			{...restProps}
			className={cn(styles.tasks, className)}
		>
			<p className={styles.title}>Задачи на сегодня</p>
			{
				tasks?.length === 0 &&
					<div className={cn(styles.task, styles.task_empty)}>
						<p>Задачи отсутствуют</p>
					</div>
			}
			{
				tasks && tasks?.length > 0 && tasks?.map(task => (
					<div key={task?.id} className={styles.task}>
						<div className={styles.task__date}>
							{DateTime.fromISO(task?.deadline).toFormat('dd.MM.yyyy')}
						</div>
						<p className={styles.task__description}>
							{task?.description}
						</p>
						<Button
							size={ButtonSize.xs}
							stretch
							onClick={() => onFinishTask(task?.id)}
						>
							Завершить задачу
						</Button>
					</div>
				))
			}
		</section>
	);
}
