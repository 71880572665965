import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import cn from 'classnames';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { AlfaSelect } from 'app/components/ui/Select';
import { Button, DatePicker, Textarea } from 'app/components/ui';
import { ButtonTheme } from 'app/components/ui/Button';
import { StudentTaskType } from 'app/models/enums/student-task-type.enum';
import {
	addClassTask,
	getStudyingPlanTopics,
	getTeacherSubjects
} from 'app/services/teacherAPI';

import styles from './index.module.sass';

const ClassTaskAddingFormSchema = Yup.object().shape({
	deadline: Yup.string()
		.required('Заполните поле'),
	description: Yup.string()
		.required('Заполните поле')
});

interface Props {
	type: StudentTaskType;
	onCancel: (flag?: boolean) => void;
	className?: string;
}

export const ClassTaskAddingForm = (props: Props) => {
	const { type, onCancel, className = '', ...restProps } = props;

	const classId = useLocation().pathname.split('/')[3];

	const [currentSubject, setCurrentSubject] = useState<number>();
	const [currentTopics, setCurrentTopics] = useState<Array<{ value: string, label: string }>>([]);

	const { data: subjects } = useQuery('teacher-subjects', getTeacherSubjects, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { data: topics, refetch } = useQuery(['topics', classId, currentSubject], getStudyingPlanTopics, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: false
	});

	const addMutation = useMutation((data: {
		subject: number,
		topic: string,
		description: string,
		deadline: string
	}) => addClassTask({ school_class: +classId, type, ...data }));

	useEffect(() => {
		setCurrentTopics(topics?.map(item => ({ value: item, label: item })) ?? []);
	}, [topics]);

	useEffect(() => {
		if (currentSubject) {
			refetch();
		}
	}, [currentSubject]);

	return (
		<>
			<h3>Новая задача</h3>
			<Formik
				enableReinitialize
				validationSchema={ClassTaskAddingFormSchema}
				initialValues={{
					deadline: '',
					subject: null as any,
					topic: null as any,
					description: ''
				}}
				onSubmit={values => {
					if (!classId) {
						return;
					}

					addMutation.mutate(
						values,
						{
							onSuccess: () => {
								toast.success('Задача успешно создана');
								onCancel(true);
							},
							onError: () => {
								toast.error('Не удалось создать задачу');
							}
						}
					);
				}}
			>
				{({ values, setFieldValue, errors, touched }) => (
					<Form
						{...restProps}
						className={cn(styles.form, className)}
					>
						<section>
							<DatePicker
								value={values?.deadline}
								onChange={(value) => setFieldValue('deadline', value)}
								label='Дата'
								placeholder='Выберите дату'
								hasError={!!errors?.deadline && touched?.deadline}
								className={cn(styles.control, styles.control_datepicker)}
							/>
							<AlfaSelect<number>
								onChange={option => {
									setFieldValue('subject', option.value);
									setFieldValue('topic', null);
									setCurrentSubject(option.value);
								}}
								options={subjects?.map(item => ({ value: item?.id, label: item?.full_name })) ?? []}
								label='Наименование предмета'
								className={cn(styles.control, styles.control_stretch)}
							/>
							<AlfaSelect<string>
								value={{ value: values?.topic, label: values?.topic }}
								onChange={option => setFieldValue('topic', option.value)}
								options={currentTopics}
								label='Тема урока'
								className={cn(styles.control, styles.control_stretch)}
							/>
							<Textarea
								value={values?.description}
								onChange={e => setFieldValue('description', (e.target as HTMLTextAreaElement).value)}
								label='Задача'
								hasError={!!errors?.description && touched?.description}
							/>
						</section>
						<footer className={styles.form__footer}>
							<section className={styles.form__footer__controls}>
								<Button
									onClick={onCancel}
									theme={ButtonTheme.ghost}
								>
									Отменить
								</Button>
								<Button type='submit'>
									Создать задачу
								</Button>
							</section>
						</footer>
					</Form>
				)}
			</Formik>
		</>
	);
}
