import React, { ReactNode } from 'react';
import Modal, { Styles } from 'react-modal';
import { Button } from 'app/components/ui';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';

import styles from './index.module.sass';

interface Props {
	isOpen: boolean;
	onAccept: () => void;
	onClose: () => void;
	children: ReactNode;
	config?: Partial<Modal.Props>;
	hasCloseButton?: boolean;
	className?: string;
}

const customStyles: Partial<Styles> = {
	content: {
		margin: 'auto',
		background: 'white',
		borderRadius: '18px',
		width: 'fit-content',
		height: 'fit-content',
	},
	overlay: {
		background: 'rgba(34, 95, 168, 0.8)',
		transition: 'none'
	}
};

export const ConfirmModal = (props: Props) => {
	const { isOpen, onAccept, onClose, children, config = {}, className } = props;

	const newConfig: Partial<Modal.Props> = {
		...config,
	};

	return (
		<Modal
			onRequestClose={onClose}
			isOpen={isOpen}
			{...newConfig}
			style={{...customStyles}}
			shouldCloseOnOverlayClick
			ariaHideApp={false}
			className={className}
		>
			{children}
			<div className={styles.controls}>
				<Button
					theme={ButtonTheme.secondary}
					size={ButtonSize.s}
					onClick={onClose}
				>
					Отмена
				</Button>
				<Button
					theme={ButtonTheme.primary}
					size={ButtonSize.s}
					onClick={onAccept}
				>
					Применить
				</Button>
			</div>
		</Modal>
	);
}
