import React, { ReactNode } from 'react';
import { MIN_CORRECT_LENGTH_IN_ROWS } from 'app/components/features/Evaluation/utils';
import styles from './index.module.sass';

interface Props {
	column_number: number;
	length: number;
	children: ReactNode;
	className?: string;
}

export const EvaluationAlert = ({ length, column_number, children, className, ...restProps }: Props) => (
	length >= MIN_CORRECT_LENGTH_IN_ROWS ?
		<section
			{...restProps}
			className={className}
			style={{
				gridRow: `1/ span ${length}`,
				gridColumn: column_number
			}}
		>
			<div className={styles.container}>
				<p className={styles.text}>{children}</p>
			</div>
		</section> :
		<div
			style={{
				gridRow: `1/ span ${length}`,
				gridColumn: column_number
			}}
		/>
);
