import React from 'react';
import { useQuery } from 'react-query';
import { ClassesList } from 'app/pages/teacher/Classes/components/ClassesList';
import { UpcomingLessons } from 'app/pages/teacher/Classes/components/UpcomingLessons';
import { getCurrentYearClasses } from 'app/services/teacherAPI';

import styles from './index.module.sass';

export const TeacherClassesCurrentYear = () => {
	const { data } = useQuery('classes', getCurrentYearClasses, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return (
		<section className={styles.content}>
			<ClassesList classes={data} />
			<UpcomingLessons />
		</section>
	);
}
