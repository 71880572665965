import { DependencyList, useEffect, useState } from 'react';

export const useAsyncMemo = <T = undefined>(
	factory: () => Promise<T> | undefined | null,
	deps: DependencyList,
	initial?: T
): T => {

	// @ts-ignore
	const [val, setVal] = useState<T>(initial);
	useEffect(() => {
		let cancel = false;
		const promise = factory();
		if (promise === undefined || promise === null) return;
		// eslint-disable-next-line no-shadow
		promise.then((val) => {
			if (!cancel) {
				setVal(val);
			}
		});

		// eslint-disable-next-line consistent-return
		return () => {
			cancel = true;
		};
	}, deps);
	return val;
};
