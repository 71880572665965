import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import avatarDefault from 'assets/images/avatarDefault.png';

import { setCurrentChild } from 'app/reducers/childSlice';
import { Student } from 'app/models/student.interface';
import { Icon } from 'app/components/ui/Icon';
import { Navigation } from './components/Navigation';
import { UserMenu } from './components/UserMenu';

import styles from './index.module.sass';

export const Header = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();

	const user = useSelector((state: any) => state.userSlice);
	const notificationsCount = useSelector((state: any) => state.notificationsCountSlice);
	const child = useSelector((state: any) => state.childSlice);
	const children = useSelector((state: any) => state.childrenSlice);

	const changeChild = (id: number) => {
		if (location.pathname === '/parent/profile' || location.pathname.includes('messenger')) {
			return;
		}

		dispatch(setCurrentChild(children?.find((item: Student) => item?.id === id)));
		const pathname = location.pathname.split('/');
		pathname[2] = id.toString();
		navigate(pathname.join('/'));
	}

	return (
		<header className={styles.header}>
			<section className={styles.navigation}>
				<Logo width={140} height={24} />
				<Navigation userRole={user?.role} profile={user?.profile} />
			</section>
			<section className={styles.controls}>
				{
					children && children?.length > 0 &&
					<section className={styles.children}>
						{
							children?.map((item: Student) => (
								<button
									key={item?.id}
									onClick={() => changeChild(item?.id)}
									title={`${item?.last_name} ${item?.first_name}`}
								>
									<img
										src={item?.avatar ?? avatarDefault}
										alt='Аватар'
										className={cn(
											styles.children__item,
											{[styles.children__item_active]: item?.id === child?.id}
										)}
									/>
								</button>
							))
						}
					</section>
				}
				<section className={styles.controls__group}>
					<NavLink
						to='messenger'
						className={({isActive}) => cn(styles.link, {[styles.link_active]: isActive})}
					>
						<Icon icon='comment' />
						{
							notificationsCount > 0 &&
							<div className={styles.messages}>
								{notificationsCount > 99 ? '99+' : notificationsCount}
							</div>
						}
					</NavLink>
					<NavLink
						to='notifications'
						className={({isActive}) => cn(styles.link, {[styles.link_active]: isActive})}
					>
						<Icon icon='notification' />
					</NavLink>
				</section>
				<UserMenu user={user} />
			</section>
		</header>
	);
}
