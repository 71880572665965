import axios from 'axios';
import { StorageService } from './storageService';

const storage = StorageService.getInstance();

axios.interceptors.request.use(({ ...config }) => {
	if (storage.token) {
		return {
			...config,
			headers: {
				...(config.headers || {}),
				'Authorization': `Token ${storage.token}`,
				'Content-Type': 'application/json',
			},
		};
	}
	return {
		...config,
	};
});
