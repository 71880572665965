import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { DateTime } from 'luxon';

import { Student } from 'app/models/student.interface';
import { numberEndings } from 'app/utils/numberEndings';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

interface Props {
	student: Student;
	disabled?: boolean;
	className?: string;
}

export const StudentCard = (props: Props) => {
	const { student, disabled = false, className, ...restProps } = props;

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		if (disabled) {
			event.preventDefault();
		}
	}

	return (
		<NavLink
			{...restProps}
			onClick={handleClick}
			to={student?.id.toString()}
			className={cn(styles.card, className, {[styles.card_disabled]: disabled})}
		>
			<img
				src={student.avatar || avatarDefault}
				className={styles.avatar}
				alt="Аватар"
			/>

			<h4 className={styles.name}>
				{student.first_name} {student.last_name}
			</h4>

			<p className={styles.age}>
				{student?.age}
				{numberEndings(student?.age, [' год ', ' года ', ' лет '])}
				{student?.birth_day ? `• ${DateTime.fromISO(student?.birth_day!).toFormat('d MMMM yyyy')}` : ''}
			</p>
			{
				student?.parents?.length! > 0 &&
				<section className={styles.parent__list}>
					<h5>Родители</h5>
					{
						student?.parents?.map(parent => (
							<section
								className={styles.parent}
								key={parent.first_name}
							>
								<img
									src={parent.avatar || avatarDefault}
									className={styles.parent__avatar}
									alt="Аватар"
								/>

								<span className={styles.parent__name}>
									{parent.first_name} {parent.last_name}
								</span>
							</section>
						))
					}
				</section>
			}
		</NavLink>
	);
}
