import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Input } from 'app/components/ui';
import { login } from 'app/services/authAPI';
import { StorageService } from 'app/services/storageService';

import { UserRole } from 'app/models/enums/user-role.enum';
import styles from './index.module.sass';

const AuthFormSchema = Yup.object().shape({
	username: Yup.string()
		.required('Заполните поле'),
	password: Yup.string()
		.required('Заполните поле')
});

export const LoginPage = () => {
	const storage = StorageService.getInstance();
	const navigate = useNavigate();

	const [showErrorMessage, setShowErrorMessage] = useState(false);

	const mutation = useMutation((value: { username: string, password: string }) => login(value));

	return (
		<>
			<h1>Вход на портал</h1>
			<Formik
				initialValues={{
					username: '',
					password: ''
				}}
				validationSchema={AuthFormSchema}
				onSubmit={(
					values
				) => {
					setShowErrorMessage(false);

					mutation.mutate(
						values,
						{
							onSuccess: (result) => {
								const { token, ...user } = result.data;
								storage.set('token', token);
								storage.set('role', user?.role);

								if (user?.role === UserRole.Student) {
									navigate('/student', { replace: true });
								}

								if (user?.role === UserRole.Teacher) {
									navigate('/teacher', { replace: true });
								}

								if (user?.role === UserRole.Parent) {
									navigate('/parent', { replace: true });
								}
							},
							onError: () => {
								setShowErrorMessage(true);
							}
						}
					);
				}}
			>
				{({ values, setFieldValue, errors, touched }) => (
					<Form className={styles.form}>
						<div className={styles.form__field}>
							<Input
								leftIcon="user"
								placeholder="Логин"
								value={values.username}
								onChange={val => setFieldValue('username', val)}
								iconColors="#225fa8"
								hasError={!!errors.username && !!touched.username}
							/>
							{
								errors.username && touched.username &&
								<p className={styles.form__field__error}>
									<ErrorMessage name="username" />
								</p>
							}
						</div>
						<div className={styles.form__field}>
							<Input
								leftIcon="lock"
								placeholder="Пароль"
								type="password"
								value={values.password}
								onChange={val => setFieldValue('password', val)}
								iconColors="#225fa8"
								hasError={!!errors.password && !!touched.password}
							/>
							{
								errors.password && touched.password &&
								<p className={styles.form__field__error}>
									<ErrorMessage name="password" />
								</p>
							}
						</div>

						{
							showErrorMessage &&
							<p className={styles.auth_error}>
								Неверный логин или пароль
							</p>
						}

						<Button
							type="submit"
							stretch
							className={styles.form__control}
							disabled={mutation.isLoading}
						>
							Войти
						</Button>
					</Form>
				)}
			</Formik>
			<Link
				to="password-recovery"
				className={styles.form__control_link}
			>
				Забыли пароль?
			</Link>
		</>
	);
}
