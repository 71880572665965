import React, { ChangeEvent } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import cn from 'classnames';

import { AlfaSelect } from 'app/components/ui/Select';
import { Button, DatePicker, Icon } from 'app/components/ui';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import {
	getClassStudents,
	postMedicalCertificate,
	uploadMedicalCertificateFile,
} from 'app/services/teacherAPI';

import styles from './index.module.sass';

const ReferenceAddingFormSchema = Yup.object().shape({
	student: Yup.string().required(),
	start_at: Yup.date().required(),
	end_at: Yup.date()
		.when('start_at',
			(startAt, schema) => startAt
				&& schema.min(startAt))
		.required(),
	file: Yup.object().shape({
		id: Yup.string().required()
	}),
});

interface ReferenceFormValues {
	student: string;
	start_at: string;
	end_at: string;
	file: {
		id: string;
		file: File
	};
}

interface Props {
	classId: string;
	onCancel: () => void;
	className?: string;
}

export const ReferenceAddingForm = (props: Props) => {
	const { classId, onCancel, className = '', ...restProps } = props;

	const studentsQuery = useQuery(['students', classId], getClassStudents, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!classId
	});

	const students = studentsQuery.data?.map(student => ({
		value: student.id,
		label: `${student.first_name} ${student.last_name}`,
	}));

	const uploadFileMutation = useMutation((file: File) => uploadMedicalCertificateFile(file));
	const saveCertificateMutation = useMutation((data: any) => postMedicalCertificate(data));

	const handleAddFile = (e: ChangeEvent<HTMLInputElement>, setFieldValue: Function) => {
		const { files } = e.target;
		if (!files) {
			return null;
		}
		return uploadFileMutation.mutate(files[0], {
			onSuccess: res => {
				setFieldValue('file', {
					id: res.data?.id,
					file: files[0]
				});
				e.target.value = '';
			},
		});
	};

	const handleSaveFile = (file: File) => FileSaver.saveAs(file as Blob, file?.name ?? 'Справка');

	const initialFormValues: Partial<ReferenceFormValues> = {};

	return (
		<>
			<h3>Новая справка</h3>
			<Formik
				enableReinitialize
				initialValues={initialFormValues}
				validationSchema={ReferenceAddingFormSchema}
				onSubmit={values => {
					saveCertificateMutation.mutate({
						...values,
						file: values.file?.id
					}, {
						onSuccess: () => {
							toast.success('Справка успешно создана');
							onCancel();
						},
						onError: () => {
							toast.error('Произошла ошибка');
						}
					});
				}}
			>
				{({ values, errors, setFieldValue }) => (
					<Form
						{...restProps}
						className={cn(styles.form, className)}
					>
						<section>
							<AlfaSelect
								onChange={option => setFieldValue('student', option.value)}
								options={students ?? []}
								label='Ученик'
								hasError={!!errors?.student}
								className={styles.form__field}
							/>
							<div className={styles.form__row}>
								<DatePicker
									onChange={(value) => setFieldValue('start_at', value)}
									value={values?.start_at}
									label='С'
									hasError={!!errors.start_at}
									placeholder='Выберите дату'
									className={styles.form__field}
								/>
								<DatePicker
									onChange={(value) => setFieldValue('end_at', value)}
									value={values?.end_at}
									label='По'
									hasError={!!errors.end_at}
									placeholder='Выберите дату'
									className={styles.form__field}
								/>
							</div>
							<section>
								{
									values.file ?
										<div className={styles.file}>
											<button
												type='button'
												onClick={() => handleSaveFile(values.file!.file)}
											>
												{values.file?.file?.name}
											</button>
											<button
												type='button'
												onClick={() => setFieldValue('file', null)}
												className={styles.button_delete}
											>
												<Icon icon='trash' />
											</button>
										</div>
										:
										<label
											htmlFor='fileInput'
											className={styles.form__field__upload}
										>
											<Icon icon='clip' size={12}/>
											Добавить файл
											<input
												id='fileInput'
												type='file'
												onChange={e =>
													handleAddFile(e, setFieldValue)}
											/>
										</label>
								}
							</section>
						</section>
						<footer className={styles.form__footer}>
							<section className={styles.form__footer__controls}>
								<Button
									onClick={onCancel}
									theme={ButtonTheme.ghost}
									size={ButtonSize.l}
								>
									Отменить
								</Button>
								<Button
									type='submit'
									size={ButtonSize.l}
								>
									Сохранить
								</Button>
							</section>
						</footer>
					</Form>
				)}
			</Formik>
		</>
	);
}
