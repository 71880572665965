import React from 'react';
import cn from 'classnames';
import ReactPlayer from 'react-player';
import FileSaver from 'file-saver';

import { Button } from 'app/components/ui';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { ModalContainer } from 'app/components/modals/ModalContainer';
import { MediaType } from 'app/models/enums/media-type.enum';
import { File } from 'app/models/file.interface';

import styles from './index.module.sass';

interface Props {
	file: File;
	onClose: () => void;
	className?: string;
}

export const MediaPreviewModal = (props: Props) => {
	const {
		file,
		onClose,
		className,
		...restProps
	} = props;

	const uploadImage = () => {
		FileSaver.saveAs(file?.file as unknown as Blob,
			file?.presentation_name ?? 'Медиа');
	}

	return (
		<ModalContainer
			{...restProps}
			isOpen={!!file}
			onClose={onClose}
		>
			<section className={cn(styles.content, className)}>
				{
					file?.file && (
						file?.type === MediaType.Video ?
							<ReactPlayer
								controls
								url={file.file}
								className={styles.media}
								width='100%'
							/> :
							<img
								className={styles.media}
								alt='preview'
								src={file!.file}
							/>
					)
				}
				<section className={styles.controls}>
					<Button onClick={onClose}
							theme={ButtonTheme.ghost} size={ButtonSize.l}>
						Закрыть
					</Button>
					<Button
						onClick={uploadImage}
						size={ButtonSize.l}
					>
						Скачать
					</Button>
				</section>
			</section>
		</ModalContainer>
	);
}
