import React from 'react';
import { useLocation } from 'react-router-dom';
import { Evaluation } from 'app/components/features';

import styles from './index.module.sass';

export const ParentChildrenEvaluation = () => {
	const childId = useLocation().pathname.split('/')[2];

	return (
		<section className={styles.content}>
			<Evaluation studentId={childId} />
		</section>
	);
}
