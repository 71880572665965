import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import InfiniteScroll from 'react-infinite-scroller';

import { getPersonalChats } from 'app/services/messengerAPI';
import { setUnreadMessagesCount } from 'app/reducers/unreadMessagesCountSlice';
import { PersonalChat } from 'app/models/personl-chat.interface';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

interface Props {
	className?: string;
}

export const Chats = (props: Props) => {
	const { className = '', ...restProps } = props;

	const location = useLocation();
	const dispatch = useDispatch();
	const { id } = useParams();

	const notificationsCount = useSelector((state: any) => state.notificationsCountSlice);

	const limit = 25;
	const [offset, setOffset] = useState(0);
	const [chats, setChats] = useState<Array<PersonalChat>>([]);

	const { data, refetch, isLoading } = useQuery(['personal_chats', limit, offset ], getPersonalChats, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: false,
		cacheTime: 0
	});

	useEffect(() => {
		refetch();
	}, [offset]);

	useEffect(() => {
		if (!data) {
			return;
		}

		if(data?.results?.length > 0) {
			const results = data?.results;

			if (offset === 0) {
				setChats(results);
			} else {
				setChats([...chats, ...results]);
			}
		}
	}, [data]);

	const setChatWatched = (id: number, has_unread_messages: boolean) => {
		if (has_unread_messages) {
			dispatch(setUnreadMessagesCount(notificationsCount > 1 ? notificationsCount - 1 : 0));
		}

		const newChats = chats.map(chat => {
			if (chat.id === id) {
				return { ...chat, has_unread_messages: false };
			}

			return chat;
		});

		setChats(newChats);
	}

	return (
		<section
			{...restProps}
			className={cn(styles.container, className)}
		>
			{
				!isLoading && chats?.length === 0 ?
					<p className={styles.chat_empty}>Чаты отсутствуют</p> :
					<InfiniteScroll
						pageStart={0}
						initialLoad={false}
						loadMore={() => setOffset(offset + limit)}
						hasMore={chats?.length < data?.count!}
						useWindow={false}
					>
						{
							chats?.map((item) => (
								<NavLink
									key={item?.id}
									to={`chats/${item?.id}`}
									onClick={() => setChatWatched(item?.id, item?.has_unread_messages)}
									className={cn(
										styles.chat,
										{[styles.chat_active]: location.pathname.includes('chats') && +id! === item?.id}
									)}
								>
									<img
										src={item?.user?.avatar || avatarDefault}
										className={styles.chat__avatar}
										alt="Аватар"
									/>
									<div className={styles.chat__info}>
										<p className={styles.name}>{item?.user?.name}</p>
										<p className={styles.message}>{item?.last_message_text}</p>
									</div>
									<div className={styles.chat__flags}>
										{
											item?.last_message_date &&
											<p className={styles.time}>
												{DateTime.fromISO(item?.last_message_date).toFormat('dd.MM.yy HH:mm')}
											</p>
										}
										{
											item?.has_unread_messages && <div className={styles.circle} />
										}
									</div>
								</NavLink>
							))
						}
					</InfiniteScroll>
			}
		</section>
	);
}
