import { createSlice } from '@reduxjs/toolkit';

const childrenSlice = createSlice({
	name: 'children',
	initialState: [],
	reducers: {
		setChildren(state, action) {
			return action.payload;
		},
		resetChildren() {
			return [];
		}
	}
});

export default childrenSlice.reducer;
export const { setChildren, resetChildren } = childrenSlice.actions;

