import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { useMutation, useQuery } from 'react-query';
import { DateTime } from 'luxon';
import FileSaver from 'file-saver';
import InfiniteScroll from 'react-infinite-scroller';
import { toast } from 'react-toastify';

import {
	getGroupChatMessages,
	sendGroupChatFileMessage,
	sendGroupChatMessage,
	uploadChatFile,
	removeMessage
} from 'app/services/messengerAPI';
import { Icon } from 'app/components/ui';
import { useAppSelector } from 'app/hooks/reduxHooks';
import { File as UserFile } from 'app/models/file.interface';
import { GroupChatMessage } from 'app/models/group-chat.interface';
import { ConfirmModal } from 'app/components/modals';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

export const GroupChat = () => {
	const { id } = useParams();
	const bottomRef = useRef<HTMLDivElement>(null);

	const user = useAppSelector((state: any) => state.userSlice);

	const [message, setMessage] = useState('');

	const limit = 25;
	const [offset, setOffset] = useState(0);
	const [messages, setMessages] = useState<Array<GroupChatMessage>>([]);
	const [isMessageRemovingOpen, setIsMessageRemovingOpen] = useState(false);
	const [messageForRemove, setMessageForRemove] = useState<number>();

	const { data, refetch } = useQuery(['group_chat_messages', id, limit, offset], getGroupChatMessages, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: false,
		cacheTime: 0
	});

	useEffect(() => {
		refetch();
	}, [offset]);

	useEffect(() => {
		if (offset === 0) {
			refetch();
		} else {
			setOffset(0);
		}
	}, [id]);

	useEffect(() => {
		if (data === undefined) {
			return;
		}

		const results = data?.results;

		if (offset === 0) {
			setMessages(results);
		} else {
			setMessages([...messages, ...results]);
		}
	}, [data]);

	const uploadFileMutation = useMutation((file: File) => uploadChatFile(file));
	const removeMessageMutation = useMutation((id: number) => removeMessage(id));

	const sendMessage = useMutation(
		(value: string) =>
			sendGroupChatMessage(+id!, value)
	);

	const sendFileMessageMutation = useMutation(
		(value: number) =>
			sendGroupChatFileMessage(+id!, value)
	);

	useEffect(() => {
		setTimeout(() => {
			bottomRef.current?.scrollIntoView();
		}, 100);
	}, [messages]);

	const sendMessageHandle = () => {
		sendMessage.mutate(message,{
			onSuccess: () => {
				setMessage('');
				if (offset === 0) {
					refetch();
				} else {
					setOffset(0);
				}
			}
		});
	}

	const addFileHandle = (e: ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		if (!files) {
			return null;
		}

		return uploadFileMutation.mutate(files[0], {
			onSuccess: res => {
				sendFileMessageMutation.mutate(res.data?.id, {
					onSuccess: () => {
						if (offset === 0) {
							refetch();
						} else {
							setOffset(0);
						}
					}
				})
			},
		});
	};

	const uploadFile = (file: UserFile) => {
		FileSaver.saveAs(file?.file as unknown as Blob,
			file?.presentation_name ?? 'file');
	}

	const handleEnterDown = (e: any) => {
		if (e.key === 'Enter') {
			sendMessageHandle();
		}
	}

	const removeMessageHandle = (id: number) => {
		setMessageForRemove(id);
		setIsMessageRemovingOpen(true);
	}

	const removeAlbomAccept = () => {
		setIsMessageRemovingOpen(false);

		if (messageForRemove) {
			removeMessageMutation.mutate(
				messageForRemove,
				{
					onSuccess: () => {
						setMessages(
							messages.filter(message => message?.id !== messageForRemove)
						);

						setMessageForRemove(undefined);

						toast.success('Сообщение удалено');
					},
					onError: () => {
						toast.error('Не удалось удалить сообщение');
					}
				}
			);
		}
	}

	return (
		<>
			<div className={styles.content}>
				<InfiniteScroll
					pageStart={0}
					initialLoad={false}
					loadMore={() => setOffset(offset + limit)}
					hasMore={messages?.length < data?.count!}
					isReverse
					useWindow={false}
				>
					{
						messages?.length! > 0 && [...messages!]?.reverse()?.map((message, index) => (
							<section key={message?.id} className={styles.content__wrapper}>
								{
									(index === 0 ||
									DateTime.fromISO([...messages!]?.reverse()[index - 1]?.sent_at).toFormat('dd.MM.YYY') !==
									DateTime.fromISO(message?.sent_at).toFormat('dd.MM.YYY')) &&
									<h2 className={styles.date}>{DateTime.fromISO(message?.sent_at).toFormat('dd MMMM')}</h2>
								}
								<div
									className={cn(
										styles.message,
										{[styles.message_author]: message?.author?.id === user?.user_id}
									)}
								>
									{
										message?.author?.id !== user?.user_id &&
										<img
											className={styles.message__avatar}
											src={message?.author?.avatar ?? avatarDefault}
											alt='card avatar'
										/>
									}
									<div
										className={cn(
											styles.message__wrapper,
											{[styles.message__wrapper_author]: message?.author?.id === user?.user_id}
										)}
									>
										{
											message?.author?.id !== user?.user_id &&
											<p className={styles.message__caption}>
												{message?.author?.name}
											</p>
										}
										<div className={styles.message__content}>
											{
												message?.file && message?.file?.type === 0 &&
												<button
													onClick={() => uploadFile(message?.file)}
													className={styles.wrapper}
												>
													<img
														src={message?.file?.file}
														alt='message'
														className={styles.image}
													/>
												</button>
											}
											{
												message?.file && message?.file?.type === 2 &&
												<button
													onClick={() => uploadFile(message?.file)}
													className={styles.wrapper}
												>
													<p className={styles.text}>{message?.file?.presentation_name}</p>
												</button>
											}
											<p className={styles.text}>{message?.text}</p>
											<p className={styles.time}>
												{DateTime.fromISO(message?.sent_at).toFormat('HH:mm')}
											</p>
											{
												message?.author?.id === user?.user_id &&
												<button onClick={() => removeMessageHandle(message?.id)}>
													<Icon icon='close' size={16} />
												</button>
											}
										</div>
									</div>
								</div>
								<div ref={bottomRef} />
							</section>
						))
					}
				</InfiniteScroll>
			</div>
			<div className={styles.controls}>
				<label
					htmlFor='fileInput'
					className={styles.controls__file}
				>
					<Icon
						icon='clip'
						size={24}
					/>
					<input
						id='fileInput'
						type='file'
						onChange={e => addFileHandle(e)}
					/>
				</label>
				<input
					value={message}
					onChange={event => setMessage(event.target.value)}
					onKeyDown={handleEnterDown}
					placeholder='Напишите сообщение'
				/>
				<button
					onClick={sendMessageHandle}
					className={styles.controls__send}
				>
					<Icon
						icon='send'
						size={24}
					/>
				</button>
			</div>
			<ConfirmModal
				isOpen={isMessageRemovingOpen}
				onClose={() => setIsMessageRemovingOpen(false)}
				onAccept={removeAlbomAccept}
			>
				<h3>Вы уверены, что хотите <br/> удалить сообщение?</h3>
			</ConfirmModal>
		</>
	);
}
