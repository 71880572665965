import React from 'react';
import { NavLink, useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';

import { Tag } from 'app/components/ui';
import { getMediaArchives } from 'app/services/userAPI';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

export const MediaArchive = () => {
	const [params] = useSearchParams();

	const { data, isLoading } = useQuery(['albums', params.get('yearId')], getMediaArchives, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return (
		<section className={styles.container}>
			{
				data?.map(item =>
					<NavLink
						key={item?.id}
						to={item?.id.toString()}
						className={styles.card}
					>
						<img
							src={item?.preview?.file ?? avatarDefault}
							alt='poster'
							className={styles.cover}
						/>
						<h2 className={styles.title}>{item?.school_class?.name}</h2>
						<Tag>{DateTime.fromISO(item?.date).toFormat('d MMMM yyyy')}</Tag>
						<span className={styles.text}>{item?.description}</span>
					</NavLink>
				)
			}
			{
				!isLoading && data?.length === 0 &&
				<div className={styles.container_empty}>
					<p>Архив отсутствует</p>
				</div>
			}
		</section>
	);
}
