import * as Yup from 'yup';

export const ProfileEditFormSchema = Yup.object().shape({
	email: Yup.string()
		.email('Проверьте правильность почты'),
	first_name: Yup.string().required('Заполните поле'),
	last_name: Yup.string().required('Заполните поле'),
	phone_number: Yup.string().length(12, 'Некорректный номер телефона')
});

export const PHONE_MASK = '{+7} ({9}00) 000-00-00';
