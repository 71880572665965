import React from 'react';
import { ServicesMock } from 'app/mocks/services.mock';

import styles from './index.module.sass';

export const ParentServices = () => (
	<section className={styles.wrapper}>
		<section className={styles.content}>
			<section className={styles.cards}>
				{
					ServicesMock.map(card =>
						<section
							key={card.id}
							className={styles.card}
						>
							<section className={styles.card__content}>
								<h2 className={styles.title}>
									{card.title}
								</h2>
								<p className={styles.description}>
									{card.description}
								</p>
								<p className={styles.price}>Стоимость</p>
								<p className={styles.price__value}>{`${card.price}₽`}</p>
							</section>
							<button className={styles.add}>
								Добавить в козину
							</button>
						</section>
					)
				}
			</section>
		</section>
	</section>
);
