import { createSlice } from '@reduxjs/toolkit';

const unreadMessagesCountSlice = createSlice({
	name: 'unread_messages-count',
	initialState: null,
	reducers: {
		setUnreadMessagesCount(state, action) {
			return action.payload;
		}
	}
});

export default unreadMessagesCountSlice.reducer;
export const { setUnreadMessagesCount } = unreadMessagesCountSlice.actions;
