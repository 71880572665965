import React from 'react';
import { FieldArray, Form, Formik } from 'formik';
import cn from 'classnames';
import { useMutation, useQuery } from 'react-query';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { AlfaSelect } from 'app/components/ui/Select';
import { Button } from 'app/components/ui';
import {
	addSyllabus,
	editSyllabus,
	getCurrentYearClasses,
	getTeacherSubjects
} from 'app/services/teacherAPI';
import { ButtonTheme } from 'app/components/ui/Button';
import { Syllabus } from 'app/models/syllabus.interface';
import { PlanBlock } from './components/PlanBlock';

import styles from './index.module.sass';

const PlanEditFormSchema = Yup.object().shape({
	school_class: Yup.string()
		.required('Заполните поле'),
	subject: Yup.string()
		.required('Заполните поле'),
	data: Yup.array().of(
		Yup.object().shape({
			title: Yup.string().required('Заполните поле'),
			topics: Yup.array().of(
				Yup.object().shape({
					name: Yup.string().required('Заполните поле'),
					planned_date: Yup.string().required('Заполните поле'),
					actual_date: Yup.string().required('Заполните поле')
				})
			)
		})
	)
});

interface Props {
	data: Syllabus;
	onClose: (withRefetch?: boolean) => void;
	className?: string;
}

export const PlanEditForm = (props: Props) => {
	const { data, onClose, className = '', ...restProps } = props;

	const addMutation = useMutation((data: Syllabus) => addSyllabus(data));
	const editMutation = useMutation((data: Syllabus) => editSyllabus(data?.id!, data));

	const { data: classes } = useQuery('teacher-classes', getCurrentYearClasses, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { data: subjects } = useQuery('teacher-subjects', getTeacherSubjects, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return (
		<>
			<h3>{data?.data?.length === 0 ? 'Новый план' : 'Редактирование плана'}</h3>
			<Formik
				enableReinitialize
				initialValues={data}
				validationSchema={PlanEditFormSchema}
				onSubmit={values => {
					if (!data?.id) {
						addMutation.mutate(
							values,
							{
								onSuccess: () => {
									toast.success('Учебный план успешно создан');
									onClose(true);
								},
								onError: () => {
									toast.error('Не удалось создать учебный план');
								}
							}
						);
					} else {
						editMutation.mutate(
							values,
							{
								onSuccess: () => {
									toast.success('Учебный план успешно изменен');
									onClose(true);
								},
								onError: () => {
									toast.error('Не удалось изменить учебный план');
								}
							}
						);
					}

				}}
			>
				{({ values, errors, touched, setFieldValue }) => (
					<Form
						{...restProps}
						className={cn(styles.form, className)}
					>
						<section>
							<div
								className={styles.form__row}
							>
								<AlfaSelect
									value={classes
										?.map(item => ({ value: item?.id, label: item?.name }))
										?.find(item => item?.value === values?.school_class)
									}
									onChange={option => setFieldValue('school_class', option.value)}
									options={classes?.map(item => ({ value: item?.id, label: item?.name })) ?? []}
									label='Класс'
									hasError={!!errors?.school_class && !!touched?.school_class}
									className={styles.control}
								/>

								<AlfaSelect
									value={subjects
										?.map(item => ({ value: item?.id, label: item?.full_name }))
										?.find(item => item?.value === values?.subject)
									}
									onChange={option => setFieldValue('subject', option.value)}
									options={subjects?.map(item => ({ value: item?.id, label: item?.full_name })) ?? []}
									label='Наименование предмета'
									hasError={!!errors?.subject && !!touched?.subject}
									className={styles.control}
								/>
							</div>
							<FieldArray
								name='data'
								component={PlanBlock}
							/>
						</section>
						<footer className={styles.form__footer}>
							<section className={styles.form__footer__controls}>
								<Button
									theme={ButtonTheme.ghost}
									onClick={onClose}
								>
									Отменить
								</Button>
								<Button type='submit'>
									Создать план
								</Button>
							</section>
							{
								false &&
								<span className={styles.info}>
									При закрытии черновик сохраняется автоматически
								</span>
							}
						</footer>
					</Form>
				)}
			</Formik>
		</>
	);
}
