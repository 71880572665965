import axios, { AxiosResponse } from 'axios';
import { QueryFunctionContext } from 'react-query';

import { Student } from 'app/models/student.interface';
import { ScheduleEventListItem } from 'app/models/schedule-event.interface';
import { EventType } from 'app/models/enums/event-type.enum';
import { Parent } from 'app/models/parent.interface';

export const getChildren = async(): Promise<Array<Student>> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/parents/children/`);
	return response?.data;
}

export const getChildClassmates = async({ queryKey }: QueryFunctionContext): Promise<Array<Student>> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/parents/children/${id}/classmates/`);
	return response?.data;
}

export const getChildClassmatesParents = async({ queryKey }: QueryFunctionContext): Promise<Array<Parent>> => {
	const [, id] = queryKey as [string, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/parents/children/${id}/classmates/parents/`);
	return response?.data;
}

export const getChildSchedule = async({ queryKey }: QueryFunctionContext): Promise<Array<ScheduleEventListItem>> => {
	const [, date, eventType, studentId] = queryKey as [string, number, EventType, number];
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/parents/schedule/`, {
		params: {
			date,
			type: eventType,
			student_id: studentId
		}
	});
	return response?.data;
}

export const uploadChildDocument = (data: { id: number, file: File, type: 0 | 1 }): Promise<AxiosResponse> => {
	const formData = new FormData();
	formData.append('file', data.file);
	formData.append('presentation_name', data.file.name);
	formData.append('type', data.type.toString());

	return axios.post(`${process.env.REACT_APP_API_URL}/students/${data.id}/documents/`, formData, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
	);
}
