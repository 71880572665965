import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useMutation, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import FileSaver from 'file-saver';

import { Button, Checkbox } from 'app/components/ui';
import { ConfirmModal, FullSizeFridgeContainer } from 'app/components/modals';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { numberEndings } from 'app/utils/numberEndings';
import avatarDefault from 'assets/images/avatarDefault.png';
import {
	finishTeacherTasks,
	getTeacherTasks,
	removeTeacherTask
} from 'app/services/teacherAPI';
import { TeacherTask } from 'app/models/teacher-task.interface';
import { TaskStatus } from 'app/models/enums/task-status.enum';
import { TaskAddingForm } from './components/TaskAddingForm';

import styles from './index.module.sass';

export const TeacherTasksList = () => {
	const [params] = useSearchParams();

	const limit = 20;
	const [offset, setOffset] = useState(0);
	const [tasks, setTasks] = useState<Array<TeacherTask>>([]);
	const [taskForRemove, setTaskForRemove] = useState<number>();
	const [isTaskAddingOpen, setIsTaskAddingOpen] = useState(false);
	const [isTaskRemovingOpen, setIsTaskRemovingOpen] = useState(false);
	const [allTasksSelected, setAllTasksSelected] = useState(false);
	const [selectedTasks, setSelectedTasks] = useState<Array<number>>([]);

	const { data, refetch } = useQuery(
		['teacher-tasks', limit, offset, params.get('type'), params.get('authored')],
		getTeacherTasks, {
			retry: 0,
			refetchOnWindowFocus: false,
			enabled: false,
			cacheTime: 0
		}
	);

	const finishTasksMutation = useMutation((selectedTasks: number[]) => finishTeacherTasks(selectedTasks));
	const removeTaskMutation = useMutation((taskId: number) => removeTeacherTask(taskId));

	useEffect(() => {
		if (offset === 0) {
			refetch();
		} else {
			setOffset(0);
		}
	}, [params]);

	useEffect(() => {
		refetch();
	}, [offset]);

	useEffect(() => {
		if (!data) {
			return;
		}

		if(data?.results?.length > 0) {
			const results = data?.results;

			if (offset === 0) {
				setTasks(results);
			} else {
				setTasks([...tasks, ...results]);
			}
		} else {
			setTasks([]);
		}
	}, [data]);

	const getNextPage = () => {
		setOffset(offset + limit);
	}

	const onOpenTaskAddingHandle = () => setIsTaskAddingOpen(true);

	const onCloseTaskAddingHandle = (withReload = false) => {
		if (typeof withReload === 'boolean' && withReload) {
			if (offset === 0) {
				refetch();
			} else {
				setOffset(0);
			}
		}

		setIsTaskAddingOpen(false);
	}

	const onTasksSelectionChange = (checked: boolean, id: number) => {
		if (checked) {
			setSelectedTasks([...selectedTasks, id]);
		} else {
			setSelectedTasks(selectedTasks.filter(item => item !== id));
		}

		setAllTasksSelected(false);
	}

	const skipSelectedTasks = () => {
		setSelectedTasks([]);
		setAllTasksSelected(false);
	}

	const finishSelectedTasks = () => {
		if (selectedTasks?.length > 0) {
			finishTasksMutation.mutate(
				selectedTasks,
				{
					onSuccess: () => {
						toast.success('Выбранные задачи завершены');
						setSelectedTasks([]);
						setAllTasksSelected(false);
						if (offset === 0) {
							refetch();
						} else {
							setOffset(0);
						}
					},
					onError: () => {
						toast.error('Не удалось завершить выбранные задачи');
					}
				}
			);
		}
	}

	const setAllTasksSelectedHandle = (flag: boolean) => {
		setAllTasksSelected(flag);
		if (flag) {
			setSelectedTasks(tasks?.filter(task => task?.status === 0)?.map(task => task?.id)!);
		} else {
			setSelectedTasks([]);
		}
	}

	const loadFiles = (task: any) => {
		task?.files?.forEach((item: any) => {
			FileSaver.saveAs(item?.file as unknown as Blob, item?.presentation_name);
		});
	}

	const removeTask = (id: number) => {
		setIsTaskRemovingOpen(true);
		setTaskForRemove(id);
	}

	const removeTaskAccept = () => {
		setIsTaskRemovingOpen(false);

		if (taskForRemove) {
			removeTaskMutation.mutate(
				taskForRemove,
				{
					onSuccess: () => {
						toast.success('Задача удалена');
						setTaskForRemove(undefined);
						if (offset === 0) {
							refetch();
						} else {
							setOffset(0);
						}
					},
					onError: () => {
						toast.error('Не удалось удалить задачу');
					}
				}
			);
		}
	}

	return (
		<section className={styles.content}>
			{
				(selectedTasks.length > 0 || allTasksSelected) &&
				<section
					className={styles.tooltip}
				>
					<div className={styles.tooltip__caption}>
						<Button
							onClick={skipSelectedTasks}
							theme={ButtonTheme.secondary}
							className={styles.button_clear}
						>
							Снять выделение
						</Button>
						{
							allTasksSelected ?
								<p>Выбраны все активные задачи</p> :
								<p>
									Выбрано {selectedTasks.length}
									{numberEndings(selectedTasks.length, [' задача', ' задачи', ' задач'])}
								</p>
						}
					</div>
					<Button
						onClick={finishSelectedTasks}
						theme={ButtonTheme.secondary}
						className={styles.button_finish}
					>
						Завершить задачи
					</Button>
				</section>
			}
			{
				params.get('type') !== 'finished' &&
				<Button
					onClick={onOpenTaskAddingHandle}
					theme={ButtonTheme.secondary}
					size={ButtonSize.l}
					stretch
					className={styles.button_add}
				>
					Новая задача
				</Button>
			}
			{
				tasks?.length > 0 &&
				!!tasks?.find(task =>
					task.status === TaskStatus.Created ||
					task.status === TaskStatus.InProgress
				) &&
				params.get('type') !== 'finished' &&
				<Checkbox
					value={allTasksSelected}
					onChange={event => setAllTasksSelectedHandle(event.target.checked)}
					className={styles.checkbox_all}
				>
					Все задачи
				</Checkbox>
			}
			{
				tasks?.map((task: any) => (
					<div key={`${task?.id}-${Math.random()}`} className={styles.task}>
						{
							task?.status !== 2 &&
							<Checkbox
								value={selectedTasks.includes(task?.id) || allTasksSelected}
								onChange={event => onTasksSelectionChange(event.target.checked, task?.id)}
								className={styles.check}
							/>
						}
						<div className={styles.task__content}>
							<p className={styles.task__name}>{task?.description}</p>
							<div className={styles.task__info}>
								<div className={styles.item}>
									<p className={styles.item__label}>
										исполнитель
									</p>
									<div className={styles.item__data}>
										<img
											src={task?.assigned_to?.avatar ?? avatarDefault}
											alt='аватар'
											className={styles.avatar}
										/>
										<p>{`${task?.assigned_to?.last_name} ${task?.assigned_to?.first_name} ${task?.assigned_to?.patronymic}`}</p>
									</div>
								</div>
								<div className={styles.deadline}>
									<div className={styles.item}>
										<p className={styles.item__label}>
											статус исполнения
										</p>
										{
											task?.status === 0 &&
											<p>Активна</p>
										}
										{
											task?.status === 2 &&
											<p>Завершена</p>
										}
									</div>
									<div className={styles.item}>
										<p className={styles.item__label}>
											крайний срок
										</p>
										<p>{DateTime.fromISO(task?.deadline).toFormat('dd.MM.yyyy')}</p>
									</div>
								</div>
							</div>
						</div>
						<div className={styles.task__controls}>
							<p className={styles.task__controls__date}>
								{task?.date}
							</p>
							<Button
								leftIcon='clip'
								theme={ButtonTheme.ghost}
								size={ButtonSize.s}
								onClick={() => loadFiles(task)}
							>
								Скачать файлы
							</Button>
							{
								task.status === TaskStatus.Created &&
								<Button
									theme={ButtonTheme.clear}
									size={ButtonSize.s}
									stretch
									onClick={() => removeTask(task?.id)}
									className={styles.remove_button}
								>
									Удалить
								</Button>
							}
						</div>
					</div>
				))
			}
			{
				tasks?.length === 0 &&
				<section className={styles.content_empty}>
					<p>Задачи отсутствуют</p>
				</section>
			}
			{
				data?.count! > tasks?.length &&
				<Button
					theme={ButtonTheme.secondary}
					onClick={getNextPage}
					stretch
					className={cn(styles.button_add, styles.button_load)}
				>
					Показать еще
				</Button>
			}
			<FullSizeFridgeContainer
				isOpen={isTaskAddingOpen}
				onClose={onCloseTaskAddingHandle}
			>
				<TaskAddingForm
					onCancel={onCloseTaskAddingHandle}
				/>
			</FullSizeFridgeContainer>
			<ConfirmModal
				isOpen={isTaskRemovingOpen}
				onClose={() => {
					setTaskForRemove(undefined);
					setIsTaskRemovingOpen(false);
				}}
				onAccept={removeTaskAccept}
			>
				<h3>Действительно хотите <br /> удалить задачу?</h3>
			</ConfirmModal>
		</section>
	);
}
