import React, { FormEvent } from 'react';
import cn from 'classnames';

import styles from './index.module.sass';

export enum TextareaSize {
	s,
	m
}

interface Props {
	value: string;
	onChange: (e: FormEvent<HTMLTextAreaElement>) => void;
	label: string;
	name?: string;
	hasError?: boolean;
	className?: string;
	size?: TextareaSize;
}

export const Textarea = (props: Props) => {
	const {
		value,
		onChange = () => {},
		size = TextareaSize.m,
		label,
		name = '',
		hasError = false,
		className = '',
		...restProps
	} = props;

	return (
		<section className={cn(styles.wrapper, className)}>
			{
				label &&
				<label
					id='aria-label'
					htmlFor='textarea'
					className={cn(styles.label,
						{
							[styles.label_s]: size === TextareaSize.s,
							[styles.label_m]: size === TextareaSize.m,
						})
					}
				>
					{label}
				</label>
			}
			<textarea
				{...restProps}
				value={value}
				onChange={onChange}
				aria-labelledby='aria-label'
				id='textarea'
				name={name}
				className={cn(styles.textarea,
					{
						[styles.textarea_s]: size === TextareaSize.s,
						[styles.textarea_m]: size === TextareaSize.m,
					},
					hasError && styles.textarea_error
				)}
			/>
		</section>
	);
}
