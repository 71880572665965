import React, { useState } from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';

import { Button, ButtonTheme } from 'app/components/ui/Button';
import { numberEndings } from 'app/utils/numberEndings';
import { Student } from 'app/models/student.interface';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

interface Props {
	birthdays: Student[];
	className?: string;
}
export const Birthdays = (props: Props) => {
	const { birthdays = [], className, ...restProps } = props;

	const [showAllStudents, setShowAllStudents] = useState(false);

	return (
		<>
			{}
				<section
					{...restProps}
					className={cn(styles.wrapper, className)}
				>
					<h4>Дни рождения</h4>
					<p className={styles.date}>{DateTime.now().toFormat('d MMMM yyyy')}</p>
					{
						birthdays?.length === 0 ?
							<p className={styles.empty}>Дни рождения отсутствуют</p> :
							<section className={styles.students}>
								{
									birthdays?.slice(0, showAllStudents ? undefined : 3)?.map(student => (
										<div key={student?.id} className={styles.students__item}>
											<img
												alt="avatar"
												src={student?.avatar || avatarDefault}
											/>
											<div className={styles.info}>
												<span className={styles.info__line}>
													{student?.first_name} {student?.last_name}
												</span>
												<span
													className={cn(styles.info__line, styles.info__line_class)}
												>
													{student?.school_class?.name}
												</span>
											</div>
											<span className={styles.age}>
												{student?.age} {numberEndings(student?.age, [' год ', ' года ', ' лет '])}
											</span>
										</div>
									))
								}
							</section>
					}
					{
						birthdays?.length! > 3 &&
						<Button
							theme={ButtonTheme.ghost}
							stretch
							onClick={() => setShowAllStudents(!showAllStudents)}
							className={styles.control}
						>
							{showAllStudents ? 'Скрыть' : 'Показать всех'}
						</Button>
					}
				</section>
		</>
	);
}
