import React from 'react';
import { useQuery } from 'react-query';

import { getStudentClassmates } from 'app/services/studentAPI';
import { StudentCard } from 'app/components/features';

import styles from './index.module.sass';

export const StudentClassStudents = () => {
	const { data } = useQuery(['classmates'], getStudentClassmates, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return (
		<section className={styles.content}>
			{
				data?.map((student) => (
					<StudentCard key={Math.random()} disabled student={student} />
				))
			}
		</section>
	);
}
