import React, { ChangeEvent } from 'react';
import { useMutation, useQuery } from 'react-query';
import FileSaver from 'file-saver';
import { useAppSelector } from 'app/hooks/reduxHooks';
import { UserRole } from 'app/models/enums/user-role.enum';
import { Slider } from 'app/components/ui/Slider';
import { DocumentCard } from 'app/components/features';
import { uploadTeacherDocument } from 'app/services/teacherAPI';
import { File as DocumentFIle } from 'app/models/file.interface';
import { getUserDocuments } from 'app/services/userAPI';
import { AvatarChanger } from '../AvatarChanger';
import { TeacherEditForm, ParentEditForm, StudentEditForm } from '../EditForms';

import styles from './index.module.sass';

export const EditProfile = () => {
	const user = useAppSelector((state: any) => state.userSlice);

	const { data: documents, refetch: refetchDocuments } = useQuery(['user_documents', user?.user_id], getUserDocuments, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: user.role === UserRole.Teacher && !!user?.user_id.toString()
	});

	const uploadFileMutation = useMutation((data: { file: File, type: 0 | 1 }) =>
		uploadTeacherDocument({ file: data.file, type: data.type }));

	const addFileHandle = (e: ChangeEvent<HTMLInputElement>, type: 0 | 1) => {
		const { files } = e.target;
		if (!files) {
			return null;
		}

		return uploadFileMutation.mutate({ file: files[0], type}, {
			onSuccess: () => {
				refetchDocuments();
			},
		});
	}

	const uploadFile = (file: DocumentFIle) => {
		FileSaver.saveAs(file?.file as unknown as Blob,
			file?.presentation_name ?? 'document');
	}

	const formByRole = () => {
		switch (user.role) {
			case UserRole.Student:
				return <StudentEditForm />;
			case UserRole.Parent:
				return <ParentEditForm />;
			case UserRole.Teacher:
				return <TeacherEditForm />;
			default:
				return <div />;
		}
	}

	return (
		<section className={styles.content}>
			<h1 className={styles.title}>Профиль</h1>
			<section className={styles.form}>
				{formByRole()}
				<AvatarChanger user={user} />
			</section>
			{
				user.role === UserRole.Teacher &&
				<section className={styles.content__item}>
					<h1>Недавние документы</h1>
					<div className={styles.flex_header}>
						<label
							htmlFor='achieveDocumentInput'
							className={styles.file_control}
						>
							Добавить документ (Достижение)
							<input
								id='achieveDocumentInput'
								type='file'
								accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*'
								onChange={e => addFileHandle(e, 0)}
							/>
						</label>
						<label
							htmlFor='educationFileInput'
							className={styles.file_control}
						>
							Добавить документ (Образование)
							<input
								id='educationFileInput'
								type='file'
								accept='application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*'
								onChange={e => addFileHandle(e, 1)}
							/>
						</label>
					</div>
					{
						documents?.length! > 0 ? (
							<Slider
								withNavigation
								slidesPerView={3}
								className={styles.documents}
							>
								{
									documents?.map((item: any) => (
										<button
											key={item?.id}
											onClick={() => uploadFile(item)}
											className={styles.documents__item}
										>
											<DocumentCard data={item} />
										</button>
									))
								}
							</Slider>
						) : (
							<DocumentCard
								data={undefined}
								className={styles.documents__item}
							/>
						)
					}
				</section>
			}
		</section>
	);
}
