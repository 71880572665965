import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { getChildClassmatesParents } from 'app/services/parentAPI';
import { ParentCard } from 'app/components/features';

import styles from './index.module.sass';

export const ParentClassParents = () => {
	const childId = useLocation().pathname.split('/')[2];

	const { data } = useQuery(['child_classmates_parents', childId], getChildClassmatesParents, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!childId
	});

	return (
		<section className={styles.content}>
			{
				data?.map((parent) => (
					<ParentCard key={Math.random()} parent={parent} />
				))
			}
		</section>
	);
}
