import React, { useState } from 'react';
import { useQuery } from 'react-query';

import { Schedule } from 'app/components/features';
import { getTeacherSchedule } from 'app/services/teacherAPI';
import { EventType } from 'app/models/enums/event-type.enum';

import styles from './index.module.sass';

export const TeacherSchedule = () => {
	const [date, setDate] = useState<string>();
	const [eventType, setEventType] = useState<EventType>(EventType.Lesson);

	const { data } = useQuery(['events', date, eventType], getTeacherSchedule, {
		retry: 0,
		refetchOnWindowFocus: false,
		cacheTime: 0,
		enabled: !!date && !!eventType.toString()
	});

	const onDateChange = (date: string) => {
		setDate(date);
	}

	const onEventTypeChange = (type: EventType) => {
		setEventType(type);
	}

	return (
		<section className={styles.wrapper}>
			<section className={styles.content}>
				<Schedule
					data={data}
					onDateChange={onDateChange}
					onEventTypeChange={onEventTypeChange}
				/>
			</section>
		</section>
	);
}
