import React from 'react';
import { DateTime } from 'luxon';

import { Profile } from 'app/models/profile.interface';
import { Student } from 'app/models/student.interface';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

interface Props {
	data: Profile | Student;
	className?: string;
}

export const StudentInfoDetails = (props: Props) => {
	const { data, className, ...restProps } = props;

	return (
		<section {...restProps} className={className}>
			<h1>Профиль</h1>
			<section className={styles.content}>
				<div className={styles.info}>
					<h3>Общая информация</h3>
					<div className={styles.info__block}>
						<div className={styles.item}>
							<p className={styles.item__label}>Фамилия</p>
							<p className={styles.item__data}>{data?.last_name}</p>
						</div>
						<div className={styles.item}>
							<p className={styles.item__label}>Имя</p>
							<p className={styles.item__data}>{data?.first_name}</p>
						</div>
						<div className={styles.item}>
							<p className={styles.item__label}>Отчество</p>
							<p className={styles.item__data}>{data?.patronymic}</p>
						</div>
						<div className={styles.item}>
							<p className={styles.item__label}>Дата рождения</p>
							<p className={styles.item__data}>{DateTime.fromISO(data?.birth_day!).toFormat('dd.MM.yyyy')}</p>
						</div>
					</div>
					<h3>Контактные данные</h3>
					<div className={styles.info__block}>
						<div className={styles.item}>
							<p className={styles.item__label}>Email</p>
							<p className={styles.item__data}>{'email' in data ? data?.email : '-'}</p>
						</div>
						<div className={styles.item}>
							<p className={styles.item__label}>Телефон</p>
							<p className={styles.item__data}>
								{'phone_number' in data && data?.phone_number ? data?.phone_number : '-'}
							</p>
						</div>
					</div>
					<h3>Родители</h3>
					<div className={styles.info__block}>
						{
							'parents' in data ? data?.parents?.map(item => (
								<div key={item?.id} className={styles.item}>
									<p className={styles.item__data}>{item?.first_name} {item?.last_name}</p>
								</div>
							)) : '-'
						}
					</div>
				</div>
				<div>
					<h3>Фотография</h3>
					<img
						src={data?.avatar ?? avatarDefault}
						alt='student'
						className={styles.avatar}
					/>
				</div>
			</section>
		</section>
	);
}
