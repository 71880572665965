import React from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import { ParentCard } from 'app/components/features';
import { getClassParents } from 'app/services/teacherAPI';

import styles from './index.module.sass';

export const TeacherClassParents = () => {
	const currentClassId = useLocation().pathname.split('/')[3];
	const { data } = useQuery(['students', currentClassId], getClassParents, {
		retry: 0,
		enabled: !!currentClassId
	});

	return (
		<section className={styles.content}>
			{
				data?.map((parent) => (
					<ParentCard key={Math.random()} parent={parent} />
				))
			}
		</section>
	);
}
