import React from 'react';
import cn from 'classnames';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';

import { getUpcomingLessons } from 'app/services/teacherAPI';

import styles from './index.module.sass';

export const UpcomingLessons = () => {
	const { data } = useQuery('upcoming-lessons', getUpcomingLessons, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return (
		<section className={styles.card}>
			<h3>Ближайшие уроки</h3>
			{
				data?.map((lesson, index) => (
					<div
						key={`${lesson?.start_time}_${index}`}
						className={cn(styles.lesson, {[styles.lesson_nearest]: index === 0})}
					>
						<p className={styles.lesson__time}>
							<span>{DateTime.fromISO(lesson?.start_time).toFormat('HH:mm')}</span>
							<br />
							<span>{DateTime.fromISO(lesson?.end_time).toFormat('HH:mm')}</span>
						</p>
						<div className={styles.lesson__content}>
							<p
								className={styles.lesson__content__name}
								style={{color: lesson?.color}}
							>
								{lesson?.name}
							</p>
							<p className={styles.lesson__content__info}>
								<span>{lesson?.subject}</span>
								<br />
								<span>{lesson?.place}</span>
							</p>
						</div>
					</div>
				))
			}
			{
				data?.length === 0 &&
				<p className={styles.empty_message}>Ближайшие уроки отсутствуют</p>
			}
		</section>
	);
}
