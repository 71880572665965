import React from 'react';
import cn from 'classnames';

import styles from './index.module.sass';

interface Props {
	className?: string;
}

export const EmptyMessages = (props: Props) => {
	const { className = '', ...restProps } = props;

	return (
		<p
			{...restProps}
			className={cn(styles.container, className)}
		>
			Выберите чат
		</p>
	);
}
