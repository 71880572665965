import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import styles from './index.module.sass';

interface ButtonGroupItem {
	value: string;
	label: string;
	onClick: () => void;
}

interface Props {
	initialState: string;
	buttons: Array<ButtonGroupItem>;
	className?: string;
	type?: 'primary' | 'secondary';
}

export const ButtonGroup: FC<Props> = (props: Props) => {
	const {
		initialState,
		buttons,
		type = 'primary',
		className
	} = props;

	const [activeButtonId, setActiveButtonId] = useState<string>(initialState);

	useEffect(() => {
		setActiveButtonId(initialState);
	}, [initialState]);

	return (
		<div className={cn(styles.container, className)}>
			{buttons.map((button) => (
				<button
					key={Math.random().toString()}
					onClick={() => {
						setActiveButtonId(`${button.value}`)
						button.onClick();
					}}
					className={cn(styles.button, {
						[styles.button_active]: activeButtonId === `${button.value}`,
						[styles.button_active_primary]: activeButtonId === `${button.value}` && type === 'primary',
						[styles.button_active_secondary]: activeButtonId === `${button.value}` && type === 'secondary',
					})}
				>
					{button.label}
				</button>
			))}
		</div>
	)
}
