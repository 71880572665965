import React, { useState } from 'react';
import cn from 'classnames';
import { Icon } from 'app/components/ui/Icon';
import { StudentsList } from 'app/pages/teacher/Classes/components/StudentsList';
import { numberEndings } from 'app/utils/numberEndings';
import { Class } from 'app/models/class.interface';

import classAvatar from 'assets/images/classAvatar.png';
import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

interface Props {
	classes?: Array<Class>;
	className?: string;
}
export const ClassesList = (props: Props) => {
	const { classes = [], className } = props;

	const [studentMenuOpen, setStudentMenuOpen] = useState(false);
	const [selectedClass, setSelectedClass] = useState<Class>();

	const onStudentMenuOpen = (item: Class) => {
		setSelectedClass(item);
		setStudentMenuOpen(true);
	}

	const onStudentMenuClose = () => {
		setSelectedClass(undefined);
		setStudentMenuOpen(false);
	}

	return (
		<section className={cn(styles.content, className)}>
			{
				classes?.length > 0 && classes?.map((item) => (
					<button
						key={item.id}
						onClick={() => onStudentMenuOpen(item)}
						className={cn(styles.card, {[styles.card_my_class]: item?.is_form_master})}
					>
						<div className={styles.caption}>
							<img
								src={classAvatar}
								className={styles.caption__avatar}
								alt='class avatar'
							/>
							<h3>{item?.name}</h3>
						</div>
						{
							item?.is_form_master &&
							<p className={styles.my_class_label}>Классное руководство</p>
						}
						{
							!item?.is_form_master &&
							<div className={styles.subjects}>
								{
									item?.subjects?.map((subject) => (
										<span
											key={subject?.id}
											className={styles.subjects__item}
										>
										{subject?.full_name}
									</span>
									))
								}
							</div>
						}
						<div className={styles.info}>
							<p>
								<Icon
									icon='heart'
								/>
								<span>
									{item?.students_count}
									{numberEndings(item?.students_count, [' ученик', ' ученика', ' учеников'])}
								</span>
							</p>
							<p>
								<Icon
									icon='two-user'
								/>
								<span>
									{item?.parents_count}
									{numberEndings(item?.parents_count, [' родитель', ' родителя', ' родителей'])}
								</span>
							</p>
							<p>
								<Icon
									icon='location'
								/>
								<span>{item?.school}</span>
							</p>
						</div>
						{
							!!item?.form_master &&
							<div className={styles.teacher}>
								<img
									src={item?.form_master?.avatar || avatarDefault}
									className={styles.teacher__avatar}
									alt='teacher avatar'
								/>
								<div>
									<p className={styles.teacher__name}>
										{item?.form_master?.last_name} {item?.form_master?.first_name}
										<br/>
										{item?.form_master?.patronymic}
									</p>
									<span className={styles.teacher__role}>
									Классный руководитель
								</span>
								</div>
							</div>
						}
						{
							!!item?.tutor &&
							<div className={styles.teacher}>
								<img
									src={item?.tutor?.avatar || avatarDefault}
									className={styles.teacher__avatar}
									alt='tutor avatar'
								/>
								<div>
									<p className={styles.teacher__name}>
										{item?.tutor?.last_name} {item?.tutor?.first_name}
										<br/>
										{item?.tutor?.patronymic}
									</p>
									<span className={styles.teacher__role}>
									Тьютор
								</span>
								</div>
							</div>
						}
					</button>
				))
			}
			<StudentsList
				selectedClass={selectedClass!}
				isOpen={studentMenuOpen}
				onClose={onStudentMenuClose}
			/>
		</section>
	);
}
