import React from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { useQuery } from 'react-query';
import FileSaver from 'file-saver';

import {
	DocumentCard,
	LessonCard,
	EventCard,
	StudentScores,
	StudentTasks
} from 'app/components/features';
import { Slider } from 'app/components/ui/Slider';
import {
	getStudentAverageScores,
	getStudentNearestEvents,
	getStudentNearestLessons,
	getStudentNearestTasks
} from 'app/services/studentAPI';
import { getUserDocuments } from 'app/services/userAPI';
import { File } from 'app/models/file.interface';

import calendar from 'assets/images/calendar.png';
import chart from 'assets/images/chart.png';
import book from 'assets/images/book.png';
import green from 'assets/images/green.png';
import sun from 'assets/images/sun.png';
import task from 'assets/images/task.png';

import styles from './index.module.sass';

export const MyStudentInfo = () => {
	const user = useSelector((state: any) => state.userSlice);

	const { data: nearest_lessons } = useQuery(['nearest_lessons', user?.user_id], getStudentNearestLessons, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!user?.user_id.toString()
	});

	const { data: nearest_events } = useQuery(['nearest_events', user?.user_id], getStudentNearestEvents, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!user?.user_id.toString()
	});

	const { data: tasks } = useQuery(['nearest_tasks', user?.user_id], getStudentNearestTasks, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!user?.user_id.toString()
	});

	const { data: average_scores } = useQuery(['average_scores', user?.user_id], getStudentAverageScores, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!user?.user_id.toString()
	});

	const { data: documents } = useQuery(['user_documents', user?.user_id], getUserDocuments, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!user?.user_id.toString()
	});

	const uploadFile = (file: File) => {
		FileSaver.saveAs(file?.file as unknown as Blob,
			file?.presentation_name ?? 'document');
	}

	return (
		<section className={styles.content}>
			<div className={styles.cover}>
				<h1>{DateTime.now().toFormat('cccc, dd MMMM, HH:mm')}</h1>
				<img
					src={sun}
					alt=''
					className={styles.sun}
				/>
			</div>
			<img
				src={green}
				alt=''
				className={styles.green}
			/>
			<section className={cn(styles.content__item, styles.content__item_first, styles.content__item_flex)}>
				<div className={styles.block}>
					<h1>Сейчас</h1>
					<LessonCard
						lesson={nearest_lessons && nearest_lessons[0]}
						className={styles.block__content}
					/>
				</div>
				<div className={styles.block}>
					<div className={styles.block__caption}>
						<h1>Далее</h1>
					</div>
					<LessonCard
						lesson={nearest_lessons && nearest_lessons[1]}
						className={styles.block__content}
					/>
				</div>
			</section>
			<section className={styles.content__item}>
				<h1>Ближайшие мероприятия</h1>
				<img
					src={calendar}
					alt=''
					className={cn(styles.sticker, styles.sticker_event)}
				/>
				{
					nearest_events?.length! > 0 ? (
						<Slider
							withNavigation
							slidesPerView={2}
							className={styles.block__content}
						>
							{
								nearest_events?.map(event => (
									<EventCard
										key={event?.id}
										event={event}
									/>
								))
							}
						</Slider>
					) : (
						<EventCard
							event={null}
							className={styles.block__content}
						/>
					)
				}
			</section>
			<section className={styles.content__item}>
				<h1>Задачи</h1>
				<img
					src={task}
					alt=''
					className={cn(styles.sticker, styles.sticker_task)}
				/>
				<StudentTasks tasks={tasks} />
			</section>
			<section className={styles.content__item}>
				<h1>Средние баллы</h1>
				<img
					src={chart}
					alt=''
					className={cn(styles.sticker, styles.sticker_score)}
				/>
				<StudentScores
					scores={average_scores!}
					className={styles.block__content}
				/>
			</section>
			<section className={styles.content__item}>
				<h1>Недавние документы</h1>
				<img
					src={book}
					alt=''
					className={cn(styles.sticker, styles.sticker_document)}
				/>
				{
					documents?.length! > 0 ? (
						<Slider
							withNavigation
							slidesPerView={3}
							className={styles.block__content}
						>
							{
								documents?.map(item => (
									<button
										key={item?.id}
										onClick={() => uploadFile(item)}
										className={styles.block}
									>
										<DocumentCard data={item} />
									</button>
								))
							}
						</Slider>
					) : (
						<DocumentCard
							data={undefined}
							className={styles.block__content}
						/>
					)
				}
			</section>
		</section>
	);
}
