import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
	name: 'user',
	initialState: {},
	reducers: {
		setCurrentUser(state, action) {
			return action.payload;
		},
		updateAvatar(state, action) {
			return {
				...state,
				avatar: action.payload
			}
		},
		resetCurrentUser() {
			return {};
		}
	}
});

export default userSlice.reducer;
export const { setCurrentUser, updateAvatar, resetCurrentUser } = userSlice.actions;
