import React, { ChangeEvent } from 'react';
import { Form, Formik } from 'formik';
import cn from 'classnames';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { useParams, useSearchParams } from 'react-router-dom';

import {
	Button,
	DatePicker,
	AlfaSelect,
	Input,
	Icon
} from 'app/components/ui';
import { ButtonTheme } from 'app/components/ui/Button';
import {
	addMediaArchive,
	editMediaArchive,
	getArchiveYearClasses,
	getCurrentYearClasses,
	removeMediaArchiveFile,
	uploadMediaArchiveFile
} from 'app/services/teacherAPI';

import styles from './index.module.sass';

const TaskAddingFormSchema = Yup.object().shape({
	school_class: Yup.number()
		.required('Заполните поле'),
	description: Yup.string()
		.required('Заполните поле'),
	date: Yup.string()
		.required('Заполните поле'),
});

interface Props {
	onCancel: (withReload?: boolean) => void;
	data?: {
		school_class: number,
		description: string,
		date: string,
		preview?: { id: number, file: string }
	};
	className?: string;
}

export const AlbomAddingForm = (props: Props) => {
	const { onCancel, data, className = '', ...restProps } = props;

	const urlParams = useParams();
	const [params] = useSearchParams();

	const { data: classes } = useQuery(
		['classes', params.get('yearId')],
		params.get('yearId') ? getArchiveYearClasses : getCurrentYearClasses,
		{
			retry: 0,
			refetchOnWindowFocus: false,
		}
	);

	const addMutation = useMutation((data: {
		school_class: number,
		description: string,
		date: string,
		preview: number
	}) => addMediaArchive(params.get('yearId') ? { ...data, studying_year: +params.get('yearId')! } : data));

	const editMutation = useMutation((data: {
		school_class: number,
		description: string,
		date: string,
		preview: number
	}) => editMediaArchive(+urlParams.id!, params.get('yearId') ? { ...data, studying_year: +params.get('yearId')! } : data));

	const uploadFileMutation = useMutation((file: File) => uploadMediaArchiveFile(file));
	const deleteMutation = useMutation((id: number) => removeMediaArchiveFile(id));

	const handleAddFile = (
		e: ChangeEvent<HTMLInputElement>,
		setFieldValue: Function
	) => {
		const { files } = e.target;
		if (!files) {
			return null;
		}
		return uploadFileMutation.mutate(files[0], {
			onSuccess: res => {
				setFieldValue('preview', res.data);

				e.target.value = '';
			},
		});
	};

	const removeFile = (
		id: number,
		setFieldValue: Function
	) => {
		deleteMutation.mutate(id,{
			onSuccess: () => {
				setFieldValue(
					'preview',
					null
				);
			}
		});
	}

	return (
		<>
			<h3>{data ? 'Альбом' : 'Новый альбом'}</h3>
			<Formik
				enableReinitialize
				validationSchema={TaskAddingFormSchema}
				initialValues={data || {
					school_class: null,
					description: '',
					date: '',
					preview: null as unknown as { id: number, file: string }
				}}
				onSubmit={values => {
					if (!data) {
						// @ts-ignore
						addMutation.mutate(values?.preview?.id ? { ...values, preview: values?.preview?.id } : values,
							{
								onSuccess: () => {
									toast.success('Альбом успешно создан');
									onCancel(true);
								},
								onError: () =>{
									toast.error('Не удалось создать альбом');
								}
							}
						);
					} else {
						// @ts-ignore
						editMutation.mutate(values?.preview?.id ? { ...values, preview: values?.preview?.id } : values,
							{
								onSuccess: () => {
									toast.success('Альбом успешно обновлен');
									onCancel(true);
								},
								onError: () =>{
									toast.error('Не удалось обновить альбом');
								}
							}
						);
					}
				}}
			>
				{({ values, setFieldValue }) => (
					<Form
						{...restProps}
						className={cn(styles.form, className)}
					>
						<section>
							<AlfaSelect<number>
								value={{value: values.school_class!, label:  classes?.find(item => item?.id === values.school_class)?.name!}}
								onChange={option => setFieldValue('school_class', option.value)}
								options={classes?.map(item => ({ value: item?.id, label: item?.name })) ?? []}
								label='Класс'
								className={cn(styles.control)}
							/>
							<Input
								label='Наименование альбома'
								value={values.description}
								onChange={value => setFieldValue('description', value)}
								className={cn(styles.control)}
							/>
							<DatePicker
								value={values.date}
								onChange={value => setFieldValue('date', value)}
								label='Дата проведенного мероприятия'
								placeholder='Выберите дату'
								className={cn(styles.control, styles.control_datepicker)}
							/>
							<div>
								<p className={styles.preview__label}>Обложка</p>
								{
									values.preview?.id ?
										<div className={styles.preview_container}>
											<img
												src={values.preview?.file}
												alt=''
												className={styles.preview}
											/>
											<button onClick={() => removeFile(values.preview?.id!, setFieldValue)}>
												<Icon icon='close' size={24} />
											</button>
										</div> :
										<label
											htmlFor='fileInput'
											className={styles.upload}
										>
											<Icon icon='upload-photo' size={46}/>
											Выберите файл
											<input
												id='fileInput'
												type='file'
												onChange={e =>
													handleAddFile(e, setFieldValue)}
											/>
										</label>
								}
							</div>
						</section>
						<footer className={styles.form__footer}>
							<section className={styles.form__footer__controls}>
								<Button
									onClick={onCancel}
									theme={ButtonTheme.ghost}
								>
									Отменить
								</Button>
								<Button type='submit'>
									{data ? 'Обновить альбом' : 'Создать альбом'}
								</Button>
							</section>
						</footer>
					</Form>
				)}
			</Formik>
		</>
	);
}
