import React, { useEffect, useState } from 'react';
import { Button, Icon } from 'app/components/ui';
import { ServicesMock } from 'app/mocks/services.mock';

import styles from './index.module.sass';

export const ParentCart = () => {
	const [services, setServices] = useState(ServicesMock);
	const [result, setResult] = useState(0);

	const deleteService = (id: number) => setServices(prev => prev.filter(v => v.id !== id));
	const order = () => console.log('Оформить');

	useEffect(() => {
		const newPrice = services
			.map(v => v.price)
			.reduce((acc, cur) => acc + cur, 0);

		setResult(newPrice);
	}, [services]);

	return (
		<section className={styles.wrapper}>
			<section className={styles.content}>
				<h1>Корзина</h1>
				<section className={styles.blocks}>
					<section className={styles.cards}>
						{
							services.map(card =>
								<section
									key={card.id}
									className={styles.card}
								>
									<button
										onClick={() => deleteService(card.id)}
										className={styles.delete}
									>
										<Icon icon='trash' />
									</button>

									<section className={styles.data}>
										<h2>{card.title}</h2>
										<p className={styles.data__description}>{card.description}</p>
									</section>

									<h2 className={styles.price}>{`${card.price}₽`}</h2>
								</section>
							)
						}
					</section>
					<aside className={styles.aside}>
						<span className={styles.aside__title}>Общая сумма</span>
						<p className={styles.price}>{`${result}₽`}</p>
						<Button
							onClick={order}
							className={styles.order}
							stretch
						>
							Оформить
						</Button>
					</aside>
				</section>
			</section>
		</section>
	);
}
