import React from 'react';
import cn from 'classnames';

import { Button, ButtonSize, ButtonTheme } from 'app/components/ui/Button';

import styles from './index.module.sass';

interface Props {
	handleReset: () => void;
	isDirty: boolean;
	isValid: boolean;
	className?: string;
}

export const ChangesButtonGroup = (props: Props) => {
	const { handleReset, isDirty, isValid, className, ...restProps } = props;

	return (
		<section
			{...restProps}
			className={cn(styles.wrapper, className)}
		>
			<Button
				onClick={handleReset}
				disabled={!isDirty}
				theme={ButtonTheme.ghost}
				size={ButtonSize.l}
			>
				Сбросить изменения
			</Button>

			<Button
				type='submit'
				disabled={!isValid}
				size={ButtonSize.l}
			>
				Сохранить
			</Button>
		</section>
	);
}
