import { createSlice } from '@reduxjs/toolkit';

const tasksCountSlice = createSlice({
	name: 'tasks-count',
	initialState: null,
	reducers: {
		setTasksCount(state, action) {
			return action.payload;
		}
	}
});

export default tasksCountSlice.reducer;
export const { setTasksCount } = tasksCountSlice.actions;

