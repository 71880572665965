import React from 'react';

import { StudentInfoDetails } from 'app/components/features';
import { Student } from 'app/models/student.interface';

interface Props {
	student: Student;
}
export const TeacherStudentInfoDetails = (props: Props) => {
	const { student } = props;

	return (
		<StudentInfoDetails data={student!} />
	);
}
