import React from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import cn from 'classnames';

import { Icon } from 'app/components/ui/Icon';
import { getClassInfo } from 'app/services/teacherAPI';

import styles from './index.module.sass';

export const TeacherClass = () => {
	const currentClassId = useLocation().pathname.split('/')[3];

	const { data } = useQuery(['current_class', currentClassId], getClassInfo, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!currentClassId
	});

	return (
		<section className={styles.wrapper}>
			<aside className={styles.navigation}>
				<p className={styles.title}>{data?.name}</p>
				<nav>
					<ul>
						<li>
							<NavLink
								to='evaluation'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='evaluation-color'
									size={24}
									className={styles.link__icon}
								/>
								Оценивание
							</NavLink>
						</li>
						<li>
							<NavLink
								to='schedule'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='schedule-color'
									size={24}
									className={styles.link__icon}
								/>
								Расписание
							</NavLink>
						</li>
						<li>
							<NavLink
								to='students'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='students-color'
									size={24}
									className={styles.link__icon}
								/>
								Ученики
							</NavLink>
						</li>
						<li>
							<NavLink
								to='parents'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='parents-color'
									size={24}
									className={styles.link__icon}
								/>
								Родители
							</NavLink>
						</li>
						<li>
							<NavLink
								to='skips'
								className={({ isActive }) =>  cn(styles.link, styles.link_skips, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='skip'
									size={24}
									className={styles.link__icon}
								/>
								Пропуски
							</NavLink>
						</li>
						<li>
							<NavLink
								to='tasks'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='work'
									size={24}
									className={styles.link__icon}
								/>
								Задачи
							</NavLink>
						</li>
					</ul>
				</nav>
			</aside>
			<Outlet />
		</section>
	);
}
