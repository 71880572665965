import { ComponentType, FC } from 'react';
import { Navigate } from 'react-router-dom';

import { StorageService } from 'app/services/storageService';
import { UserRole } from 'app/models/enums/user-role.enum';

interface Props {
	component: ComponentType;
}

export const PublicRoute: FC<Props> = ({ component: RouteComponent }) => {
	const storage = StorageService.getInstance();

	const role = +storage.get('role');

	if (!storage.token) {
		return <RouteComponent />;
	}

	if (role === UserRole.Student) {
		return <Navigate to='/student' />;
	}

	if (role === UserRole.Teacher) {
		return <Navigate to='/teacher' />;
	}

	if (role === UserRole.Parent) {
		return <Navigate to='/parent' />;
	}

	return <Navigate to='/' />;
}
