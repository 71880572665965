import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { Header } from 'app/components/ui/Header';
import { StorageService } from 'app/services/storageService';
import { getUserInfo, getUserUnreadMessagesCount } from 'app/services/userAPI';
import { getChildren } from 'app/services/parentAPI';
import { setCurrentUser } from 'app/reducers/userSlice';
import { setCurrentChild } from 'app/reducers/childSlice';
import { setChildren } from 'app/reducers/childrenSlice';
import { UserRole } from 'app/models/enums/user-role.enum';
import { setUnreadMessagesCount } from 'app/reducers/unreadMessagesCountSlice';

import styles from './index.module.sass';

export const Layout = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useDispatch();
	const storage = StorageService.getInstance();

	const storeUser = useSelector((state: any) => state.userSlice);

	const { data: user } = useQuery(['user', storage.token], getUserInfo, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!location.pathname
	});

	const { data: unread_messages } = useQuery(['unread_messages', storage.token], getUserUnreadMessagesCount, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!location.pathname,
		cacheTime: 0
	});

	const { data: children } = useQuery([`children-${storeUser?.user_id}`, user?.role], getChildren, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: user?.role === UserRole.Parent
	});

	useEffect(() => {
		if (user) {
			dispatch(setCurrentUser(user));
		}
	}, [user]);

	useEffect(() => {
		if (unread_messages !== undefined) {
			dispatch(setUnreadMessagesCount(unread_messages));
		}
	}, [unread_messages]);

	useEffect(() => {
		if (children) {
			dispatch(setChildren(children));
			dispatch(setCurrentChild(
			children?.find(item => item?.id === +location.pathname.split('/')[2]) ?? children[0]
			));

			if (location.pathname === '/parent') {
				navigate(`${children[0]?.id}`);
			}
		}
	}, [children]);

	return (
		<div className={styles.wrapper}>
			{
				!!storeUser && Object.keys(storeUser).length !== 0 &&
				<>
					<Header />
					<section className={styles.content}>
						<Outlet />
					</section>
				</>
			}
		</div>
	);
}
