import React, { Fragment, Ref, useState } from 'react';
import { useQuery } from 'react-query';
import cn from 'classnames';
import { Tooltip } from 'react-tooltip';

import { getGradesTypes } from 'app/services/schoolAPI';
import { isGrade } from 'app/utils/checkIsGrade';
import { AbsenceMark, Grade } from 'app/models/student-rating.model';
import { EvaluateType } from 'app/components/features/TeacherEvaluation/utils';
import EvaluationTag from '../EvaluationTag';

import styles from './index.module.sass';

interface Props {
	isOpen?: boolean;
	onClick?: () => void;
	anchorId: string;
	tooltipRef?: Ref<HTMLButtonElement>;
	data: Grade | Grade[] | AbsenceMark;
	className?: string;
}

const EvaluationDetail = (props: Props) => {
	const {
		anchorId,
		data,
		onClick,
		className,
		tooltipRef,
		...restProps
	} = props;

	const { data: grades } = useQuery('types', getGradesTypes, {
		retry: 0,
		refetchOnWindowFocus: false,
	});
	
	const [currentEvaluate, setCurrentEvaluate] = useState(
		Array.isArray(data) ? data[0] : data);

	const handleChangeEvaluate = (value: Grade) => {
		setCurrentEvaluate(value)
	};
	
	const getFields = (data: EvaluateType) => isGrade(data) ?
		[
			{
				title: 'тема урока',
				value: data.topic
			},
			{
				title: 'тип оценки',
				value: grades?.find(grade => grade.id === data.type)?.name ?? ''
			},
			{
				title: 'оценка',
				value: data.score
			},
			{
				title: 'комментарий',
				value: data.comment
			}
		] :
		[
			{
				title: 'причина',
				value: data.has_medical_reason ? 'По болезни' : 'Другая причина(Н)'
			},
			{
				title: 'комментарий',
				value: data.comment
			},
		];

	return (
		<Tooltip
			{...restProps}
			className={cn(styles.container, className)}
			anchorSelect={`[id='${anchorId}']`}
			delayShow={100}
			delayHide={50}
			style={{
				width: '300px',
				background: '#225fa8',
				opacity: '0.9',
				boxShadow: '0px 14px 64px rgba(0, 0, 0, 0.25)',
				borderRadius: '8px',
				padding: '16px',
				zIndex: '999'
			}}
			clickable
			place='bottom'
			events={[]}
			noArrow
		>
			<button
				ref={tooltipRef}
				onClick={onClick}
				className={styles.content}
			>
				{
					Array.isArray(data) && data.length > 1 &&
					<div className={styles.switcher}>
						{
							data.map(evaluate =>
								<button
									onClick={(e) => {
										e.stopPropagation();
										handleChangeEvaluate(evaluate);
									}}
									key={evaluate.id}
								>
									<EvaluationTag value={evaluate.score} />
								</button>
							)
						}
					</div>
				}
				{
					getFields(currentEvaluate).map(field => field.value &&
						<Fragment key={field.title}>
							<span className={styles.field__title}>
								{field.title}
							</span>
							<span className={styles.field__value}>
								{field.value}
							</span>
						</Fragment>
					)
				}
			</button>
		</Tooltip>
	);
}

export default React.memo(EvaluationDetail);
