import React, { ChangeEvent, FC } from 'react';
import cn from 'classnames';

import styles from './index.module.sass';

interface Props {
	value: boolean,
	onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
	name?: string,
	type?: 'default' | 'circle',
	className?: string,
	disabled?: boolean,
}

export const Checkbox: FC<Props> = (props) => {
	const {
		value,
		onChange = () => {},
		name = '',
		type = 'default',
		className = '',
		disabled,
		children,
		...restProps
	} = props;

	return (
		// eslint-disable-next-line jsx-a11y/label-has-associated-control
		<label className={cn(styles.check, className)}>
			<input
				{...restProps}
				className={cn(styles.check__input)}
				type='checkbox'
				name={name}
				checked={value}
				onChange={onChange}
				disabled={disabled}
			/>
			<span className={
				cn(
					styles.check__box,
					{[styles.check__box_circle]: type === 'circle'},
					{[styles.check__box_with_content]: !!children},
					{[styles.check__box_disabled]: disabled}
				)}
			/>
			{children}
		</label>
	);
};
