import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import cn from 'classnames';

import { useAppSelector } from 'app/hooks/reduxHooks';
import { getUserNotifications } from 'app/services/userAPI';
import { UserRole } from 'app/models/enums/user-role.enum';
import { Notification } from 'app/models/notifications.interface';
import { NotificationType } from 'app/models/enums/notification-type.enum';
import { ButtonTheme } from 'app/components/ui/Button';
import { Button, Icon } from 'app/components/ui';

import styles from './index.module.sass';

export const Notifications = () => {
	const location = useLocation();
	const user = useAppSelector((state: any) => state.userSlice);

	const limit = 10;
	const [offset, setOffset] = useState(0);
	const [notifications, setNotifications] = useState<{
		date: string;
		notifications: Notification[];
	}[]>([]);

	const [notificationType, setNotificationType] = useState('');

	const { data, refetch } = useQuery(['notifications', limit, offset, notificationType], getUserNotifications, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: false,
		cacheTime: 0
	});

	const isNotParent = user.role !== UserRole.Teacher;

	const links = [
		{
			type: '',
			title: 'Все уведомления',
			icon: 'notification',
		},
		{
			type: NotificationType.Class,
			title: 'Класс',
			icon: 'students',
		},
		{
			type: NotificationType.Event,
			title: 'По мероприятиям',
			icon: 'events',
		},
		isNotParent &&
		{
			type: NotificationType.Tasks,
			title: 'Задачи',
			icon: 'evaluation',
		},
		{
			type: NotificationType.Schedule,
			title: 'По расписанию',
			icon: 'schedule',
		},
	].filter(Boolean) as Array<{title: string, icon: string, type: string | number}>;

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		setNotificationType(params.get('type') ?? '');
		if (offset === 0) {
			refetch();
		} else {
			setOffset(0);
		}
	}, [location]);

	useEffect(() => {
		refetch();
	}, [offset]);

	useEffect(() => {
		if (!data) {
			return;
		}

		if(data?.results?.length > 0) {
			const results = data?.results;

			if (offset === 0) {
				setNotifications(results);
			} else {
				setNotifications([...notifications, ...results]);
			}
		} else {
			setNotifications([]);
		}
	}, [data]);

	const getNotificationIcon = (eventType: NotificationType) => {
		switch (eventType) {
			case NotificationType.Event:
				return 'evaluation';
			case NotificationType.Schedule:
				return 'schedule';
			case NotificationType.Class:
				return 'students';
			default:
				return 'notification';
		}
	}

	const getNextPage = () => {
		setOffset(offset + limit);
	}

	return (
		<section className={styles.wrapper}>
			<aside className={styles.navigation}>
				<nav>
					<ul>
						{
							links?.map(link =>
								<li key={link.title}>
									<NavLink
										to={`?type=${link.type}`}
										className={
										cn(
											styles.link, styles.link_archive, {
												[styles.link_active]: notificationType === link.type.toString()
											}
										)}
									>
										<Icon
											icon={link.icon}
											size={24}
											className={styles.link__icon}
										/>
										{link.title}
									</NavLink>
								</li>
							)
						}
					</ul>
				</nav>
			</aside>

			<section className={styles.content}>
				<h1 className={styles.title}>Все уведомления</h1>
				{
					notifications?.map(data =>
						<section
							key={data.date}
							className={styles.notification}
						>
							<h2 className={styles.notification__date}>{DateTime.fromISO(data.date).toFormat('dd MMMM')}</h2>
							{
								data?.notifications?.map(notification =>
									<section
										key={notification.text}
										className={cn(
											styles.event,
											!notification.is_received && styles.event_active
										)}
									>
										<Icon icon={getNotificationIcon(notification.type)} />
										<p className={styles.event__text}>{notification.text}</p>
									</section>
								)
							}
						</section>
					)
				}
				{
					notifications?.length === 0 &&
					<div className={styles.notification_empty}>
						<p>Уведомления отсутствуют</p>
					</div>
				}
				{
					data?.count! > notifications?.length &&
					<Button
						theme={ButtonTheme.secondary}
						onClick={getNextPage}
						stretch
						className={styles.button_add}
					>
						Показать еще
					</Button>
				}
			</section>
		</section>
	);
}
