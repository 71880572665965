import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { ErrorMessage, Form, Formik } from 'formik';
import { toast } from 'react-toastify';

import { Input, InputMask } from 'app/components/ui';
import {
	getTeacherProfileData,
	updateTeacherProfileData
} from 'app/services/profileAPI';
import { convertDateFormat, dateFormat, isoFormat } from 'app/utils/convertDateFormat';
import { Teacher } from 'app/models/profile.interface';
import { useAppSelector } from 'app/hooks/reduxHooks';

import { PHONE_MASK, ProfileEditFormSchema } from '../common';
import { ChangesButtonGroup } from '../ChangesButtonGroup';

import styles from '../index.module.sass';

export const TeacherEditForm = () => {
	const numberMask = /^\d+$/;

	const user = useAppSelector((state: any) => state.userSlice);

	const { data } = useQuery(['profile'], getTeacherProfileData, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!user?.user_id.toString()
	});

	const mutation = useMutation((profile: Teacher) => updateTeacherProfileData(profile, data?.id ?? ''));

	return (
		<Formik
			enableReinitialize
			initialValues={{
				...data,
				school_address: data?.address,
				birth_day: convertDateFormat(data?.birth_day ?? '', isoFormat, dateFormat)
			}}
			validationSchema={ProfileEditFormSchema}
			onSubmit={(values) => {
				mutation.mutate({
					...values,
					school_address: data?.address,
					birth_day: convertDateFormat(values.birth_day ?? '', dateFormat, isoFormat)
				} as Teacher, {
					onSuccess: () => {
						toast.success('Данные профиля успешно обновлены');
					},
					onError: () => {
						toast.error('Не удалось обновить данные профиля');
					}
				});
			}}
		>
			{({
				values,
				setFieldValue,
				errors,
				touched,
				isValid,
				dirty,
				handleReset
			}) => (
				<Form className={styles.form} noValidate>
					<h3 className={styles.form__subtitle}>
						Общая информация
					</h3>
					<div className={styles.form__field}>
						<Input
							disabled
							label='Фамилия'
							value={values.last_name}
							onChange={val => setFieldValue('last_name', val)}
						/>
						{
							errors.last_name && touched.last_name &&
							<p className={styles.form__field__error}>
								<ErrorMessage name='last_name' />
							</p>
						}
					</div>
					<div className={styles.form__field}>
						<Input
							disabled
							label='Имя'
							value={values.first_name}
							onChange={val => setFieldValue('first_name', val)}
						/>
						{
							errors.first_name && touched.first_name &&
							<p className={styles.form__field__error}>
								<ErrorMessage name='first_name' />
							</p>
						}
					</div>
					<Input
						disabled
						label='Отчество'
						value={values.patronymic}
						onChange={val => setFieldValue('patronymic', val)}
						className={styles.form__field}
					/>
					<InputMask
						disabled
						label='Дата рождения'
						mask={Date}
						value={values.birth_day}
						onMaskChange={val => setFieldValue('birth_day', val)}
						className={styles.form__field}
					/>
					<h3 className={styles.form__subtitle}>
						Контактные данные
					</h3>
					<div className={styles.form__field}>
						<Input
							label='Email'
							value={values.email}
							onChange={val => setFieldValue('email', val)}
							withClear
						/>
						{
							errors.email && touched.email &&
							<p className={styles.form__field__error}>
								<ErrorMessage name='email' />
							</p>
						}
					</div>
					<div className={styles.form__field}>
						<InputMask
							disabled
							mask={PHONE_MASK}
							label='Телефон'
							value={values.phone_number}
							onMaskChange={val => setFieldValue('phone_number', val)}
							withClear
						/>

						{
							errors.phone_number && touched.phone_number &&
							<p className={styles.form__field__error}>
								<ErrorMessage name='phone_number' />
							</p>
						}
					</div>
					<h3 className={styles.form__subtitle}>
						Профессиональная деятельность
					</h3>
					<Input
						label='Должность'
						value={values.job_title}
						onChange={val => setFieldValue('job_title', val)}
						className={styles.form__field}
					/>
					<Input
						label='Образование'
						value={values.education}
						onChange={val => setFieldValue('education', val)}
						className={styles.form__field}
					/>
					<InputMask
						mask={numberMask}
						label='Опыт работы'
						value={values.work_experience?.toString()}
						onMaskChange={val => setFieldValue('work_experience', val ? +val : '')}
						className={styles.form__field}
					/>
					<Input
						label='Навыки и достижения'
						value={values.skills_and_achievements}
						onChange={val => setFieldValue('skills_and_achievements', val)}
						className={styles.form__field}
					/>
					<Input
						label='Публикации'
						value={values.publications}
						onChange={val => setFieldValue('publications', val)}
						className={styles.form__field}
					/>
					<Input
						disabled
						label='Адрес'
						value={values.school_address}
						onChange={val => setFieldValue('school_address', val)}
						className={styles.form__field}
					/>
					<ChangesButtonGroup
						handleReset={handleReset}
						isValid={isValid}
						isDirty={dirty}
					/>
				</Form>
			)}
		</Formik>
	);
}
