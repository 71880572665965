import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useQuery } from 'react-query';

import { FridgeContainer } from 'app/components/modals';
import { getClassStudents } from 'app/services/teacherAPI';
import { Class } from 'app/models/class.interface';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

interface Props {
	selectedClass: Class;
	isOpen: boolean;
	onClose: () => void;
	className?: string;
}

export const StudentsList = (props: Props) => {
	const { selectedClass, isOpen, onClose, className } = props;

	const { data, refetch } = useQuery(['students', selectedClass?.id], getClassStudents, {
		retry: 0,
		enabled: false
	});

	useEffect(() => {
		if (selectedClass?.id) {
			refetch();
		}
	}, [selectedClass]);

	return (
		<FridgeContainer
			isOpen={isOpen}
			onClose={onClose}
			className={className}
		>
			<section className={styles.container}>
				<NavLink
					to={`../${selectedClass?.id}`}
					className={styles.navigation}
				>
					<span className={styles.navigation__class_name}>
						{selectedClass?.name}
					</span>
					<span className={styles.navigation__label}>
						Перейти на страницу класса
					</span>
				</NavLink>
				{
					data?.map((student) => (
						<div
							key={Math.random()}
							className={styles.student}
						>
							<img
								src={student?.avatar || avatarDefault}
								className={styles.student__avatar}
								alt='student avatar'
							/>
							<div>
								<span className={styles.student__last_name}>
									{student?.last_name}
								</span>
								<span className={styles.student__name}>
									{student?.first_name}
								</span>
							</div>
						</div>
					))
				}
			</section>
		</FridgeContainer>
	);
}
