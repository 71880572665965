import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { useSelector } from 'react-redux';

import { AbsenceMark, Grade } from 'app/models/student-rating.model';
import EvaluationTag
	from 'app/components/features/Evaluation/components/EvaluationTag';
import EvaluationDetail
	from 'app/components/features/Evaluation/components/EvaluationDetail';
import {
	EvaluateType,
	EvaluationPropertiesContext
} from 'app/components/features/TeacherEvaluation/utils';
import EvaluationDetailEdit from '../EvaluationDetailEdit';

import styles from './index.module.sass';

interface Props {
	id: string;
	values?: AbsenceMark | Grade[];
	openEvaluateId: string;
	handleDetail: (v: string | null) => void;
	onChangeEvaluate: (v: EvaluateType) => void;
	onDeleteEvaluate: (v: EvaluateType) => void;
	date: DateTime;
	className?: string;
}

const EvaluationCell = (props: Props) => {
	const {
		id,
		values,
		openEvaluateId,
		handleDetail = () => {},
		onChangeEvaluate = () => {},
		onDeleteEvaluate = () => {},
		date,
		className,
		...restProps
	} = props;

	const user = useSelector((state: any) => state.userSlice);

	const { tooltipRef } = useContext(EvaluationPropertiesContext);

	const [daysFromNow, setDaysFromNow] = useState(0);
	const [currentEvaluate, setCurrentEvaluate] = useState<EvaluateType | null>(null);

	// TODO daysFromNow > 7 || daysFromNow < 0 - условие для выставления оценок сегодня и за 6 дней до этого
	const canEditCell = useMemo(() => user?.profile === 0 && (!(daysFromNow < 0)), [daysFromNow]);

	useEffect(() => setDaysFromNow(Math.round(DateTime.now().diff(date, ['days']).days)), [date]);

	useEffect(() => {
		if(!values) {
			setCurrentEvaluate(null);
			return;
		}
		setCurrentEvaluate(Array.isArray(values) ? values[0] : values);
	}, [values]);

	return (
		<section
			className={cn(styles.container, className)}
		>
			<button
				id={id}
				style={{
					cursor: canEditCell ? 'pointer' : 'auto'
				}}
				className={cn(
					styles.cell,
					!canEditCell && styles.cell_disabled,
					openEvaluateId === id && styles.cell_active
				)
			}
				onClick={() => handleDetail(openEvaluateId === id ? null : id)}
				{...restProps}
			>
				{
					!currentEvaluate &&
					<div
						className={
						cn(
							{
								[styles.tag__empty]: true,
								[styles.tag__empty_active]: canEditCell && openEvaluateId === id
							},
						)}
					/>
				}
				{
					values && Array.isArray(values) &&
					<EvaluationTag
						isWide
						value={values?.map(v => (v as Grade).score)}
						className={styles.tag__evaluation}
					/>
				}
				{
					values && !Array.isArray(values) &&
					<span className={styles.tag__absence}>
						{
							values.has_medical_reason ? 'Б' : 'Н'
						}
					</span>
				}
			</button>
			<EvaluationDetailEdit
				anchorId={id}
				isOpen={canEditCell && openEvaluateId === id}
				onChange={(v) => onChangeEvaluate(v)}
				onDelete={v => onDeleteEvaluate(v)}
				onClose={() => handleDetail(null)}
				data={values}
			/>
			{
				values &&
				<EvaluationDetail
					isOpen={!canEditCell && openEvaluateId === id}
					anchorId={id}
					tooltipRef={tooltipRef}
					onClick={() => handleDetail(null)}
					data={values}
				/>
			}
		</section>
	);
}

export default React.memo(EvaluationCell);
