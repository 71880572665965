import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import cn from 'classnames';
import { Semester } from 'app/models/semester.interface';
import { AlfaSelect, Button, ButtonGroup, Icon } from 'app/components/ui';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { EvaluationFilters } from 'app/components/features/TeacherEvaluation/utils';

import styles from './index.module.sass';

interface Props {
	filter: EvaluationFilters;
	handleFilter: (v: EvaluationFilters) => void;
	subjects: {
		id: number;
		full_name: string;
	}[];
	semesters: Semester[];
	className?: string;
}

const TeacherEvaluationHeader = (props: Props) => {
	const {
		filter,
		handleFilter,
		subjects,
		semesters,
		className,
		...restProps
	} = props;

	const { currentTime, subject } = filter;

	const getPreviousMonth = () => {
		handleFilter({
			...filter,
			currentTime: currentTime.plus({ month: -1 }),
		});
	};

	const getNextMonth = () => handleFilter({
		...filter,
		currentTime: currentTime.plus({ month: 1 }),
	});

	const changeSubject = (subject: { value: number, label: string }) => handleFilter({
		...filter,
		subject
	});

	const changeSemester = (semester: Semester) => handleFilter({
		...filter,
		semester
	});

	const subjectOptions = () => subjects?.map(subject => ({
			value: subject.id,
			label: subject.full_name
		})) ?? [];

	const createButtons = () => semesters?.map(semester => ({
		value: `${semester.id}`,
		label: semester.name,
		onClick: () => changeSemester(semester),
	})) ?? [];

	const buttons: { onClick: () => void; label: string; value: string }[] = createButtons();

	const enableNextMonth = useMemo(() => {
		if(!filter.semester) {
			return true;
		}
		const semesterEndMonth = DateTime.fromISO(filter.semester?.end_at).month;
		return semesterEndMonth > filter.currentTime.month;
	}, [filter.semester?.end_at, filter.currentTime]);

	const enablePrevMonth = useMemo(() => {
		if(!filter.semester) {
			return true;
		}
		const semesterStartMonth = DateTime.fromISO(filter.semester?.start_at).month;
		return semesterStartMonth < filter.currentTime.month;
	}, [filter.semester?.start_at, filter.currentTime]);

	return (
		<section
			{...restProps}
			className={cn(styles.container, className)}
		>
			<AlfaSelect
				value={subject ?? subjectOptions()[0]}
				options={subjectOptions()}
				onChange={changeSubject}
				className={styles.subject}
			/>
			<section className={styles.controls}>
				{
					filter.semester?.id &&
					<ButtonGroup
						buttons={buttons}
						initialState={filter.semester.id.toString()}
						className={styles.switcher}
					/>
				}
				<Button
					onClick={getPreviousMonth}
					disabled={!enablePrevMonth}
					className={styles.button}
					theme={ButtonTheme.ghost}
					size={ButtonSize.s}
				>
					<Icon
						icon='arrow-left'
						size={24}
					/>
				</Button>
				<Button
					onClick={getNextMonth}
					disabled={!enableNextMonth}
					className={styles.button}
					theme={ButtonTheme.ghost}
					size={ButtonSize.s}
				>
					<Icon
						icon='arrow-right'
						size={24}
					/>
				</Button>
			</section>
		</section>
	);
};

export default React.memo(TeacherEvaluationHeader);
