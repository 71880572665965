import { ReactNode, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {
	Navigation,
	Pagination,
	Swiper as SwiperType,
	SwiperOptions
} from 'swiper';
import { SwiperModule } from 'swiper/types';
import cn from 'classnames';

import { Icon } from 'app/components/ui';

import styles from './index.module.sass';

interface Props {
	children: ReactNode[] | ReactNode;
	slidesPerView?: number;
	withNavigation?: boolean;
	sliderOptions?: Partial<SwiperOptions>;
	className?: string;
}

export const Slider = ({
		children = [],
		slidesPerView = 3,
		sliderOptions,
		withNavigation,
		className
	}: Props
) => {
	const swiperRef = useRef<SwiperType>();

	const swiperModules =
		[Pagination, withNavigation && Navigation]
			.filter(Boolean) as SwiperModule[];

	const handlePrevSlide = () => swiperRef.current?.slidePrev();
	const handleNextSlide = () => swiperRef.current?.slideNext();

	return (
		<div className={cn(styles.wrapper, className)}>
			{
				withNavigation &&
				Array.isArray(children) &&
				children?.length > slidesPerView &&
				<div className={styles.navigation__container}>
					<button
						onClick={handlePrevSlide}
						className={cn(styles.navigation, styles.navigation__prev)}
					>
						<Icon icon='arrow-long' size={24} />
					</button>
					<button
						onClick={handleNextSlide}
						className={cn(styles.navigation, styles.navigation__next)}
					>
						<Icon icon='arrow-long' size={24} />
					</button>
				</div>
			}
			<Swiper
				loop={Array.isArray(children) && children?.length >= slidesPerView * 2}
				pagination={{
					clickable: false,
					horizontalClass: styles.pagination,
					bulletClass: styles.dot,
					bulletActiveClass: styles.dot_active,
				}}
				modules={swiperModules}
				onBeforeInit={(swiper) => {
					swiperRef.current = swiper;
				}}
				spaceBetween={24}
				slidesPerView={slidesPerView}
				{...sliderOptions}
				className={className}
			>
				{
					Array.isArray(children) ?
						children.map((slide, index) =>
							<SwiperSlide key={index}>
								{slide}
							</SwiperSlide>
						) : children
				}
			</Swiper>
		</div>
	);
}
