import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import cn from 'classnames';

import { useOnClickOutside } from 'app/hooks';
import { getStudentEvaluation } from 'app/services/studentAPI';
import { getCurrentSemester, getSemesters } from 'app/services/schoolAPI';
import { StudentDetailGrades } from 'app/models/student-rating.model';
import { EvaluationHeader } from './components/Header';
import { EvaluationAlert } from './components/Alert';
import EvaluationDetail from './components/EvaluationDetail';
import EvaluationTag from './components/EvaluationTag';
import { MIN_CORRECT_LENGTH_IN_ROWS } from './utils';

import styles from './index.module.sass';

interface Props {
	studentId: string;
	className?: string;
}

export const Evaluation = ({ studentId, className = '', ...restProps }: Partial<Props>) => {
	const tableColumns = ['Предметы', 'Оценки', 'Пропуски', 'Ср.балл', 'Оценка'];

	const tooltip = useRef<HTMLButtonElement>(null);

	const [currentEvaluations, setCurrentEvaluations] = useState<StudentDetailGrades[]>([]);
	const [activeSemester, setActiveSemester] = useState<number | null>(null);
	const [openEvaluateId, setOpenEvaluateId] = useState<string | null>(null);

	const { data: semesters } = useQuery('semesters', getSemesters, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { data: curSemester } = useQuery('current_semester', getCurrentSemester, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { data: evaluations } = useQuery(['evaluations', activeSemester, studentId], getStudentEvaluation, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!studentId && !!activeSemester
	});

	useEffect(() => {
		if(!semesters?.length) {
			return;
		}
		setActiveSemester(curSemester?.id ?? semesters[0].id);
	}, [curSemester, semesters]);

	useEffect(() => {
		const emptyStudentEvaluate = {
			name: '',
			grades: [],
			average_score: 0,
			final_score: 0,
			absence_total: 0,
			absence_with_medical_certificate: 0
		};

		setCurrentEvaluations(() => {
			let evaluationTemplate = new Array(evaluations?.length ?? MIN_CORRECT_LENGTH_IN_ROWS);

			if(evaluations) {
				evaluationTemplate = [...evaluations];
			}

			for (let i = evaluations?.length ?? 0; i < MIN_CORRECT_LENGTH_IN_ROWS; i += 1) {
				evaluationTemplate[i] = emptyStudentEvaluate;
			}
			return [...evaluationTemplate];
		});
	}, [evaluations]);

	useOnClickOutside(tooltip, () => {
		setOpenEvaluateId(null);
	});

	const handleDetail = (evaluationId: string) =>
		setOpenEvaluateId(evaluationId === openEvaluateId ? null : evaluationId);

	return (
		<>
			{
				semesters && activeSemester &&
				<EvaluationHeader
					semesters={semesters}
					initialValue={activeSemester}
					onChangeType={setActiveSemester}
				/>
			}
			<section {...restProps} className={cn(styles.table, className)}>
				<section className={styles.table__head}>
					{
						tableColumns.map(columnName => (
							<p key={columnName} className={styles.column__head}>
								{columnName}
							</p>
						))
					}
				</section>
				<section className={styles.table__body}>
					{
						currentEvaluations?.map((evaluate, index) =>
							<Fragment key={`evaluate-${index}`}>
								<p className={styles.cell__subject}>{evaluate.name}</p>
								<section className={styles.cell__evaluations}>
									{
										evaluate?.grades?.map(grade => {
											const id = grade.id.toString();
											return (
												<Fragment key={id}>
													<button
														id={id}
														ref={tooltip}
														onClick={() => handleDetail(id)}
													>
														<EvaluationTag value={grade.score} />
													</button>
													<EvaluationDetail
														anchorId={id}
														onClick={() => handleDetail(id)}
														data={grade}
													/>
												</Fragment>
											)
										})
									}
								</section>
								{
									index === 0 &&
									<EvaluationAlert
										className={styles.alert}
										column_number={3}
										length={currentEvaluations.length}
									>
										Всего пропусков
									</EvaluationAlert>
								}
								<section className={styles.cell__skip}>
									<span>{evaluate.absence_total ?? 0}</span>
									<hr/>
									<span>{evaluate.absence_with_medical_certificate ?? 0}</span>
								</section>
								{
									index === 0 &&
									<EvaluationAlert
										className={styles.alert}
										column_number={5}
										length={currentEvaluations.length}
									>
										По болезни
									</EvaluationAlert>
								}
								<section className={styles.cell__result}>
									<EvaluationTag value={evaluate.average_score} />
								</section>
								<section className={styles.cell__result}>
									<EvaluationTag value={evaluate.final_score} />
								</section>
							</Fragment>
						)}
				</section>
			</section>
		</>
	);
}
