import axios, { AxiosResponse } from 'axios';

import { User } from 'app/models/user.interface';
import { Parent, Profile, Teacher } from 'app/models/profile.interface';

const AVATAR_ENDPOINT = `${process.env.REACT_APP_API_URL}/users/avatar/`;

export const getTeacherProfileData = async(): Promise<Teacher> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/teachers/profile/`);
	return response?.data;
}

export const updateTeacherProfileData = (data: Teacher, id: string) => axios.put(`${process.env.REACT_APP_API_URL}/teachers/${id}/`, data);

export const getParentProfileData = async(): Promise<Parent> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/parents/profile/`);
	return response?.data;
}

export const updateParentProfileData = (data: Parent, id: string) => axios.put(`${process.env.REACT_APP_API_URL}/parents/${id}/`, data);

export const getStudentProfileData = async(): Promise<Profile> => {
	const response = await axios.get(`${process.env.REACT_APP_API_URL}/students/profile/`);
	return response?.data;
}

export const updateStudentProfileData = (data: Profile, id: string) => axios.put(`${process.env.REACT_APP_API_URL}/students/${id}/`, data);

export const changeProfileAvatar = (file: File): Promise<AxiosResponse<User>> => {
	const formData = new FormData();
	formData.append('avatar', file);

	return axios.post(AVATAR_ENDPOINT, formData, {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
	);
};

export const removeProfileAvatar = () => axios.delete(AVATAR_ENDPOINT);
