import React from 'react';
import { useQuery } from 'react-query';

import { getStudentProfileData } from 'app/services/profileAPI';

import { StudentInfoDetails } from 'app/components/features';

export const InfoDetails = () => {
	const { data } = useQuery('profile', getStudentProfileData, {
		retry: 0,
		refetchOnWindowFocus: false
	});

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{
				data && <StudentInfoDetails data={data!} />
			}
		</>
	);
}
