import React, { FC, ReactNode } from 'react';
import Modal, { Styles } from 'react-modal';
import cn from 'classnames';
import { Icon } from 'app/components//ui/Icon';

import styles from './index.module.sass';

interface Props {
	isOpen: boolean;
	onClose?: () => void;
	children: ReactNode;
	config?: Partial<Modal.Props>;
	hasCloseButton?: boolean;
	className?: string;
}

const customStyles: Partial<Styles> = {
	content: {
		bottom: '0',
		left: 'auto',
		right: '0',
		inset: '0 0 0 auto',
		width: 'calc(100vw - 256px)',
		height: '100vh',
		border: 'none',
		background: 'transparent',
		overflow: 'unset',
		padding: '0',
	},
	overlay: {
		background: 'rgba(34, 95, 168, 0.8)',
	}
};

export const FullSizeFridgeContainer: FC<Props> = (props) => {
	const { isOpen, onClose, children, config = {}, className } = props;

	const newConfig: Partial<Modal.Props> = {
		...config,
	};

	return (
		<Modal
			onRequestClose={onClose}
			isOpen={isOpen}
			{...newConfig}
			style={customStyles}
			shouldCloseOnOverlayClick={false}
			ariaHideApp={false}
			className={cn(styles.modal, className)}
		>
			<section className={styles.container}>
				<button onClick={onClose} className={styles.close}>
					<Icon
						icon='close'
					/>
				</button>
				<section className={styles.content}>
					{children}
				</section>
			</section>

		</Modal>
	);
}
