import React, { useEffect, useMemo, useRef, useState } from 'react';
import { DateTime } from 'luxon';
import Calendar from 'react-calendar';

import { Button, ButtonGroup } from 'app/components/ui';
import { Icon } from 'app/components/ui/Icon';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { useOnClickOutside } from 'app/hooks';
import { EventType } from 'app/models/enums/event-type.enum';
import { ScheduleEventListItem } from 'app/models/schedule-event.interface';
import { ScheduleHeader } from './components/Header';

import styles from './index.module.sass';

interface Props {
	data?: Array<ScheduleEventListItem>;
	onDateChange?: (date: string) => void;
	onEventTypeChange?: (type: EventType) => void;
	forStudent?: boolean;
	className?: string;
}

export const Schedule = (props: Props) => {
	const {
		data = [],
		onDateChange = () => {},
		onEventTypeChange = () => {},
		forStudent = false,
		className
	} = props;

	const [currentDate, setCurrentDate] = useState(DateTime.now());

	const buttons = [
		{
			value: 'lessons',
			label: 'Уроки',
			onClick: () => onEventTypeChange(EventType.Lesson)
		},
		{
			value: 'circles',
			label: 'Кружки',
			onClick: () => onEventTypeChange(EventType.Circle)
		}
	];

	const calendar = useRef<HTMLDivElement>(null);

	const [calendarOpen, setCalendarOpen] = useState(false);

	useOnClickOutside(calendar, () => {
		setCalendarOpen(false);
	});

	useEffect(() => {
		onDateChange(currentDate.toFormat('yyyy-MM-dd'));
	}, [currentDate]);

	const startWeekDay = useMemo(() => currentDate.startOf('week').toFormat('d MMMM'), [currentDate]);
	const endWeekDay = useMemo(() => currentDate.endOf('week').toFormat('d MMMM'), [currentDate]);

	const getPreviousWeek = () => {
		setCurrentDate(currentDate.plus({ days: -7 }));
	}

	const getNextWeek = () => {
		setCurrentDate(currentDate.plus({ days: 7 }));
	}

	const onChangeCalendarHandle = (date: any) => {
		setCurrentDate(DateTime.fromJSDate(date));
		setCalendarOpen(false);
	}

	return (
		<section className={className}>
			<section className={styles.header}>
				<div className={styles.header__title}>
					<h1>{startWeekDay} – {endWeekDay}</h1>
					<div ref={calendar} className={styles.calendar__container}>
						<Button
							theme={ButtonTheme.ghost}
							size={ButtonSize.s}
							onClick={() => setCalendarOpen(!calendarOpen)}
							className={styles.button}
						>
							<Icon
								icon='schedule'
								size={24}
							/>
						</Button>
						{
							calendarOpen &&
							<div className={styles.calendar}>
								<Calendar onChange={(value) => onChangeCalendarHandle(value)} value={currentDate.toJSDate()} />
							</div>
						}
					</div>
				</div>
				<div className={styles.header__controls}>
					<ButtonGroup
						buttons={buttons}
						initialState='lessons'
						className={styles.switcher}
					/>
					<Button
						theme={ButtonTheme.ghost}
						size={ButtonSize.s}
						onClick={getPreviousWeek}
						className={styles.button}
					>
						<Icon
							icon='arrow-left'
							size={24}
						/>
					</Button>
					<Button
						theme={ButtonTheme.ghost}
						size={ButtonSize.s}
						onClick={getNextWeek}
						className={styles.button}
					>
						<Icon
							icon='arrow-right'
							size={24}
						/>
					</Button>
				</div>
			</section>
			<ScheduleHeader dayOfWeekStart={currentDate.startOf('week')} className={styles.days} />
			<section className={styles.content}>
				{
					data?.slice(0, -1)?.map((item) => (
						<div key={item?.date} className={styles.content__column}>
							{
								item?.events?.map((event) => (
									<div key={event?.start_time} className={styles.event}>
										<p className={styles.event__time}>
											{DateTime.fromISO(event?.start_time).toFormat('HH:mm ')}
											-
											{DateTime.fromISO(event?.end_time).toFormat(' HH:mm')}
										</p>
										<p
											className={styles.event__name}
											style={{ color: event?.color}}
										>
											{event?.name}
										</p>
										{
											!forStudent &&
											<p className={styles.event__info}>
												{event?.subject}
											</p>
										}
										<p className={styles.event__info}>
											{event?.place}
										</p>
										{
											event?.school &&
											<p className={styles.event__school}>
												({event?.school})
											</p>
										}
										{
											forStudent &&
											<p className={styles.event__teacher}>
												{event?.teacher}
											</p>
										}
									</div>
								))
							}
						</div>
					))
				}
			</section>
		</section>
	);
}
