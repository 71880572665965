import React from 'react';

import { FridgeContainer } from 'app/components/modals';

import styles from './index.module.sass';

interface Props {
	chatId: number;
	isOpen: boolean;
	onClose: () => void;
	className?: string;
}

export const MediaList = (props: Props) => {
	const { chatId, isOpen, onClose, className } = props;

	return (
		<FridgeContainer
			isOpen={isOpen}
			onClose={onClose}
			className={className}
		>
			<section className={styles.container}>
				{chatId}
			</section>
		</FridgeContainer>
	);
}
