import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';

import { Icon } from 'app/components/ui/Icon';
import { UserRole } from 'app/models/enums/user-role.enum';
import { User } from 'app/models/user.interface';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

interface Props {
	user: User;
	className?: string;
}

export const UserMenu = (props: Props) => {
	const { user, className, ...restProps } = props;
	
	const getRole = (role: UserRole) => {
		switch (role) {
			case UserRole.Student:
				return 'Ученик';
			case UserRole.Teacher:
				if (user?.profile === 0) {
					return 'Учитель';
				}
				if (user?.profile === 1) {
					return 'Тьютор';
				}
				if (user?.profile === 2) {
					return 'Специалист';
				}
				return '';
			case UserRole.Parent:
				return 'Родитель';
			default:
				return '';
		}
	}

	return (
		<NavLink
			{...restProps}
			to='profile'
			className={cn(styles.wrapper, className)}
		>
			<p className={styles.user}>
				<span
					className={styles.user__name}>{user?.last_name} {user?.first_name}
				</span>
				<span className={styles.user__role}>
					{getRole(user?.role)}
				</span>
			</p>
			<div className={styles.info}>
				<img
					alt="avatar"
					src={user?.avatar || avatarDefault}
				/>
				<Icon
					icon="arrow"
					className={styles.arrow}
				/>
			</div>
		</NavLink>
	);
}
