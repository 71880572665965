import { createSlice } from '@reduxjs/toolkit';

const childSlice = createSlice({
	name: 'child',
	initialState: {},
	reducers: {
		setCurrentChild(state, action) {
			return action.payload;
		},
		resetChild() {
			return {};
		}
	}
});

export default childSlice.reducer;
export const { setCurrentChild, resetChild } = childSlice.actions;

