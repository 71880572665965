export interface UserInfo {
	username: string;
	email: string;
}

export class StorageService {
	// eslint-disable-next-line no-use-before-define
	protected static instance: StorageService;

	set token(token: string) {
		this.set('token', token);
	}

	get token(): string {
		return this.get('token');
	}

	// eslint-disable-next-line class-methods-use-this
	remove(key: string) {
		localStorage.removeItem(key);
	}

	// eslint-disable-next-line class-methods-use-this
	set(key: string, value: any) {
		localStorage.setItem(key, value);
	}

	// eslint-disable-next-line class-methods-use-this
	get(key: string): any {
		return localStorage.getItem(key);
	}

	logout() {
		this.remove('user');
		this.remove('role');
		this.remove('token');
	}

	static getInstance(): StorageService {
		if (!StorageService.instance) {
			StorageService.instance = new StorageService();
		}
		return StorageService.instance;
	}
}
