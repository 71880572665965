import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from 'app/hooks/reduxHooks';
import { ButtonGroup } from 'app/components/ui';
import { UserRole } from 'app/models/enums/user-role.enum';

import { Groups } from './components/Groups';

import styles from './index.module.sass';
import { Contacts } from './components/Contacts';
import { Chats } from './components/Chats';

enum MessengerListType {
	CHATS,
	GROUPS,
	CONTACTS
}

export const MessengerPage = () => {
	const location = useLocation();

	const user = useAppSelector((state: any) => state.userSlice);

	const [messengerListType, setMessengerListType] = useState(MessengerListType.CHATS);
	const [initialState, setInitialState] = useState('chats');

	const filters = user?.role === UserRole.Teacher ? [
		{
			value: 'chats',
			label: 'Чаты',
			onClick: () => {
				setMessengerListType(MessengerListType.CHATS);
				setInitialState(filters[0].value);
			}
		},
		{
			value: 'groups',
			label: 'Группы',
			onClick: () => {
				setMessengerListType(MessengerListType.GROUPS);
				setInitialState(filters[1].value);
			}
		},
		{
			value: 'contacts',
			label: 'Контакты',
			onClick: () => {
				setMessengerListType(MessengerListType.CONTACTS);
				setInitialState(filters[2].value);
			}
		}
	] : [
		{
			value: 'chats',
			label: 'Чаты',
			onClick: () => {
				setMessengerListType(MessengerListType.CHATS);
				setInitialState(filters[0].value);
			}
		},
		{
			value: 'groups',
			label: 'Группы',
			onClick: () => {
				setMessengerListType(MessengerListType.GROUPS);
				setInitialState(filters[1].value);
			}
		}
	];

	useEffect(() => {
		if (location.pathname.includes('chats')) {
			setMessengerListType(MessengerListType.CHATS);
			setInitialState(filters[0].value);
		}

		if (location.pathname.includes('groups')) {
			setMessengerListType(MessengerListType.GROUPS);
			setInitialState(filters[1].value);
		}
	}, [location]);

	return (
		<section className={styles.wrapper}>
			<aside>
				<ButtonGroup
					buttons={filters}
					initialState={initialState}
					className={styles.filters}
				/>
				<div className={styles.content}>
					{
						messengerListType === MessengerListType.CHATS &&
						<Chats />
					}
					{
						messengerListType === MessengerListType.GROUPS &&
						<Groups />
					}
					{
						messengerListType === MessengerListType.CONTACTS &&
						<Contacts />
					}
				</div>
			</aside>
			<section className={styles.messages}>
				<Outlet />
			</section>
		</section>
	);
}
