import React, { useEffect, useState } from 'react';
import {
	matchPath,
	NavLink,
	Outlet,
	useLocation,
	useNavigate
} from 'react-router-dom';
import cn from 'classnames';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { Icon } from 'app/components/ui/Icon';
import { getStudyingYears } from 'app/services/schoolAPI';

import styles from './index.module.sass';

export const TeacherSyllabusTasks = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const user = useSelector((state: any) => state.userSlice);

	const [yearId, setYearId] = useState<number>();

	const { data } = useQuery('studying years', getStudyingYears, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	useEffect(() => {
		if (user?.profile !== 0) {
			navigate('/teacher/home');
		}

		const params = new URLSearchParams(location.search);
		if (params.get('yearId')) {
			setYearId(+params.get('yearId')!);
		}
	}, [location]);

	return (
		<section className={styles.wrapper}>
			<aside className={styles.navigation}>
				<nav>
					<ul>
						<li>
							<NavLink
								to='current-year'
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='star'
									size={24}
									className={styles.link__icon}
								/>
								Текущий учебный год
							</NavLink>
						</li>
						<li>
							<NavLink
								to={data ? `archive?yearId=${data[0]?.id}` : 'archive'}
								className={({ isActive }) =>  cn(styles.link, {[styles.link_active]: isActive})}
							>
								<Icon
									icon='calendar'
									size={24}
									className={styles.link__icon}
								/>
								Архив
							</NavLink>
						</li>
					</ul>
					{
						!!matchPath(
							useLocation().pathname,
							'/teacher/plan/archive'
						) &&
						<ul>
							{
								data?.map((item) => (
									<li key={item?.id}>
										<NavLink
											to={`archive?yearId=${item?.id}`}
											className={cn(styles.link, styles.link_archive, {[styles.link_active]: yearId === item?.id})}
										>
											{item?.name}
										</NavLink>
									</li>
								))
							}
						</ul>
					}
				</nav>
			</aside>
			<Outlet />
		</section>
	);
}
