import React from 'react';
import { useLocation } from 'react-router-dom';
import { TeacherEvaluation } from 'app/components/features';

import styles from './index.module.sass';

export const TeacherClassEvaluation = () => {
	const currentClassId = useLocation().pathname.split('/')[3];

	return (
		<section className={styles.content}>
			<TeacherEvaluation classId={currentClassId} />
		</section>
	);
}
