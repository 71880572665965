import React, { FC, useMemo } from 'react';
import { DateTime } from 'luxon';
import cn from 'classnames';

import styles from './index.module.sass';

interface Props {
	dayOfWeekStart: DateTime;
	className?: string;
}

export const ScheduleHeader: FC<Props> = (props: Props) => {
	const { dayOfWeekStart, className, ...restProps } = props;

	const weekDays = useMemo(() =>
		Array.from({ length: 6 }, (x, i) => i)
			.map((i) => dayOfWeekStart.plus({ days: i })), [dayOfWeekStart]);

	const weekdayRender = (date: DateTime) => (
		<span
			key={date.toString()}
			className={cn({
				[styles.title]: true,
				[styles.title_active]: DateTime.now().toFormat('d MMMM yyyy') === date.toFormat('d MMMM yyyy')
			})}
		>
			{date.weekdayLong}, {date.day}
		</span>
	);

	return (
		<section
			{...restProps}
			className={cn(styles.container, className)}
		>
			{
				weekDays.map((day) => weekdayRender(day))
			}
		</section>
	);
}
