import React, { useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Icon } from 'app/components/ui/Icon';
import { EmployeeType } from 'app/models/enums/employee-type.enum';

import styles from './index.module.sass';

export const TeacherEmployees = () => {
	const location = useLocation();

	const [filter, setFilter] = useState<number>();

	const links = [
		{
			title: 'Учителя предметники',
			type: EmployeeType.SubjectTeachers,
			icon: 'teacher'
		},
		{
			title: 'Тьюторы',
			type: EmployeeType.Tutor,
			icon: 'parents-color'
		},
		{
			title: 'Специалисты',
			type: EmployeeType.Specialist,
			icon: 'work'
		},
	];

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (params.get('type')) {
			setFilter(+params.get('type')!);
		} else {
			setFilter(EmployeeType.SubjectTeachers);
		}
	}, [location]);

	return (
		<section className={styles.wrapper}>
			<aside className={styles.navigation}>
				<p className={styles.title}>Сотрудники</p>
				<nav>
					<ul>
						{
							links.map((link) =>
								<li key={link.type}>
									<NavLink
										to={`/teacher/employees?type=${link.type}`}
										className={cn(styles.link, {[styles.link_active]: filter === link.type})}
									>
										<Icon
											icon={link.icon}
											size={24}
											className={styles.link__icon}
										/>
										{link.title}
									</NavLink>
								</li>
							)
						}
					</ul>
				</nav>
			</aside>
			<Outlet />
		</section>
	);
}
