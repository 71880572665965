import { DateTime } from 'luxon';
import cn from 'classnames';

import { File } from 'app/models/file.interface';

import documents from 'assets/images/documents.png';
import styles from './index.module.sass';

interface Props {
	data: File | undefined;
	className?: string;
}

export const DocumentCard = ({ data, className, ...restProps }: Props) => (
	<div {...restProps} className={cn(styles.container, className)}>
		{
			data ? (
				<>
					<div className={styles.header}>
						<img
							className={styles.avatar}
							src={documents}
							alt='card avatar'
						/>
						<span>{DateTime.fromISO(data?.uploaded_at!).toFormat('dd.MM.yyyy')}</span>
					</div>
					<p className={styles.title}>{data?.presentation_name}</p>
				</>
			) : (
				<p className={styles.empty}>Документ отсутствует</p>
			)
		}

	</div>
);
