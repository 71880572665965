import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

// import { Icon } from 'app/components/ui';
import {
	getGroupChatInfo,
	getPersonalChatInfo
} from 'app/services/messengerAPI';

import avatarDefault from 'assets/images/avatarDefault.png';

import { MediaList } from './components/MediaList';
import { GroupChat } from './components/GroupChat';
import { PersonalChat } from './components/PersonalChat';

import styles from './index.module.sass';

export const Chat = () => {
	const location = useLocation();
	const { id } = useParams();

	const [mediaMenuOpen, setMediaMenuOpen] = useState(false);

	const { data: group_chat_info } = useQuery(['group_chat_info', id], getGroupChatInfo, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!id?.toString() && location.pathname.includes('groups')
	});

	const { data: personal_chat_info } = useQuery(['personal_chat_info', id], getPersonalChatInfo, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!id?.toString() && location.pathname.includes('chats')
	});

	// const onMediaMenuOpen = () => {
	// 	setMediaMenuOpen(true);
	// }

	const onMediaMenuClose = () => {
		setMediaMenuOpen(false);
	}

	const getChatName = () => {
		if (location.pathname.includes('groups')) {
			return group_chat_info?.name;
		}

		if (location.pathname.includes('chats')) {
			return personal_chat_info?.user?.name;
		}

		return '';
	}

	const getChatAvatar = () => {
		if (location.pathname.includes('groups')) {
			return group_chat_info?.image;
		}

		if (location.pathname.includes('chats')) {
			return personal_chat_info?.user?.avatar;
		}

		return avatarDefault;
	}

	return (
		<>
			<section className={styles.wrapper}>
				<div className={styles.header}>
					<div className={styles.user}>
						<img
							src={getChatAvatar() ?? avatarDefault}
							className={styles.user__avatar}
							alt="Аватар"
						/>
						<p className={styles.user__name}>
							{getChatName()}
						</p>
					</div>
					{/* <button */}
					{/*	onClick={onMediaMenuOpen} */}
					{/*	className={styles.files} */}
					{/* > */}
					{/*	<Icon */}
					{/*		icon='clip' */}
					{/*		size={24} */}
					{/*	/> */}
					{/* </button> */}
				</div>
				{
					location.pathname.includes('groups') &&
					<GroupChat />
				}
				{
					location.pathname.includes('chats') &&
					<PersonalChat />
				}
			</section>
			<MediaList
				chatId={+id!}
				isOpen={mediaMenuOpen}
				onClose={onMediaMenuClose}
			/>
		</>
	);
}
