import React from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';

import { Icon } from 'app/components/ui';

import eventJanuary from 'assets/images/event/event-january.png';
import eventFebruary from 'assets/images/event/event-february.png';
import eventMart from 'assets/images/event/event-mart.png';
import eventApril from 'assets/images/event/event-april.png';
import eventMay from 'assets/images/event/event-may.png';
import eventJune from 'assets/images/event/event-june.png';
import eventJuly from 'assets/images/event/event-july.png';
import eventAugust from 'assets/images/event/event-august.png';
import eventSeptember from 'assets/images/event/event-september.png';
import eventOctober from 'assets/images/event/event-october.png';
import eventNovember from 'assets/images/event/event-november.png';
import eventDecember from 'assets/images/event/event-december.png';

import styles from './index.module.sass';

interface Props {
	event: any;
	className?: string;
}

export const EventCard = (props: Props) => {
	const { event, className, ...restProps } = props;

	const getCardBackground = () => {
		switch (DateTime.fromISO(event?.date).toFormat('MM')) {
			case '01':
				return `center right / contain no-repeat url(${eventJanuary}), linear-gradient(to right, #B6EAF6, #EAF8FC)`;
			case '02':
				return `center right / contain no-repeat url(${eventFebruary}), linear-gradient(to right, #B5E2ED, #D0F1F9)`;
			case '03':
				return `center right / contain no-repeat url(${eventMart}), linear-gradient(to right, #A3F2D7, #CFFFEF)`;
			case '04':
				return `center right / contain no-repeat url(${eventApril}), linear-gradient(to right, #65F8B5, #B2FFDC)`;
			case '05':
				return `center right / contain no-repeat url(${eventMay}), linear-gradient(to right, #ADF4F0, #DAFFFD)`;
			case '06':
				return `center right / contain no-repeat url(${eventJune}), linear-gradient(to right, #FFF06A, #FFF7B0)`;
			case '07':
				return `center right / contain no-repeat url(${eventJuly}), linear-gradient(to right, #FEF5A5, #FFF9BF)`;
			case '08':
				return `center right / contain no-repeat url(${eventAugust}), linear-gradient(to right, #FDF290, #FFF8BC)`;
			case '09':
				return `center right / contain no-repeat url(${eventSeptember}), linear-gradient(to right, #FFD962, #FFEDB7)`;
			case '10':
				return `center right / contain no-repeat url(${eventOctober}), linear-gradient(to right, #FBDC7F, #FFEFC0)`;
			case '11':
				return `center right / contain no-repeat url(${eventNovember}), linear-gradient(to right, #FFDB71, #FFECB4)`;
			case '12':
				return `center right / contain no-repeat url(${eventDecember}), linear-gradient(to right, #86D4D6, #E3FEFF)`;
			default:
				return '';
		}
	}

	return (
		<div
			{...restProps}
			className={cn(styles.card, className, {[styles.card_empty]: !event})}
			style={{ background: getCardBackground() }}
		>
			{
				event ? (
					<>
						<h2>{event?.name}</h2>
						<div className={styles.card__content}>
							<p className={styles.info}>
								<Icon
									icon='clock'
									size={20}
								/>
								{DateTime.fromISO(event?.date).toFormat('dd MMMM')} в {DateTime.fromISO(event?.start_at).toFormat('HH:mm')}
							</p>
							<p className={styles.info}>
								<Icon
									icon='pin'
									size={20}
								/>
								{event?.place}
							</p>
						</div>
					</>
				) : (
					<p className={styles.empty}>
						Мероприятие отсутствует
					</p>
				)
			}
		</div>
	);
}
