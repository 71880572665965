import React from 'react';
import { useQuery } from 'react-query';
import cn from 'classnames';
import { DateTime } from 'luxon';

import { Events } from 'app/components/features';
import { getTeacherEvents } from 'app/services/teacherAPI';

import styles from './index.module.sass';


interface Props {
	className?: string;
}
export const TeacherEvents = (props: Props) => {
	const { className, ...restProps } = props;

	const { data } = useQuery(['teacher-events', DateTime.now().toISODate()], getTeacherEvents, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	return (
		<Events
			{...restProps}
			className={cn(styles.events, className)}
			events={data}
		/>
	);
}
