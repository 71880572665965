import React, { useEffect, useRef } from 'react';
import cn from 'classnames'
import { IMaskInput } from 'react-imask';
import { AnyMaskedOptions } from 'imask';
import styles from 'app/components/ui/Input/index.module.sass'
import { Icon } from 'app/components/ui/Icon';

interface Props {
	value: string
	placeholder: string;
	type: string;
	disabled: boolean;
	leftIcon: string;
	rightIcon: string;
	withClear: boolean;
	hasError: boolean;
	iconColors: string;
	className: string;
	mask: any;
	onMaskChange: (event?: string, mask?: AnyMaskedOptions) => void;
	label?: string;
}

export const InputMask = (props: Partial<Props>) => {
	const {
		value = '',
		mask = '',
		onMaskChange = () => {},
		leftIcon,
		rightIcon,
		disabled = false,
		withClear = false,
		iconColors = '',
		hasError = false,
		className,
		label,
		...restProps
	} = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const onClearInput = () => {
		inputRef.current!.value = '';
		onMaskChange('');
	}

	useEffect(() => {
		if(!inputRef.current || !disabled) {
			return;
		}

		inputRef.current.setAttribute('disabled', 'true');
	}, [])

	return (
		<div className={cn(styles.wrapper, hasError && styles.wrapper_error, className)}>
			{
				label &&
				<span className={styles.label}>{label} </span>
			}
			{
				leftIcon &&
				<Icon
					icon={leftIcon}
					size={24}
					className={styles.prefix}
					style={{color: iconColors}}
				/>
			}
			<IMaskInput
				{...restProps}
				mask={mask}
				value={value}
				inputRef={inputRef}
				onAccept={(val, mask) => onMaskChange(mask.unmaskedValue)}
			/>

			{
				withClear &&
				<button
					disabled={disabled}
					type='button'
					onClick={onClearInput}
					className={cn(styles.suffix, styles.clear)}
				>
					{
						!!value &&
						<Icon
							icon='clear'
							size={12}
						/>
					}
				</button>
			}
			{
				rightIcon &&
				<Icon
					icon={rightIcon}
					size={24}
					className={styles.suffix}
					style={{color: iconColors}}
				/>
			}
		</div>
	);
}
