import { useContext, useMemo } from 'react';
import cn from 'classnames';

import { IconContext, IconPathContext } from './provider';
import { cachedRequest, getSize, symbolToSvg } from './utils';
import { useAsyncMemo } from '../../../utils/use-async-memo';
import styles from './index.module.sass';

interface IconProps {
	icon: string;
	className?: string;
	style?: Object;
	size?: number | string;

	[key: string]: any;
}

export const Icon = ({ icon, className, size, style, ...props }: IconProps) => {
	const icons = useContext<Record<string, string>>(IconContext);
	const path = useContext(IconPathContext);

	const src = useAsyncMemo(async () => {
		// eslint-disable-next-line
		const mappedSrc = icons[icon] || icon;

		if (!mappedSrc) {
			return Promise.resolve(null);
		}

		if (mappedSrc.includes('<svg')) {
			return Promise.resolve(mappedSrc);
		}

		if (mappedSrc.includes('.svg')) {
			const req = await cachedRequest(mappedSrc);

			return req;
		}

		const res = await cachedRequest(path(mappedSrc));
		return symbolToSvg(res, path(mappedSrc));
	}, [icon]);

	const [width, height] = useMemo(() => {
		if (!src) {
			return [size || 24, size || 24];
		}
		const [iconWidth, iconHeight] = getSize(src);
		const Width = size || iconWidth || 24;
		const Height = size || iconHeight || 24;

		return [Width, Height];
	}, [size, src]);

	return (
		// eslint-disable-next-line react/react-in-jsx-scope
		<div
			{...props}
			className={cn(styles.icon, className)}
			dangerouslySetInnerHTML={{ __html: (src as string) }}
			style={{
				...style,
				width: `${width}px`,
				minWidth: `${width}px`,
				height: `${height}px`,
				minHeight: `${height}px`,
			}}
		/>
	);
};

Icon.displayName = 'Icon';
