import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { AbsenceMark } from 'app/models/student-rating.model';
import { AlfaSelect, Button, Textarea } from 'app/components/ui';
import { TextareaSize } from 'app/components/ui/Textarea';
import { SelectSize } from 'app/components/ui/Select';
import { ButtonSize, ButtonTheme } from 'app/components/ui/Button';
import { ABSENCE_REASONS } from 'app/components/features/TeacherEvaluation/utils';

import styles from '../index.module.sass';

const AbsenceFormSchema = Yup.object().shape({
	reason: Yup.object().shape({
		label: Yup.string().required(),
		value: Yup.string().required()
	})
});

interface FormAbsenceValues {
	comment: string;
	reason?: {
		value: number;
		label: string;
	};
}

interface Props {
	onChange: (value: AbsenceMark) => void;
	onDelete: () => void;
	data: AbsenceMark | null;
}

export const AbsenceMarkForm = ({ onChange, onDelete, data }: Props) => {
	const getReasonOption = (hasMedicalReason: boolean) => ({
		value: +hasMedicalReason,
		label: ABSENCE_REASONS[+hasMedicalReason].label
	});

	const initialValues: FormAbsenceValues = {
		reason: data ? getReasonOption(data.has_medical_reason) : undefined,
		comment: data?.comment ?? ''
	};

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={AbsenceFormSchema}
			onSubmit={values => {
				const { reason, ...restValues } = values;
				return onChange({
					id: data?.id,
					...restValues,
					has_medical_reason: !!reason?.value
				} as AbsenceMark);
			}}
		>
			{({ values, setFieldValue, errors, touched }) => (
				<Form className={styles.form}>
					<AlfaSelect
						label='Причина'
						value={values.reason}
						options={ABSENCE_REASONS}
						onChange={(v) => setFieldValue('reason', v)}
						hasError={!!errors.reason && !!touched.reason}
						size={SelectSize.s}
						className={styles.select}
					/>
					<Textarea
						label='Комментарий'
						onChange={e => setFieldValue('comment', (e.target as HTMLTextAreaElement).value)}
						value={values.comment}
						size={TextareaSize.s}
						className={styles.textarea}
					/>
					<hr className={styles.delimiter}/>
					<section className={styles.form__footer}>
						{
							data &&
							<Button
								onClick={onDelete}
								theme={ButtonTheme.ghost}
								size={ButtonSize.s}
							>
								Удалить
							</Button>
						}
						<Button
							type='submit'
							size={ButtonSize.s}
						>
							{data ? 'Редактировать' : 'Сохранить'}
						</Button>
					</section>
				</Form>
			)}
		</Formik>
	);
}
