import React from 'react';
import { useLocation, useNavigate, } from 'react-router-dom';
import { useQuery } from 'react-query';
import { DateTime } from 'luxon';
import FileSaver from 'file-saver';

import { numberEndings } from 'app/utils/numberEndings';
import { getTeacherDetail } from 'app/services/teacherAPI';
import { Icon } from 'app/components/ui';
import { Slider } from 'app/components/ui/Slider';
import { DocumentCard } from 'app/components/features';
import { getUserDocuments } from 'app/services/userAPI';
import { File as DocumentFIle } from 'app/models/file.interface';

import avatarDefault from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

export const TeacherEmployeeProfile = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const teacherId = location.pathname.split('/')[3];

	const { data } = useQuery(['employee_detail', teacherId], getTeacherDetail, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!teacherId
	});

	const { data: documents } = useQuery(['user_documents', teacherId], getUserDocuments, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!teacherId.toString()
	});

	const uploadFile = (file: DocumentFIle) => {
		FileSaver.saveAs(file?.file as unknown as Blob,
			file?.presentation_name ?? 'document');
	}

	return (
		<section className={styles.content}>
			<h1 className={styles.title}>Профиль</h1>
			{
				data &&
				<section className={styles.employee}>
					<div className={styles.employee__data}>
						<h3 className={styles.subtitle}>Общая информация</h3>
						<span>Фамилия</span>
						<p>{data?.last_name}</p>
						<span>Имя</span>
						<p>{data?.first_name}</p>
						{
							data?.patronymic &&
							<>
								<span>Отчество</span>
								<p>{data?.patronymic}</p>
							</>
						}
						{
							data?.birth_day &&
							<>
								<span>Дата рождения</span>
								<p>{DateTime.fromISO(data?.birth_day).toFormat('dd.MM.yyyy')}</p>
							</>
						}
						<h3 className={styles.subtitle}>Контактные данные</h3>
						{
							data?.email &&
							<>
								<span>Email</span>
								<p>{data?.email}</p>
							</>
						}
						{
							data?.phone_number &&
							<>
								<span>Телефон</span>
								<p>{data?.phone_number}</p>
							</>
						}
						{
							(data?.job_title || data?.address) &&
							<>
								<h3 className={styles.subtitle}>Профессиональная
									деятельность</h3>
								{
									data?.job_title &&
									<>
										<span>Должность</span>
										<p>{data?.job_title}</p>
									</>
								}
								{
									data?.address &&
									<>
										<span>Адрес</span>
										<p>{data?.address ?? ''}</p>
									</>
								}
							</>
						}
						<h3 className={styles.subtitle}>Дополнительная информация</h3>
						{
							(typeof data?.work_experience === 'number') &&
							<>
								<span>Опыт работы</span>
								<p>{data?.work_experience} {numberEndings(data?.work_experience, [' год ', ' года ', ' лет '])}</p>
							</>
						}
						{
							data?.education &&
							<>
								<span>Образование</span>
								<p>{data?.education}</p>
							</>
						}
						{
							data?.skills_and_achievements &&
							<>
								<span>Навыки и достижения</span>
								<p>{data?.skills_and_achievements}</p>
							</>
						}
						{
							data?.publications &&
							<>
								<span>Публикации</span>
								<p>{data?.publications}</p>
							</>
						}
					</div>
					<div>
						<h3 className={styles.subtitle}>Фотография</h3>
						<img
							className={styles.avatar}
							src={data?.avatar || avatarDefault}
							alt='employee avatar'
						/>
					</div>

					<button
						onClick={() => navigate(-1)}
						className={styles.close}
					>
						<Icon icon='clear' size={24} />
					</button>
				</section>
			}

			<section>
				<div className={styles.documents__header}>
					<h3>Недавние документы</h3>
				</div>
				{
					documents?.length! > 0 ? (
						<Slider
							withNavigation
							slidesPerView={3}
						>
							{
								documents?.map(item => (
									<button
										key={item?.id}
										onClick={() => uploadFile(item)}
										className={styles.documents__item}
									>
										<DocumentCard data={item} />
									</button>
								))
							}
						</Slider>
					) : (
						<DocumentCard
							data={undefined}
							className={styles.documents__item}
						/>
					)
				}
			</section>
		</section>
	);
}
