import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';

import {
	getArchiveSyllabus,
	getArchiveYearClasses,
	getTeacherSubjects
} from 'app/services/teacherAPI';
import { SyllabusFilters } from '../Filters';
import { TeacherSyllabusContentTable } from '../ContentTable';

import styles from './index.module.sass';

export const TeacherSyllabusTasksArchive = () => {
	const location = useLocation();

	const [yearId, setYearId] = useState<number>();
	const [filterValues, setFilterValues] = useState<{ class_number: number, subject: number }>();

	const { data: classes } = useQuery(['classes', yearId], getArchiveYearClasses, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!yearId
	});

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		if (params.get('yearId')) {
			setYearId(+params.get('yearId')!);
		}
	}, [location]);

	const { data: subjects } = useQuery('teacher-subjects', getTeacherSubjects, {
		retry: 0,
		refetchOnWindowFocus: false,
	});

	const { data: syllabus } = useQuery(['archive-year-syllabus', yearId, filterValues?.class_number, filterValues?.subject], getArchiveSyllabus, {
		retry: 0,
		refetchOnWindowFocus: false,
		enabled: !!yearId && !!filterValues?.class_number && !!filterValues?.subject
	});

	useEffect(() => {
		if (!!classes && !!subjects) {
			setFilterValues({ class_number: classes[0]?.id, subject: subjects[0]?.id });
		}
	}, [classes, subjects]);

	const onFiltersChange = (values: {
		class_number: number,
		subject: number,
	}) => {
		setFilterValues(values);
	}

	return (
		<section className={styles.wrapper}>
			<header className={styles.header}>
				<h1>Архивный год</h1>
			</header>
			<SyllabusFilters
				classes={classes ?? []}
				subjects={subjects ?? []}
				onChange={onFiltersChange}
				className={styles.filters}
			/>
			<section className={styles.content}>
				{
					!syllabus?.id ?
						<p className={styles.empty_message}>
							Учебный план отсутствует
						</p> :
						<TeacherSyllabusContentTable data={syllabus?.data ?? []} />
				}
			</section>
		</section>
	);
}
