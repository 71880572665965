import React from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';

import { SyllabusItem, Topic } from 'app/models/syllabus.interface';

import styles from './index.module.sass';

interface Props {
	data: SyllabusItem[];
	className?: string;
}

export const TeacherSyllabusContentTable = (props: Props) => {
	const { data, className = '', ...restProps } = props;

	return (
		<section
			{...restProps}
			className={cn(styles.table, className)}
		>
			<header
				className={cn(styles.table__header, styles.grid)}>
				<span>№</span>
				<span>Тема урока</span>
				<section className={styles.deadline}>
					<p className={styles.deadline_centred}>
						Календарные даты
					</p>
					<section className={styles.dates}>
						<p>План</p>
						<p>Факт</p>
					</section>
				</section>
			</header>
			{
				data?.map(block =>
					<section
						key={Math.random()}
						className={styles.table__block}
					>
						<p className={styles.table__block__title}>{block.title}</p>
						<ul>
							{
								block?.topics?.map((lesson: Topic, index: number) =>
									<li
										className={styles.grid}
										key={index}
									>
										<span>{index + 1}</span>
										<h4>{lesson?.name}</h4>
										<section className={styles.dates}>
											<p>{DateTime.fromISO(lesson?.planned_date).toFormat('dd.MM')}</p>
											<p>{DateTime.fromISO(lesson?.actual_date).toFormat('dd.MM')}</p>
										</section>
									</li>
								)
							}
						</ul>
					</section>
				)
			}
		</section>
	);
}
