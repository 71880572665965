import React, { ChangeEvent } from 'react';
import { useMutation } from 'react-query';
import cn from 'classnames';

import { changeProfileAvatar, removeProfileAvatar } from 'app/services/profileAPI';
import { useAppDispatch } from 'app/hooks/reduxHooks';
import { updateAvatar } from 'app/reducers/userSlice';
import { User } from 'app/models/user.interface';

import defaultAvatar from 'assets/images/avatarDefault.png';

import styles from './index.module.sass';

interface Props {
	user: User;
	className?: string;
}

export const AvatarChanger = (props: Props) => {
	const { user, className, ...restProps } = props;

	const dispatch = useAppDispatch();
	const uploadMutation = useMutation((file: File) => changeProfileAvatar(file));
	const deleteMutation = useMutation(() => removeProfileAvatar());

	const changeAvatar = (event: ChangeEvent<HTMLInputElement>) => {
		const { files } = event.target;

		if (!files) {
			return {};
		}

		return uploadMutation.mutate(files[0], {
			onSuccess: res => {
				dispatch(updateAvatar(res.data.avatar));
			}
		});
	};

	const deleteAvatar = () => {
		deleteMutation.mutate(undefined,{
			onSuccess: () => {
				dispatch(updateAvatar(null));
			}
		});
	};

	return (
		<section
			{...restProps}
			className={cn(styles.avatar, className)}
		>
			<h3 className={styles.avatar__subtitle}>Фотография</h3>

			<img
				className={styles.avatar__photo}
				alt="avatar"
				src={user.avatar || defaultAvatar}
			/>

			<label
				htmlFor="fileInput"
				className={cn(styles.btn__upload, styles.btn)}
			>
				Загрузить новую
				<input
					id="fileInput"
					type="file"
					onChange={changeAvatar}
				/>
			</label>

			<button
				onClick={deleteAvatar}
				className={cn(styles.btn__delete, styles.btn)}
			>
				Удалить фото
			</button>
		</section>
	);
}
