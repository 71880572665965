import React, { useRef, useState } from 'react';
import cn from 'classnames';

import { Icon } from 'app/components/ui/Icon';

import styles from './index.module.sass';

interface Props {
	value: string;
	onChange: (val: string) => void;
	placeholder: string;
	type: string;
	disabled: boolean;
	leftIcon: string;
	rightIcon: string;
	withClear: boolean;
	iconColors: string;
	hasError: boolean;
	label: string;
	className?: string;
}

export const Input = (props: Partial<Props>) => {
	const {
		value = '',
		onChange = () => {},
		type = 'text',
		leftIcon,
		rightIcon,
		disabled = false,
		withClear = false,
		iconColors = '',
		hasError = false,
		className = '',
		label,
		...restProps
	} = props;

	const inputRef = useRef<HTMLInputElement>(null);

	const [isPasswordShowing, setPasswordShowing] = useState(false);

	const onChangePasswordShowing = () => {
		setPasswordShowing(!isPasswordShowing);
	}
	const onClearInput = () => {
		inputRef.current!.value = '';
		onChange('');
	}

	return (
		<div className={cn(styles.wrapper, hasError && styles.wrapper_error, className)}>
			{
				label &&
				<span className={styles.label}>{label}</span>
			}
			{
				leftIcon &&
				<Icon
					icon={leftIcon}
					size={24}
					className={styles.prefix}
					style={{color:iconColors}}
				/>
			}
			<input
				{...restProps}
				disabled={disabled}
				value={value}
				onChange={e => onChange(e.target.value)}
				type={type === 'text' || isPasswordShowing ? 'text' : 'password'}
				ref={inputRef}
			/>

			{
				withClear &&
				<button
					disabled={disabled}
					type="button"
					onClick={onClearInput}
					className={cn(styles.suffix, styles.clear)}
				>
					{
						!!value &&
						<Icon
							icon="clear"
							size={12}
						/>
					}
				</button>
			}
			{
				rightIcon &&
				<Icon
					icon={rightIcon}
					size={24}
					className={styles.suffix}
					style={{color: iconColors}}
				/>
			}
			{
				type === 'password' &&
				<button
					type="button"
					onClick={onChangePasswordShowing}
					className={styles.suffix}
				>
					<Icon
						icon={isPasswordShowing ? 'eye' : 'eye-slash'}
						size={24}
						style={{color: iconColors}}
					/>
				</button>
			}
		</div>
	);
}
