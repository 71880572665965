import { createContext, Ref } from 'react';
import { DateTime } from 'luxon';

import { AbsenceMark, Grade } from 'app/models/student-rating.model';
import { Semester } from 'app/models/semester.interface';

export const ABSENCE_REASONS = [
	{
		value: 0,
		label: 'Другая причина'
	},
	{
		value: 1,
		label: 'По болезни(Н)'
	},
];

export const GRADES = [2, 3, 4, 5];

export type EvaluateType = Grade | AbsenceMark;

export interface  EvaluationFilters {
	subject: { label: string, value: number } | null;
	currentTime: DateTime;
	semester: Semester | null;
}

export const EvaluationPropertiesContext = createContext<Partial<{
	subjectId: number | null;
	classId: string;
	tooltipRef: Ref<HTMLButtonElement>
}>>({});

export const EvaluationPropertiesProvider = EvaluationPropertiesContext.Provider;
